import { createAsyncThunk } from '@reduxjs/toolkit';
import {
	importExcelBooksAPI,
	exportExcelBooksAPI,
	exportExcelAuthorsAPI,
	exportExcelCategoriesAPI,
	exportExcelPublishersAPI,
} from 'constants/apiURL';
import Request from 'helpers/Request';

export const importExcelBooks = createAsyncThunk('book/import excel', async (file, { rejectWithValue }) => {
	try {
		const response = await Request.makeUpload(importExcelBooksAPI, file);
		return response.data;
	} catch (err) {
		const error = JSON.parse(err.response);
		return rejectWithValue(error);
	}
});

export const exportExcelBooks = createAsyncThunk('book/export excel books', async (params, { rejectWithValue }) => {
	try {
		const response = await Request.makeGet(exportExcelBooksAPI, params);
		return response.data;
	} catch (err) {
		const error = JSON.parse(err.response);
		return rejectWithValue(error);
	}
});

// export const exportData = async (
// 	active = '',
// 	search = '',
// 	start = 0,
// 	sort = '[{"property":"createdAt","direction":"ASC"}]'
// ) => {
// 	const filter = [];
// 	if (active != '') {
// 		filter.push({
// 			operator: `eq`,
// 			value: `${active}`,
// 			property: `verify`,
// 		});
// 	}
// 	if (search != '') {
// 		filter.push({
// 			operator: 'search',
// 			value: search,
// 			property: `name`,
// 		});
// 	}
// 	const strFilter = JSON.stringify(filter);
// 	const { data } = await serviceHelpers.exportData('books', strFilter, sort, start, 10);
// 	return data;
// };

export const exportExcelAuthors = createAsyncThunk('book/export excel authors', async (params, { rejectWithValue }) => {
	try {
		const response = await Request.makeGet(exportExcelAuthorsAPI, params);
		return response.data;
	} catch (err) {
		const error = JSON.parse(err.response);
		return rejectWithValue(error);
	}
});

export const exportExcelCategories = createAsyncThunk(
	'book/export excel categories',
	async (params, { rejectWithValue }) => {
		try {
			const response = await Request.makeGet(exportExcelCategoriesAPI, params);
			return response.data;
		} catch (err) {
			const error = JSON.parse(err.response);
			return rejectWithValue(error);
		}
	}
);

export const exportExcelPublishers = createAsyncThunk(
	'book/export excel publishers',
	async (params, { rejectWithValue }) => {
		try {
			const response = await Request.makeGet(exportExcelPublishersAPI, params);
			return response.data;
		} catch (err) {
			const error = JSON.parse(err.response);
			return rejectWithValue(error);
		}
	}
);
