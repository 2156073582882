import { createAsyncThunk } from '@reduxjs/toolkit';
import { listHashTagAPI } from 'constants/apiURL';
import Request from 'helpers/Request';

export const getListHasgTag = createAsyncThunk('hashtag/getListHashTag', async (params, { rejectWithValue }) => {
	try {
		const response = await Request.makeGet(listHashTagAPI, params);
		return response.data;
	} catch (err) {
		const error = JSON.parse(err.response);
		return rejectWithValue(error);
	}
});
