import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
	uploadImageAPI,
	uploadMultipleImageAPI,
	bookCopyrightsAPI,
	previewLink,
	handleBookCopyrightsAPI,
	listBookCopyrightsAPI,
	handleBookCopyrightsTranslatorAPI,
	trackingAccountsAPI,
	trackingInteractAPI,
} from 'constants/apiURL';
import Request from 'helpers/Request';

export const uploadImage = createAsyncThunk('common/uploadImage', async (dataUpload, { rejectWithValue }) => {
	try {
		const response = await Request.makeUpload(uploadImageAPI, dataUpload);
		return response.data;
	} catch (err) {
		const error = JSON.parse(err.response);
		return rejectWithValue(error);
	}
});

export const getPreviewUrl = createAsyncThunk('post/getPreviewUrl', async (data, { rejectWithValue }) => {
	try {
		const response = await Request.makePost(previewLink, data);
		return response.data;
	} catch (err) {
		const error = JSON.parse(err.response);
		return rejectWithValue(error);
	}
});

export const uploadMultiFile = createAsyncThunk('common/uploadMultiFile', async (dataUpload, { rejectWithValue }) => {
	try {
		const res = await Request.makeUpload(uploadMultipleImageAPI, dataUpload);
		const data = res.data;
		return data;
	} catch (err) {
		const error = JSON.parse(err.response);
		return rejectWithValue(error);
	}
});

export const creatBookCopyrights = createAsyncThunk(
	'common/creatBookCopyrights',
	async (dataCopyrights, { rejectWithValue }) => {
		try {
			const res = await Request.makePost(bookCopyrightsAPI, dataCopyrights);
			const data = res.data;
			return data;
		} catch (err) {
			const error = err.response.message;
			return rejectWithValue(error);
		}
	}
);

export const getListCopyrights = createAsyncThunk('common/getListCopyrights', async (params, { rejectWithValue }) => {
	try {
		const res = await Request.makeGet(bookCopyrightsAPI, params);
		const data = res.data;
		return data;
	} catch (err) {
		const error = err.response.message;
		return rejectWithValue(error);
	}
});

export const getBookCopyrightsAuthor = createAsyncThunk(
	'common/getBookCopyrightsAuthor',
	async (bookId, { rejectWithValue }) => {
		try {
			const res = await Request.makeGet(listBookCopyrightsAPI(bookId));
			const data = res.data;
			return data;
		} catch (err) {
			const error = err.response.message;
			return rejectWithValue(error);
		}
	}
);

export const confirmCopyrights = createAsyncThunk('common/getListCopyrights', async (params, { rejectWithValue }) => {
	const { bookId, body } = params;
	try {
		const res = await Request.makePatch(handleBookCopyrightsAPI(bookId), body);
		const data = res.data;
		return data;
	} catch (err) {
		const error = err.response;
		return rejectWithValue(error);
	}
});

export const confirmCopyrightsTranslator = createAsyncThunk(
	'common/getListCopyrights',
	async (params, { rejectWithValue }) => {
		const { bookId, body } = params;
		try {
			const res = await Request.makePatch(handleBookCopyrightsTranslatorAPI(bookId), body);
			const data = res.data;
			return data;
		} catch (err) {
			const error = err.response;
			return rejectWithValue(error);
		}
	}
);

export const trackingAccounts = createAsyncThunk('common/tracking accounts', async (params, { rejectWithValue }) => {
	try {
		const res = await Request.makeGet(trackingAccountsAPI, params);
		return res.data;
	} catch (err) {
		const error = err.response;
		return rejectWithValue(error);
	}
});

export const trackingInteract = createAsyncThunk('common/tracking interact', async (params, { rejectWithValue }) => {
	try {
		const res = await Request.makeGet(trackingInteractAPI, params);
		return res.data;
	} catch (err) {
		const error = err.response;
		return rejectWithValue(error);
	}
});

const commonSlice = createSlice({
	name: 'common',
	initialState: {
		titleReviewPage: '',
		directedFromProfile: true,
	},

	reducers: {
		updateTitleReviewPage: (state, action) => {
			state.titleReviewPage = action.payload;
		},
		updateDirectFromProfile: (state, action) => {
			state.directedFromProfile = action.payload;
		},
	},
});

export const { updateTitleReviewPage, updateDirectFromProfile } = commonSlice.actions;
export default commonSlice.reducer;
