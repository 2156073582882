import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { deleteGroupFeedApi, detailGroupFeedApi, getPostsByUserAPI, listGroupFeed } from 'constants/apiURL';
import Request from 'helpers/Request';

export const getPostsByUser = createAsyncThunk('post/getPostListByUser', async (data, { rejectWithValue }) => {
	try {
		const { userId, params } = data;
		const response = await Request.makeGet(getPostsByUserAPI(userId), params);
		return response.data;
	} catch (err) {
		const error = JSON.parse(err.response);
		return rejectWithValue(error);
	}
});

export const getGroupFeed = createAsyncThunk('group/getGroupFeed', async (params = {}, { rejectWithValue }) => {
	try {
		const res = await Request.makeGet(listGroupFeed, params);
		return res.data;
	} catch (err) {
		const error = JSON.parse(err.response);
		return rejectWithValue(error);
	}
});

export const deleteGroupFeed = createAsyncThunk('group/deleteGroupFeed', async (id, { rejectWithValue }) => {
	try {
		const res = await Request.makeDelete(deleteGroupFeedApi(id));
		return res.data;
	} catch (err) {
		const error = JSON.parse(err.response);
		return rejectWithValue(error);
	}
});

export const detailGroupFeed = createAsyncThunk('group/detailGroupFeed', async (id, { rejectWithValue }) => {
	try {
		const res = await Request.makeGet(detailGroupFeedApi(id));
		return res.data;
	} catch (err) {
		const error = JSON.parse(err.response);
		return rejectWithValue(error);
	}
});

const groupFeedSlice = createSlice({
	name: 'groupFeed',
	initialState: {},
});

const groupFeed = groupFeedSlice.reducer;

export default groupFeed;
