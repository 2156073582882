import { faInfo, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Button, Card, Col, Modal, Row, Table } from '@themesberg/react-bootstrap';
import bookImageDefault from 'assets/img/default-book.png';
import avatarDefault from 'assets/img/avatar-default.jpeg';
import BookThumbnail from 'components/BookThumbnail';
import BreadcrumbComponent from 'components/BreadcrumbComponent';
import CustomDropdownMenu from 'components/CustomDropdownMenu';
import LoadingIndicator from 'components/LoadingIndicator';
import PaginationComponent from 'components/PaginationComponent';
import { breadcrumbsData } from 'constants';
import { NotificationError } from 'helpers/Error';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { confirmCopyrights, confirmCopyrightsTranslator, getListCopyrights } from 'reducers/common';
import Lightbox from 'react-image-lightbox';
import MyModal from 'components/Modal';

function ConfirmMyBook() {
	const breadcrumbData = [breadcrumbsData.booksManager.bookApproval];

	const dispatch = useDispatch();
	const callApiPerPage = useRef(10);

	const [data, setData] = useState([]);
	const [isloading, setIsLoading] = useState(false);
	const [totalNumber, setTotalNumber] = useState(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPage, setTotalPage] = useState(1);
	const [filterStatus, setFilterStatus] = useState('pending');
	const [filterRole, setFilterRole] = useState('');
	const [sortById, setSortById] = useState('DESC');
	const [choosenRequest, setChoosenRequest] = useState('');
	const [showModalInfo, setShowModalInfo] = useState(false);
	const [showModalConfirmDelete, setShowModalConfirmDelete] = useState(false);
	const [photoIndex, setPhotoIndex] = useState(0);
	const [isOpenLightBox, setIsOpenLightBox] = useState(false);

	useEffect(() => {
		getData();
	}, [currentPage, filterStatus, sortById, filterRole]);

	const onHideModalInfo = () => {
		setShowModalInfo(false);
	};

	const getData = async () => {
		setIsLoading(true);
		try {
			const params = {
				start: (currentPage - 1) * callApiPerPage.current,
				limit: callApiPerPage.current,
				sort: JSON.stringify([{ direction: sortById, property: 'createdAt' }]),
				filter: JSON.stringify([
					filterStatus && {
						operator: 'eq',
						value: filterStatus,
						property: 'status',
					},
					filterRole && {
						operator: 'eq',
						value: filterRole,
						property: 'type',
					},
				]),
			};
			const res = await dispatch(getListCopyrights(params)).unwrap();
			setTotalNumber(res.count);
			setData(res.rows);
			setTotalPage(Math.ceil(res.count / callApiPerPage.current));
		} catch (err) {
			NotificationError(err);
		} finally {
			setIsLoading(false);
		}
	};

	const onChangeFilterStatus = value => {
		setCurrentPage(1);
		setFilterStatus(value);
	};

	const onChangeFilterRole = value => {
		setCurrentPage(1);
		setFilterRole(value);
	};

	const handleConfirmCopyrights = async item => {
		setShowModalInfo(false);
		try {
			if (item.type === 'author') {
				const params = {
					bookId: item.bookId,
					body: {
						'apply': true,
						'createdBy': item.createdBy,
						'authorId': item.authorId,
					},
				};
				await dispatch(confirmCopyrights(params)).unwrap();
			} else {
				const params = {
					bookId: item.bookId,
					body: {
						'apply': true,
						'createdBy': item.createdBy,
						'translatorId': item.translatorId,
					},
				};
				await dispatch(confirmCopyrightsTranslator(params)).unwrap();
			}
			toast.success('Xác thực thành công');
		} catch (err) {
			NotificationError(err);
		}
	};

	const onClickOpenDetail = item => {
		if (item.book.authors.length) {
			setChoosenRequest(item);
			setShowModalInfo(true);
		} else {
			toast.error('Có lỗi xảy ra. Có thể sách đã bị xóa');
		}
	};

	const onClickOpenDelete = item => {
		setChoosenRequest(item);
		setShowModalConfirmDelete(true);
	};

	const onHideModalConfirmDelete = () => {
		setShowModalConfirmDelete(false);
	};

	const confirmDelete = async () => {
		try {
			const params = {
				bookId: choosenRequest.bookId,
				body: {
					'apply': false,
					'createdBy': choosenRequest.createdBy,
				},
			};
			if (choosenRequest.type === 'author') {
				await dispatch(confirmCopyrights(params)).unwrap();
			} else {
				await dispatch(confirmCopyrightsTranslator(params)).unwrap();
			}
			toast.success('Xóa yêu cầu thành công');
			getData();
		} catch (err) {
			NotificationError(err);
		} finally {
			onHideModalConfirmDelete();
		}
	};

	// const getAuthorAvatar = async id => {
	// 	try {
	// 		const res = await dispatch(getUserDetail(id)).unwrap();
	// 		setAuthorAvatar(res.avatarImage);
	// 	} catch (error) {
	// 		setAuthorAvatar('');
	// 		return;
	// 	}
	// };

	const renderDataUserToConfirm = () => {
		let result = <></>;
		if (choosenRequest?.book?.authors.length > 0 || choosenRequest?.book?.translators.length > 0) {
			const dataAuthor = choosenRequest.book.authors.find(item => item.authorId === choosenRequest.authorId);
			const dataTranslators = choosenRequest.book.translators.find(
				item => item.translatorId === choosenRequest.translatorId
			);
			if (dataAuthor || dataTranslators) {
				result = (
					<>
						<label>Xác thực {choosenRequest?.type === 'author' ? 'tác giả' : 'dịch giả'}:</label>
						<br />
						<div className='d-flex align-items-center'>
							<span className='text-break'>
								{dataAuthor?.authorName || dataTranslators?.translatorName}
							</span>
						</div>
					</>
				);
			}
		}
		return result;
	};

	return (
		<>
			<div className='d-flex justify-content-between align-items-end'>
				<BreadcrumbComponent
					subItems={breadcrumbData}
					pageTitle={`Danh sách yêu cầu xác thực sách (${totalNumber})`}
				/>
			</div>

			<Card border='light' className='shadow-sm mb-4'>
				<Card.Body className='pb-0'>
					{isloading ? (
						<div
							className='d-flex justify-content-center align-items-center mb-3'
							style={{ height: '580px' }}
						>
							<LoadingIndicator />
						</div>
					) : (
						<>
							<Table responsive className='table-centered table-nowrap rounded mb-0'>
								<thead className='thead-light'>
									<tr>
										<th className='border-0'>
											<CustomDropdownMenu
												title='id'
												dataArray={[
													{ value: 'DESC', title: 'Lớn đến bé (mới nhất)' },
													{ value: 'ASC', title: 'Bé đến lớn (cũ nhất)' },
												]}
												onFilterChange={setSortById}
												filterValue={sortById}
											/>
										</th>
										<th className='border-0'>sách</th>
										<th className='border-0'>người đăng</th>
										<th className='border-0'>nội dung</th>
										<th className='border-0'>
											<CustomDropdownMenu
												title='vai trò'
												dataArray={[
													{ value: '', title: 'Tất cả' },
													{ value: 'author', title: 'Tác giả' },
													{ value: 'translator', title: 'Dịch giả' },
												]}
												onFilterChange={onChangeFilterRole}
												filterValue={filterRole}
											/>
										</th>
										<th className='border-0'>
											<CustomDropdownMenu
												title='trạng thái'
												dataArray={[
													{ value: '', title: 'Tất cả' },
													{ value: 'pending', title: 'Chờ duyệt' },
													{ value: 'validated', title: 'Đã duyệt' },
												]}
												onFilterChange={onChangeFilterStatus}
												filterValue={filterStatus}
											/>
										</th>
										<th className='border-0'>hoạt động</th>
									</tr>
								</thead>
								{data.length > 0 && (
									<tbody>
										{data.map((item, index) => (
											<tr key={index}>
												<td>{item.id}</td>
												<td>
													<div className='d-flex align-items-center gap-2'>
														<BookThumbnail src={item.book.frontBookCover} />
														<span className='text-truncate'>{item.book.name}</span>
													</div>
												</td>
												<td>
													{item.user.fullName ||
														item.user.firstName + ' ' + item.user.lastName}
												</td>
												<td>{item.content}</td>
												<td>
													<span
														className={
															item.type === 'author' ? 'text-dark' : 'text-primary'
														}
													>
														{item.type === 'author' ? 'Tác giả' : 'Dịch giả'}
													</span>
												</td>
												<td>
													<Badge
														className='p-2'
														bg={item.status === 'pending' ? 'warning' : 'success'}
														style={{ fontSize: '0.75rem' }}
													>
														{item.status === 'pending' ? 'Chờ duyệt' : 'Đã duyệt'}
													</Badge>
												</td>
												<td>
													<div className='d-flex gap-1'>
														<Button
															style={{ width: '31px' }}
															variant='light'
															size='sm'
															title='Chi tiết'
															onClick={() => onClickOpenDetail(item)}
														>
															<FontAwesomeIcon className='text-info' icon={faInfo} />
														</Button>
														<Button
															variant='light'
															size='sm'
															title='Xóa'
															onClick={() => onClickOpenDelete(item)}
														>
															<FontAwesomeIcon
																className='text-danger'
																icon={faTrashAlt}
															/>
														</Button>
													</div>
												</td>
											</tr>
										))}
									</tbody>
								)}
							</Table>
							<PaginationComponent
								currentPage={currentPage}
								totalPage={totalPage}
								setCurrentPage={setCurrentPage}
							/>
							{data.length === 0 && (
								<p className='position-absolute start-50 translate-middle margin-negative'>
									Không có kết quả nào
								</p>
							)}
						</>
					)}
				</Card.Body>
			</Card>

			<Modal show={showModalInfo} onHide={onHideModalInfo} centered className='book-copyrights-modal'>
				<Modal.Header>
					<h5 style={{ margin: 'auto' }}>Chi tiết</h5>
				</Modal.Header>
				{choosenRequest && (
					<Modal.Body>
						<Row>
							<Col xs={4} className='text-center'>
								<img
									style={{ width: '120px', height: '165px', borderRadius: '8px' }}
									src={choosenRequest.book.frontBookCover || bookImageDefault}
									onError={e => {
										e.target.setAttribute('src', `${bookImageDefault}`);
									}}
								/>
								<p className='mt-2 text-break overflow-auto' style={{ maxHeight: '200px' }}>
									{choosenRequest.book.name}
								</p>
							</Col>
							<Col>
								<div>
									<label>Người đăng:</label>
									<br />
									<div className='d-flex align-items-center'>
										<img
											style={{
												width: '60px',
												height: '60px',
												borderRadius: '5px',
												marginRight: '5px',
											}}
											src={choosenRequest.user.avatarImage || avatarDefault}
											onError={e => {
												e.target.setAttribute('src', `${avatarDefault}`);
											}}
										/>
										<span className='text-break'>
											{choosenRequest.user.fullName ||
												choosenRequest.user.firstName + ' ' + choosenRequest.user.lastName}
										</span>
									</div>
								</div>

								<div className='mt-4'>{renderDataUserToConfirm()}</div>
							</Col>
						</Row>
						<br />
						<p>Tài liệu đính kèm:</p>
						<div className='d-flex flex-wrap justify-content-between mt-3 gap-2'>
							{choosenRequest.documents?.slice(0, 4).map((item, index) => (
								<div
									key={index}
									className='book-copyrights-modal__documents'
									onClick={() => {
										setShowModalInfo(false);
										setPhotoIndex(index);
										setIsOpenLightBox(true);
									}}
								>
									<img src={item} />
									{choosenRequest.documents?.length > 4 && index === 3 && (
										<div className='book-copyrights-modal__documents__plus'>
											+{choosenRequest.documents.length - 4}
										</div>
									)}
								</div>
							))}
						</div>
					</Modal.Body>
				)}
				<Modal.Footer>
					{choosenRequest.status === 'pending' && (
						<Button variant='success' onClick={() => handleConfirmCopyrights(choosenRequest)}>
							Xác thực
						</Button>
					)}
					<Button variant='light' onClick={onHideModalInfo}>
						Đóng
					</Button>
				</Modal.Footer>
			</Modal>

			{isOpenLightBox && !!choosenRequest.documents?.length && (
				<Lightbox
					mainSrc={choosenRequest.documents[photoIndex]}
					nextSrc={choosenRequest.documents[(photoIndex + 1) % choosenRequest.documents.length]}
					prevSrc={
						choosenRequest.documents[
							(photoIndex + choosenRequest.documents.length - 1) % choosenRequest.documents.length
						]
					}
					onCloseRequest={() => setIsOpenLightBox(false)}
					onMovePrevRequest={() =>
						setPhotoIndex(
							(photoIndex + choosenRequest.documents.length - 1) % choosenRequest.documents.length
						)
					}
					onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % choosenRequest.documents.length)}
				/>
			)}
			<MyModal
				show={showModalConfirmDelete}
				onHide={onHideModalConfirmDelete}
				onDelete={confirmDelete}
				title='Xác nhận xóa yêu cầu xác thực'
				type='yêu cầu'
			/>
		</>
	);
}

export default ConfirmMyBook;
