/* eslint max-lines: off */
import BreadcrumbComponent from 'components/BreadcrumbComponent';
import { Card, InputGroup, Form, Button, Row, Col } from '@themesberg/react-bootstrap';
import Dropzone from 'react-dropzone';
import { Image } from 'svg-components';
import Select from 'react-select';
import { useState, useRef, useEffect, useCallback } from 'react';
import _ from 'lodash';
import { getFilterSearch } from 'reducers/search';
import { useDispatch } from 'react-redux';
import { NotificationError } from 'helpers/Error';
import { getCategoryList } from 'reducers/category';
import { getUserList } from 'reducers/user';
import { getPublishers } from 'reducers/publisher';
import LoadingIndicator from 'components/LoadingIndicator';
import moment from 'moment-timezone';
import Datetime from 'react-datetime';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons';
// import ModalSeries from 'components/ModalSeries';
import { toast } from 'react-toastify';
import { maxCategoriesNumberAdded, listLanguages } from 'constants';
import { uploadImage } from 'reducers/common';
import { createBook, getBookDetail, editBook } from 'reducers/books';
import { addBookToSeries, removeBookFromSeries } from 'reducers/series';
import { breadcrumbsData } from 'constants';
import { useParams } from 'react-router-dom';
import bookImageDefault from 'assets/img/default-book.png';
import RichTextEditor from 'components/RichTextEditor';
import axios from 'axios';

const LinkAdditional = [
	{
		value: 'biboo',
		label: 'Biboo',
	},
	{
		value: 'shoppe',
		label: 'Shoppe',
	},
	{
		value: 'google play',
		label: 'Google Play',
	},
	{
		value: 'app store',
		label: 'App Store',
	}
];

function BookInformations() {
	const { bookId } = useParams();
	let breadcrumbData;
	if (bookId) {
		breadcrumbData = [breadcrumbsData.booksManager.books, breadcrumbsData.booksManager.editBook];
	} else {
		breadcrumbData = [breadcrumbsData.booksManager.books, breadcrumbsData.booksManager.createBook];
	}

	const blockInvalidChar = e => {
		return ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();
	};

	const initialLoading = {
		authorLoading: false,
		translatorLoading: false,
		categoryLoading: false,
		publisherLoading: false,
	};
	const [
		{ name, subName, originalName, authorName, isbn, page, paperSize, seoTitle, seoKeywords, seoDescription },
		setInputValue,
	] = useState({});
	const [description, setDescription] = useState('');
	const [toggleResetDescription, setToggleResetDescription] = useState(false);
	const [image, setImage] = useState(null);
	const [{ authorLoading, translatorLoading, categoryLoading, publisherLoading }, setIsLoading] =
		useState(initialLoading);
	const [suggestionData, setSuggestionData] = useState([]);
	const [translators, setTranslators] = useState([]);
	const [selectTranslatorInput, setSelectTranslatorInput] = useState('');
	const [authors, setAuthors] = useState([]);
	const [selectAuthorInput, setSelectAuthorInput] = useState('');
	// link
	const [link, setLink] = useState([]);
	const [linkItem, setLinkItem] = useState({
		Biboo: '',
		Shoppe: '',
		GooglePlay: '',
		AppStore: ''
	});

	const [categories, setCategories] = useState([]);
	const [publisher, setPublisher] = useState(null);
	const [publishDate, setPublishDate] = useState(null);
	// const [showModalSeries, setShowModalSeries] = useState(false);
	const [seriData, setSeriData] = useState({});
	const [language, setLanguage] = useState(listLanguages[0].value);
	const [buttonActive, setButtonActive] = useState(false);
	const [bookData, setBookData] = useState({});
	const [isPageLoading, setIsPageLoading] = useState(false);

	const [searchBiboo, setSearchBiboo] = useState('');
	const [suggestionDataBook, setSuggestionDataBook] = useState([]);
	const [bookBiboo, setBookBiboo] = useState([]);

	const initalAuthorsSuggestionList = useRef([]);
	const initalCategoriesSuggestionList = useRef([]);
	const initalPublisherSuggestionList = useRef([]);
	const initialInputValue = useRef({});
	const initalBooksSuggestionList = useRef([]);

	const dispatch = useDispatch();
	useEffect(() => {
		if (bookId) {
			handleGetBookDetail();
		} else {
			initialInputValue.current = {
				name: '',
				subName: '',
				originalName: '',
				authorName: '',
				translatorName: '',
				isbn: '',
				page: '',
				description: '',
				paperSize: '',
				seoTitle: '',
				seoKeywords: '',
				seoDescription: '',
			};
		}
		// get initial data authors, categories, publisher for suggestion
		getInitialAuthors();
		getInitialCategories();
		getInitialPublisher();
	}, []);

	const getInitialAuthors = async () => {
		try {
			const params = { filter: JSON.stringify([{ operator: 'eq', value: 'author', property: 'role' }]) };
			const res = await dispatch(getUserList(params)).unwrap();
			if (res.rows.length) {
				const dataTemp = res.rows.map(item => {
					return {
						value: item.id,
						label: item.fullName || `${item.firstName} ${item.lastName}`,
						isUser: true,
					};
				});
				initalAuthorsSuggestionList.current = dataTemp;
			}
		} catch (err) {
			NotificationError(err);
		}
	};

	const getInitialCategories = async () => {
		try {
			const query = {
				option: false,
				params: {},
			};
			const res = await dispatch(getCategoryList(query)).unwrap();
			if (res.rows.length) {
				const dataTemp = res.rows.map(item => {
					return {
						value: item.id,
						label: item.name,
					};
				});
				initalCategoriesSuggestionList.current = dataTemp;
			}
		} catch (err) {
			NotificationError(err);
		}
	};

	const getInitialPublisher = async () => {
		try {
			const res = await dispatch(getPublishers()).unwrap();
			if (res.rows.length) {
				const dataTemp = res.rows.map(item => {
					return {
						value: item.id,
						label: item.name,
					};
				});
				initalPublisherSuggestionList.current = dataTemp;
			}
		} catch (err) {
			NotificationError(err);
		}
	};

	const handleGetBookDetail = async () => {
		setIsPageLoading(true);
		try {
			const res = await dispatch(getBookDetail(bookId)).unwrap();
			setBookData(res);
			if (!_.isEmpty(res)) {
				// initial inputs value
				const newObj = {
					name: res.name,
					subName: res.subName,
					originalName: res.originalName,
					isbn: res.isbn,
					page: res.page,
					paperSize: res.paperSize,
					description: res.description,
					seoTitle: res.seo_title && res.seo_title !== '<null>' ? res.seo_title : '',
					seoKeywords: res.seo_keywords && res.seo_keywords !== '<null>' ? res.seo_keywords : '',
					seoDescription:
						res.seo_descriptions && res.seo_descriptions !== '<null>' ? res.seo_descriptions : '',
				};
				initialInputValue.current = newObj;
				setInputValue(newObj);
				setDescription(res.description);
				setLinkItem({
					Biboo: res.externalProductLink.biboo,
					Shoppe: res.externalProductLink.shoppe,
					GooglePlay: res.externalProductLink.google_play,
					AppStore: res.externalProductLink.app_store,
				});

				if (res.externalProductLink.biboo) {
					setLink(prev => [
						...prev,
						{
							value: 'biboo',
							label: 'Biboo',
						},
					]);
					const urlObj = new URL(res.externalProductLink.biboo);
  					const params = new URLSearchParams(urlObj.search);
					setBookBiboo([{
						label:res.externalProductLink.biboo,
						value:params.get('idBook')
					}])
				}
				if (res.externalProductLink.shoppe) {
					setLink(prev => [
						...prev,
						{
							value: 'shoppe',
							label: 'shoppe',
						},
					]);
				}
				if (res.externalProductLink.google_play) {
					setLink(prev => [
						...prev,
						{
							value: 'google play',
							label: 'Google Play',
						},
					]);
				}
				if (res.externalProductLink.app_store) {
					setLink(prev => [
						...prev,
						{
							value: 'app store',
							label: 'App Store',
						},
					]);
				}
				setInitialData(res);
			}
		} catch (err) {
			NotificationError(err);
		} finally {
			setIsPageLoading(false);
		}
	};

	const setInitialData = paramBookData => {
		// initial front book cover
		if (paramBookData.frontBookCover) {
			setImage(paramBookData.frontBookCover);
		} else {
			setImage(paramBookData.images[0]);
		}

		// initial authors value
		const authorsArr = paramBookData.authors.map(item => {
			return {
				value: item.authorId,
				label: item.authorName,
				isUser: item.isUser,
			};
		});
		setAuthors(authorsArr);

		// initial translators value
		const translatorsArr = paramBookData.translators.map(item => {
			return {
				value: item.translatorId,
				label: item.translatorName,
				isUser: item.isUser,
			};
		});
		setTranslators(translatorsArr);

		// initial categories value
		const categoriesArr = paramBookData.categories.map(item => {
			return {
				value: item.category.id,
				label: item.category.name,
			};
		});
		setCategories(categoriesArr);

		// initial publisher value
		const publisherData = [{ value: paramBookData.publisher?.id, label: paramBookData.publisher?.name }];
		setPublisher(publisherData);

		// publish date
		setPublishDate(new Date(paramBookData.publishDate));

		// initial seri data
		setSeriData({ ...paramBookData.series });

		// initial language
		setLanguage(paramBookData.language);
	};

	// function when searching
	const fetchSuggestion = async (inputValue, option) => {
		try {
			if (inputValue) {
				let dataTemp = [];
				if (option === 'author' || option === 'translator') {
					const params = { q: inputValue, type: 'authors' };
					const res = await dispatch(getFilterSearch(params)).unwrap();
					dataTemp = res.rows.map(item => {
						return {
							value: item.id,
							label: item.fullName || `${item.firstName} ${item.lastName}`,
							isUser: true,
						};
					});
				} else if (option === 'category') {
					const query = {
						q: inputValue,
						start: 0,
						limit: 10,
						type: 'categories',
					};
					const res = await dispatch(getFilterSearch(query)).unwrap();
					dataTemp = res.rows.map(item => {
						return {
							value: item.id,
							label: item.name,
						};
					});
				} else {
					const params = {
						filter: JSON.stringify([{ 'operator': 'search', 'value': inputValue, 'property': 'name' }]),
					};
					const res = await dispatch(getPublishers(params)).unwrap();
					dataTemp = res.rows.map(item => {
						return {
							value: item.id,
							label: item.name,
						};
					});
				}
				setSuggestionData(dataTemp);
			}
		} catch (err) {
			NotificationError(err);
		} finally {
			setIsLoading(initialLoading);
		}
	};

	const debounceSearch = useCallback(_.debounce(fetchSuggestion, 700), []);

	const handleSearch = (inputValue, option) => {
		debounceSearch(inputValue, option);
		if (inputValue) {
			setIsLoading(prevState => ({ ...prevState, [`${option}Loading`]: true }));
			setSuggestionData([]);
			if (option === 'author') {
				setInputValue(prevState => ({ ...prevState, authorName: inputValue }));
			} else if (option === 'translator') {
				setInputValue(prevState => ({ ...prevState, translatorName: inputValue }));
			}
		} else {
			setIsLoading(initialLoading);
			if (option === 'author') {
				setSuggestionData(initalAuthorsSuggestionList.current);
			} else if (option === 'translator') {
				setSuggestionData(initalAuthorsSuggestionList.current);
			} else if (option === 'category') {
				setSuggestionData(initalCategoriesSuggestionList.current);
			} else {
				setSuggestionData(initalPublisherSuggestionList.current);
			}
		}
	};

	// on change when typing input
	const onChange = e => {
		const { name, value } = e.target;
		setInputValue(prevState => ({ ...prevState, [name]: value }));
	};

	const onChangeLink = e => {
		const { name, value } = e.target;
		setLinkItem(prevState => ({ ...prevState, [name]: value }));
	};

	const onChangeCategories = categoriesArray => {
		if (categoriesArray.length <= maxCategoriesNumberAdded) {
			setCategories(categoriesArray);
		} else {
			toast.warning(`Chỉ được chọn tối đa ${maxCategoriesNumberAdded} chủ đề`);
		}
	};

	// check active button
	useEffect(() => {
		if (
			!image ||
			!name ||
			(authors.length === 0 && !authorName) ||
			categories.length === 0 ||
			!publisher ||
			!isbn ||
			!page ||
			!language ||
			!description 
		) {
			setButtonActive(false);
		} else {
			setButtonActive(true);
		}
	}, [image, name, authors, categories, publisher, isbn, page, language, description, authorName]);

	const clearAllData = () => {
		setInputValue(initialInputValue.current);
		setToggleResetDescription(!toggleResetDescription);
		if (bookId) {
			setInitialData(bookData);
		} else {
			setImage(null);
			setTranslators([]);
			setAuthors([]);
			setCategories([]);
			setPublisher({});
			setPublishDate(null);
			setSeriData({});
			setLanguage(listLanguages[0].value);
			setDescription('');
		}
	};

	const uploadImageFile = async file => {
		try {
			const imageUploadedData = await dispatch(uploadImage(file)).unwrap();
			return imageUploadedData?.streamPath.small;
		} catch (err) {
			NotificationError(err);
		}
	};

	const handleSubmit = async () => {
		// Tạo danh sách chủ đề
		const categoryIds = categories.map(item => item.value);

		// Tạo danh sách tác giả
		const authorsArr = authors.map(item => {
			if (item.isUser) {
				return {
					isUser: true,
					authorId: item.value,
				};
			} else {
				return {
					isUser: false,
					authorName: item.label,
				};
			}
		});

		// Tạo danh sách dịch giả
		const translatorsArr = translators.map(item => {
			if (item.isUser !== undefined && item.isUser === false) {
				return {
					isUser: false,
					translatorName: item.label,
				};
			} else {
				return {
					isUser: true,
					translatorId: item.value,
				};
			}
		});

		let imgSrc;
		if (typeof image === 'string') {
			imgSrc = image;
		} else {
			imgSrc = await uploadImageFile(image);
		}

		let dataDate = null;
		if (!_.isEmpty(publishDate)) {
			const dateTemp = publishDate._d;
			const offset = dateTemp.getTimezoneOffset();
			dataDate = new Date(dateTemp.getTime() - offset * 60 * 1000).toISOString().split('T')[0];
		}

		const bookInfo = {
			frontBookCover: imgSrc,
			images: [imgSrc],
			name: name,
			subName: subName,
			originalName: originalName,
			authors: authorsArr,
			translators: translatorsArr,
			publisherId: publisher?.value,
			isbn: isbn,
			publishDate: dataDate,
			page: Number(page),
			language: language,
			paperSize: paperSize,
			description: description,
			categoryIds: categoryIds,
			tags: [],
			series: [],
			seo_title: seoTitle || '',
			seo_keywords: seoKeywords || '',
			seo_descriptions: seoDescription || '',
			externalProductLink:{
				biboo: linkItem.Biboo,
				shoppe: linkItem.Shoppe,
				google_play: linkItem.GooglePlay,
				app_store: linkItem.AppStore,
			}
		};
		if (bookId) {
			handleEditBook(bookInfo);
		} else {
			handleCreateBook(bookInfo);
		}
	};

	const handleCreateBook = async params => {
		try {
			const res = await dispatch(createBook(params)).unwrap();
			if (!_.isEmpty(seriData)) {
				const bookCreatedId = res.id;
				const paramsForAddBookToSeries = {
					seriesId: seriData.id,
					body: { bookIds: [Number(bookCreatedId)] },
				};
				await handleAddBookToSeries(paramsForAddBookToSeries);
			}
			toast.success('Tạo sách thành công');
			clearAllData();
		} catch (err) {
			NotificationError(err);
		}
	};

	const handleRemoveBookFromSeries = async params => {
		try {
			await dispatch(removeBookFromSeries(params)).unwrap();
		} catch (err) {
			NotificationError(err);
		}
	};

	const handleAddBookToSeries = async params => {
		try {
			await dispatch(addBookToSeries(params)).unwrap();
		} catch (err) {
			NotificationError(err);
		}
	};

	const handleEditBook = async params => {
		try {
			// Nếu có thay đổi series thì chạy api xử lí
			if (bookData.series?.id !== seriData?.id) {
				// Xóa sách khỏi series cũ
				if (!_.isEmpty(bookData.series)) {
					const paramsForRemoveBookFromSeries = {
						seriesId: bookData.series.id,
						body: { bookIds: [Number(bookId)] },
					};
					await handleRemoveBookFromSeries(paramsForRemoveBookFromSeries).unwrap();
				}

				// Thêm lại sách vào series mới chọn
				if (!_.isEmpty(seriData)) {
					const paramsForAddBookToSeries = {
						seriesId: seriData.id,
						body: { bookIds: [Number(bookId)] },
					};
					await handleAddBookToSeries(paramsForAddBookToSeries).unwrap();
				}
			}

			const data = {
				bookId: bookId,
				params: params,
			};
			const res = await dispatch(editBook(data)).unwrap();
			setBookData(res);
			toast.success('Chỉnh sửa sách thành công');
		} catch (err) {
			NotificationError(err);
		}
	};

	const renderNoSearchResultMessage = option => {
		const handleClick = () => {
			if (option === 'author') {
				const cloneArrAuthors = [...authors];
				cloneArrAuthors.push({
					isUser: false,
					label: selectAuthorInput,
					value: selectAuthorInput,
				});
				setAuthors(cloneArrAuthors);
				setSelectAuthorInput('');
			} else if (option === 'translator') {
				const cloneArrTranslators = [...translators];
				cloneArrTranslators.push({
					isUser: false,
					label: selectTranslatorInput,
					value: selectTranslatorInput,
				});
				setTranslators(cloneArrTranslators);
				setSelectTranslatorInput('');
			}
		};

		return (
			<>
				{selectAuthorInput || selectTranslatorInput ? (
					<>
						<span className='m-1'>Không có kết quả tìm kiếm nào.</span>
						<span className='create-new-author-name' onClick={handleClick}>
							Nhập tên mới
						</span>
					</>
				) : (
					setSuggestionData(initalAuthorsSuggestionList.current)
				)}
			</>
		);
	};

	const getBookBiboo = async value => {
		try {
			axios
				.get('https://kong.mcbooks.vn/beta/kong/books/api/v1/books/public', {
					params: {
						filter: JSON.stringify([
							{ operator: 'eq', value: 'true', property: 'active' },
							{ operator: 'iLike', value: _.trim(value), property: 'name' },
						]),
					},
				})
				.then(function (response) {
					const newData = response.data.data.rows.map(item => {
						return {
							value: item.id,
							label: item.name,
						};
					});
					setSuggestionDataBook(newData);
					initalBooksSuggestionList.current = newData;
				})
				.catch(function (error) {
					return error;
				});
		} catch (error) {
			return;
		}
	};
	const debounceSearchBook = useCallback(_.debounce(getBookBiboo, 700), []);

	useEffect(() => {
		getBookBiboo();
	}, []);

	return (
		<div className='book-informations'>
			<BreadcrumbComponent subItems={breadcrumbData} pageTitle={bookId ? 'Chỉnh sửa sách' : 'Tạo sách'} />
			<Card border='light' className='shadow-sm mb-4'>
				<Card.Body>
					{isPageLoading ? (
						<div
							style={{ height: '500px', width: '100%' }}
							className='d-flex justify-content-center align-items-center'
						>
							<LoadingIndicator />
						</div>
					) : (
						<>
							<Dropzone
								onDrop={acceptedFiles => setImage(acceptedFiles)}
								multiple={false}
								accept={{ 'image/png': ['.png', '.jpeg', '.jpg'] }}
							>
								{({ getRootProps, getInputProps }) => (
									<div
										{...getRootProps({ className: 'upload-front-cover-wrapper sticky-top top-3' })}
									>
										{image ? (
											<>
												{typeof image === 'string' ? (
													<img
														src={image.includes('https') ? image: `https://api.wisfeed.com${image}` }
														alt='img'
														onError={e =>
															e.target.setAttribute('src', `${bookImageDefault}`)
														}
													/>
												) : (
													<img src={URL.createObjectURL(image[0])} alt='img' />
												)}
											</>
										) : (
											<>
												<input {...getInputProps()} />
												<div className='dropzone book-informations__upload-image'>
													<Image />
													<span style={{ fontWeight: 600, marginTop: '5px' }}>
														Thêm ảnh bìa từ thiết bị
													</span>
													<span style={{ marginTop: '5px' }}>
														hoặc kéo thả <span className='text-danger'>(*)</span>
													</span>
												</div>
											</>
										)}
									</div>
								)}
							</Dropzone>
							<div className='book-informations__info-form'>
								<Row>
									<Form.Group>
										<Form.Label>
											Tên sách <span className='text-danger'>*</span>
										</Form.Label>
										<Form.Control
											type='text'
											placeholder='Tên sách'
											name='name'
											value={name}
											onChange={onChange}
										/>
									</Form.Group>
								</Row>

								<Row className='mt-3'>
									<Form.Group>
										<Form.Label>Tiêu đề phụ</Form.Label>
										<Form.Control
											type='text'
											placeholder='Tiêu đề phụ'
											name='subName'
											value={subName}
											onChange={onChange}
										/>
									</Form.Group>
								</Row>

								<Row className='mt-3'>
									<Form.Group>
										<Form.Label>Tên sách gốc</Form.Label>
										<Form.Control
											type='text'
											placeholder='Tên sách gốc'
											name='originalName'
											value={originalName}
											onChange={onChange}
										/>
									</Form.Group>
								</Row>

								<Row className='mt-3'>
									<Form.Group>
										<Form.Label>
											Tác giả<span className='text-danger'> *</span>
										</Form.Label>
										<Select
											placeholder='Tìm kiếm và thêm tác giả'
											options={suggestionData}
											isMulti={true}
											value={authors}
											onChange={data => setAuthors(data)}
											onFocus={() => setSuggestionData(initalAuthorsSuggestionList.current)}
											inputValue={selectAuthorInput}
											onInputChange={value => {
												setSelectAuthorInput(value);
												handleSearch(value, 'author');
											}}
											isLoading={authorLoading}
											loadingMessage={() => <LoadingIndicator />}
											noOptionsMessage={() => renderNoSearchResultMessage('author')}
											components={{
												DropdownIndicator: () => null,
												IndicatorSeparator: () => null,
											}}
										/>
									</Form.Group>
								</Row>

								<Row className='mt-3'>
									<Form.Group>
										<Form.Label>Dịch giả</Form.Label>
										<Select
											placeholder='Tìm kiếm và thêm dịch giả'
											options={suggestionData}
											isMulti={true}
											value={translators}
											onChange={data => setTranslators(data)}
											onFocus={() => setSuggestionData(initalAuthorsSuggestionList.current)}
											inputValue={selectTranslatorInput}
											onInputChange={value => {
												setSelectTranslatorInput(value);
												handleSearch(value, 'translator');
											}}
											isLoading={translatorLoading}
											loadingMessage={() => <LoadingIndicator />}
											noOptionsMessage={() => renderNoSearchResultMessage('translator')}
											components={{
												DropdownIndicator: () => null,
												IndicatorSeparator: () => null,
											}}
										/>
									</Form.Group>
								</Row>

								<Row className='mt-3'>
									<Form.Group>
										<Form.Label>
											Chủ đề <span className='text-danger'>*</span>
										</Form.Label>
										<Select
											placeholder='Tìm kiếm và thêm chủ đề'
											options={suggestionData}
											isMulti={true}
											value={categories}
											onChange={data => onChangeCategories(data)}
											onFocus={() => setSuggestionData(initalCategoriesSuggestionList.current)}
											onInputChange={value => handleSearch(value, 'category')}
											isLoading={categoryLoading}
											loadingMessage={() => <LoadingIndicator />}
											noOptionsMessage={() => (
												<p className='m-1'>Không có kết quả tìm kiếm nào</p>
											)}
											components={{
												DropdownIndicator: () => null,
												IndicatorSeparator: () => null,
											}}
										/>
									</Form.Group>
								</Row>

								<Row className='mt-3'>
									<Form.Group>
										<Form.Label>
											Nhà xuất bản <span className='text-danger'>*</span>
										</Form.Label>
										<Select
											className='w-100'
											placeholder='Tìm kiếm và chọn một nhà xuất bản'
											isClearable={true}
											options={suggestionData}
											value={publisher}
											onChange={data => setPublisher(data)}
											onFocus={() => setSuggestionData(initalPublisherSuggestionList.current)}
											onInputChange={value => handleSearch(value, 'publisher')}
											isLoading={publisherLoading}
											loadingMessage={() => <LoadingIndicator />}
											noOptionsMessage={() => (
												<p className='m-1'>Không có kết quả tìm kiếm nào</p>
											)}
											components={{
												DropdownIndicator: () => null,
												IndicatorSeparator: () => null,
											}}
										/>
									</Form.Group>
								</Row>

								<Row className='mt-3'>
									<Col md={6}>
										<Form.Group>
											<Form.Label>
												ISBN <span className='text-danger'>*</span>
											</Form.Label>
											<Form.Control
												onKeyDown={blockInvalidChar}
												name='isbn'
												placeholder='ISBN'
												value={isbn}
												onChange={onChange}
												onWheel={e => e.target.blur()}
											/>
										</Form.Group>
									</Col>
									<Col md={6}>
										<Form.Group>
											<Form.Label>Ngày phát hành</Form.Label>
											<Datetime
												timeFormat={false}
												onChange={setPublishDate}
												locale='vi'
												renderMonth={(props, month) => <td {...props}>Th{month + 1}</td>}
												renderInput={(props, openCalendar) => (
													<InputGroup>
														<InputGroup.Text>
															<FontAwesomeIcon icon={faCalendarAlt} />
														</InputGroup.Text>
														<Form.Control
															type='text'
															value={
																publishDate
																	? moment(publishDate).format('DD/MM/YYYY')
																	: ''
															}
															placeholder='dd/mm/yyyy'
															onFocus={openCalendar}
															onChange={() => {}}
														/>
													</InputGroup>
												)}
											/>
										</Form.Group>
									</Col>
								</Row>

								<Row className='mt-3'>
									<Col md={4}>
										<Form.Group>
											<Form.Label>
												Số trang <span className='text-danger'>*</span>
											</Form.Label>
											<Form.Control
												type='number'
												onKeyDown={blockInvalidChar}
												placeholder='Số trang'
												name='page'
												value={page}
												onChange={onChange}
												onWheel={e => e.target.blur()}
											/>
										</Form.Group>
									</Col>
									<Col md={4}>
										<Form.Group>
											<Form.Label>
												Ngôn ngữ <span className='text-danger'>*</span>
											</Form.Label>
											<Form.Select
												as='select'
												value={language}
												onChange={e => setLanguage(e.target.value)}
											>
												{listLanguages.map((item, index) => (
													<option key={index} value={item.value}>
														{item.name}
													</option>
												))}
											</Form.Select>
										</Form.Group>
									</Col>
									<Col md={4}>
										<Form.Group>
											<Form.Label>Khổ sách</Form.Label>
											<Form.Control
												placeholder='Khổ sách'
												name='paperSize'
												value={paperSize}
												onChange={onChange}
											/>
										</Form.Group>
									</Col>
								</Row>

								{/* Không xóa  */}
								{/* <Row className='mt-3'>
									<Form.Group>
										<Form.Label>Sê-ri</Form.Label>
										{!_.isEmpty(seriData) ? (
											<div className='form-control d-flex justify-content-between align-items-center p-0'>
												<span
													className='w-100'
													style={{ padding: '0.55rem 0.75rem' }}
													onClick={() => setShowModalSeries(true)}
												>
													{seriData.name}
												</span>
												<FontAwesomeIcon
													className='book-informations__input__remove-btn'
													icon={faTimes}
													onClick={() => setSeriData({})}
												/>
											</div>
										) : (
											<div
												className='form-control'
												style={{ cursor: 'pointer' }}
												onClick={() => setShowModalSeries(true)}
											>
												Sê-ri bộ sách
											</div>
										)}
									</Form.Group>
									<ModalSeries
										showModalSeries={showModalSeries}
										handleCloseModalSeries={() => setShowModalSeries(false)}
										seriData={seriData}
										setSeriData={setSeriData}
									/>
								</Row> */}

								<Row className='mt-3'>
									<Form.Group>
										<Form.Label>
											Mô tả <span className='text-danger'>*</span>
										</Form.Label>
										<div className='rich-text-description'>
											<RichTextEditor
												setTextContent={setDescription}
												placeholder='Nhập nội dung'
												initialContent={initialInputValue.current.description || ''}
												toggleResetText={toggleResetDescription}
											/>
										</div>
									</Form.Group>
								</Row>

								<Row className='mt-3'>
									<b>SEO</b>
								</Row>
								<Row className='mt-3'>
									<Form.Group>
										<Form.Label>Tiêu đề</Form.Label>
										<Form.Control
											type='text'
											placeholder='Tiêu đề'
											name='seoTitle'
											value={seoTitle}
											onChange={onChange}
										/>
									</Form.Group>
								</Row>
								<Row className='mt-3'>
									<Form.Group>
										<Form.Label>Từ khóa</Form.Label>
										<Form.Control
											type='text'
											placeholder='Từ khóa'
											name='seoKeywords'
											value={seoKeywords}
											onChange={onChange}
										/>
									</Form.Group>
								</Row>
								<Row className='mt-3'>
									<Form.Group>
										<Form.Label>Mô tả</Form.Label>
										<Form.Control
											type='text'
											placeholder='Mô tả'
											name='seoDescription'
											value={seoDescription}
											onChange={onChange}
										/>
									</Form.Group>
								</Row>

								<Row className='mt-3'>
									<Form.Group>
										<Form.Label>Link bổ sung</Form.Label>
										<Select
											placeholder='Tìm kiếm và thêm link bổ sung'
											options={LinkAdditional}
											isMulti={true}
											value={link}
											onChange={data => setLink(data)}
											loadingMessage={() => <LoadingIndicator />}
											components={{
												DropdownIndicator: () => null,
												IndicatorSeparator: () => null,
											}}
											isSearchable={false}
										/>
									</Form.Group>
							
									<Form.Group>
										{link.length > 0 &&
											link.map((item, index) => {
												return (
													<div className='mt-3' key={index}>
														<div>Link bổ sung {item.label}</div>
														{item.value === 'biboo' ? (
															<Select
																placeholder='Tìm kiếm sách trên biboo'
																options={suggestionDataBook}
																isMulti={false}
																value={bookBiboo}
																onChange={data => {
																	const newData = {
																		value: data.value,
																		label: `https://biboo.com.vn/books/${data.label
																			.split(' ')
																			.join('')}/${
																			data.value
																		}?isShare=true&page=bookDetail&idBook=${
																			data.value
																		}`,
																	};
																	setLinkItem({ ...linkItem, Biboo: newData.label });
																	setBookBiboo(newData);
																}}
																onFocus={() =>
																	setSuggestionDataBook(
																		initalBooksSuggestionList.current
																	)
																}
																inputValue={searchBiboo}
																onInputChange={value => {
																	setSearchBiboo(value);
																	debounceSearchBook(value);
																}}
																isLoading={translatorLoading}
																loadingMessage={() => <LoadingIndicator />}
																components={{
																	DropdownIndicator: () => null,
																	IndicatorSeparator: () => null,
																}}
															/>
														) : (
															<Form.Control
																type='text'
																placeholder={`Link bổ sung ${item.label}`}
																name={`${item.label.replace(/\s+/g, '')}`}
																value={linkItem[item.label.replace(/\s+/g, '')]}
																onChange={onChangeLink}
															/>
														)}
													</div>
												);
											})}
									</Form.Group>
								</Row>

								<Row className='mt-5'>
									<Col md={6}>
										<Button className='w-100' variant='outline-danger' onClick={clearAllData}>
											{bookId ? 'Quay lại dữ liệu ban đầu' : 'Xóa tất cả'}
										</Button>
									</Col>
									<Col md={6}>
										<Button
											className='w-100'
											variant='primary'
											onClick={handleSubmit}
											disabled={!buttonActive}
										>
											Lưu
										</Button>
									</Col>
								</Row>
							</div>
						</>
					)}
				</Card.Body>
			</Card>
		</div>
	);
}

export default BookInformations;
