import { faEdit, faPlusCircle, faSearch, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Form, InputGroup, Modal, Row, Table } from '@themesberg/react-bootstrap';
import BreadcrumbComponent from 'components/BreadcrumbComponent';
import CustomDropdownMenu from 'components/CustomDropdownMenu';
import LoadingIndicator from 'components/LoadingIndicator';
import MyModal from 'components/Modal';
import PaginationComponent from 'components/PaginationComponent';
import { breadcrumbsData } from 'constants';
import { NotificationError } from 'helpers/Error';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { addPublisher, deletePublisher, editPublisher, getPublishers } from 'reducers/publisher';

export default function Publisher() {
	const breadcrumbData = [breadcrumbsData.publisherManager.publisher];
	const dispatch = useDispatch();

	const [listPublisher, setListPublisher] = useState([]);
	const [isloading, setIsLoading] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPage, setTotalPage] = useState(1);
	const [sortById, setSortById] = useState('DESC');
	const [sortByName, setSortByName] = useState('');
	const [inputSearchValue, setInputSearchValue] = useState('');
	const [filterValue, setFilterValue] = useState('');

	const [choosenPublisher, setChoosenPublisher] = useState('');
	const [showModal, setShowModal] = useState(false);
	const [isFetching, setIsFetching] = useState(false);
	const [name, setName] = useState('');
	const [contact, setContact] = useState('');

	const [showModalConfirmDelete, setShowModalConfirmDelete] = useState(false);

	const callApiPerPage = useRef(10);
	const totalPublisher = useRef(0);

	useEffect(() => {
		callAPIGetPublisher();
	}, [currentPage, sortById, sortByName, filterValue]);

	useEffect(() => {
		if (inputSearchValue === '' && inputSearchValue !== filterValue) {
			setTimeout(() => setFilterValue(''), 700);
		}
	}, [inputSearchValue]);

	const callAPIGetPublisher = async () => {
		setIsLoading(true);
		try {
			const params = {
				start: (currentPage - 1) * callApiPerPage.current,
				limit: callApiPerPage.current,
				sort: JSON.stringify([{ direction: sortById || sortByName, property: sortById ? 'id' : 'name' }]),
				filter: JSON.stringify([
					{ 'operator': 'search', 'value': filterValue, 'property': 'name' },
					{ 'operator': 'eq', 'value': false, 'property': 'isDeleted' },
				]),
			};
			const res = await dispatch(getPublishers(params)).unwrap();
			setListPublisher(res.rows);
			setTotalPage(Math.ceil(res.count / callApiPerPage.current));
			totalPublisher.current = res.count;
		} catch (err) {
			NotificationError(err);
		} finally {
			setIsLoading(false);
		}
	};

	const onChangeSortId = value => {
		setSortById(value);
		setSortByName('');
	};

	const onChangeSortName = value => {
		setSortByName(value);
		setSortById('');
	};

	const handleSearch = e => {
		if (e.key === 'Enter' && inputSearchValue.trim() !== filterValue) {
			setFilterValue(inputSearchValue.trim());
			if (currentPage !== 1) {
				setCurrentPage(1);
			} else {
				callAPIGetPublisher();
			}
		}
	};

	const handleShowModal = item => {
		setChoosenPublisher(item);
		setName(item?.name || '');
		setContact(item?.contact || '');
		setShowModal(true);
	};

	const handleCloseModal = () => {
		setShowModal(false);
	};

	const handleAddPublisher = async () => {
		setIsFetching(true);
		const params = {
			name: name,
			contact: contact,
		};
		try {
			await dispatch(addPublisher(params)).unwrap();
			toast.success('Thêm NXB thành công', { toastId: 'success-add-publisher' });
			setShowModal(false);
			callAPIGetPublisher();
		} catch (err) {
			NotificationError(err);
		} finally {
			setIsFetching(false);
		}
	};

	const handleEditPublisher = async () => {
		setIsFetching(true);
		const params = {
			id: choosenPublisher.id,
			body: {
				name: name,
				contact: contact,
			},
		};
		try {
			await dispatch(editPublisher(params)).unwrap();
			toast.success('Chỉnh sửa thành công', { toastId: 'success-edit-publisher' });
			setShowModal(false);
			callAPIGetPublisher();
		} catch (err) {
			NotificationError(err);
		} finally {
			setIsFetching(false);
		}
	};

	const handleShowModalDeleteItem = item => {
		setChoosenPublisher(item);
		setShowModalConfirmDelete(true);
	};

	const confirmDelete = async () => {
		try {
			await dispatch(deletePublisher(choosenPublisher.id)).unwrap();
			toast.success('Xóa thành công', { toastId: 'success-delete-publisher' });
			setShowModalConfirmDelete(false);
			callAPIGetPublisher();
		} catch (err) {
			NotificationError(err);
		}
	};

	return (
		<div className='publisher'>
			<div className='d-flex justify-content-between align-items-end'>
				<BreadcrumbComponent
					subItems={breadcrumbData}
					pageTitle={`Danh sách Nhà xuất bản (${totalPublisher.current})`}
				/>
				<div className='d-flex gap-3 align-items-center' style={{ padding: '24px 0' }}>
					<div>
						<InputGroup className='input-group-merge search-bar'>
							<InputGroup.Text>
								<FontAwesomeIcon icon={faSearch} />
							</InputGroup.Text>
							<Form.Control
								placeholder='Tìm kiếm NXB'
								value={inputSearchValue}
								onChange={e => setInputSearchValue(e.target.value)}
								onKeyPress={e => handleSearch(e)}
							/>
						</InputGroup>
					</div>
					<Button variant='secondary' onClick={() => handleShowModal(null)}>
						<FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: '12px' }} />
						Thêm NXB
					</Button>
				</div>
			</div>
			<Card border='light' className='shadow-sm mb-4'>
				<Card.Body>
					{isloading ? (
						<div className='d-flex justify-content-center align-items-center' style={{ height: '580px' }}>
							<LoadingIndicator />
						</div>
					) : (
						<>
							<Table responsive className='table-centered table-nowrap rounded mb-0'>
								<thead className='thead-light'>
									<tr>
										<th className='border-0'>
											<CustomDropdownMenu
												title='id'
												dataArray={[
													{ value: 'DESC', title: 'Lớn đến bé (mới nhất)' },
													{ value: 'ASC', title: 'Bé đến lớn (cũ nhất)' },
												]}
												onFilterChange={onChangeSortId}
												filterValue={sortById}
											/>
										</th>
										<th className='border-0'>
											<CustomDropdownMenu
												title='tên nhà xuất bản'
												dataArray={[
													{ value: 'ASC', title: 'A -> Z' },
													{ value: 'DESC', title: 'Z -> A' },
												]}
												onFilterChange={onChangeSortName}
												filterValue={sortByName}
											/>
										</th>
										<th className='border-0'>Hoạt động</th>
									</tr>
								</thead>
								{listPublisher.length > 0 && (
									<tbody>
										{listPublisher.map((item, index) => {
											return (
												<tr key={index}>
													<td>{item.id}</td>
													<td>{item.name}</td>
													<td>
														<div className='d-flex gap-1'>
															<Button
																variant='light'
																size='sm'
																title='Chỉnh sửa'
																onClick={() => handleShowModal(item)}
															>
																<FontAwesomeIcon
																	className='text-success'
																	icon={faEdit}
																/>
															</Button>
															<Button
																variant='light'
																size='sm'
																title='Xóa'
																onClick={() => handleShowModalDeleteItem(item)}
															>
																<FontAwesomeIcon
																	className='text-danger'
																	icon={faTrashAlt}
																/>
															</Button>
														</div>
													</td>
												</tr>
											);
										})}
									</tbody>
								)}
							</Table>
							<PaginationComponent
								currentPage={currentPage}
								totalPage={totalPage}
								setCurrentPage={setCurrentPage}
							/>
							{listPublisher.length === 0 && (
								<p className='position-absolute start-50 translate-middle margin-negative'>
									Không có kết quả nào
								</p>
							)}
						</>
					)}
				</Card.Body>
			</Card>

			{/* Thêm hoặc chỉnh sửa nhà xuất bản */}
			<Modal className='modal-add-publisher' show={showModal} onHide={handleCloseModal} centered>
				<Modal.Header>
					<h5 style={{ margin: 'auto' }}>{choosenPublisher ? 'Chỉnh sửa NXB' : 'Tạo mới NXB'}</h5>
				</Modal.Header>
				<Modal.Body>
					<Row>
						<Form.Group>
							<Form.Label>Tên</Form.Label>
							<span className='text-danger'> *</span>
							<Form.Control value={name} onChange={e => setName(e.target.value)} />
						</Form.Group>
					</Row>
					<Row className='mt-3'>
						<Form.Group className='mb-3'>
							<Form.Label>Liên hệ</Form.Label>
							<Form.Control value={contact} onChange={e => setContact(e.target.value)} />
						</Form.Group>
					</Row>
				</Modal.Body>
				<Modal.Footer>
					{choosenPublisher ? (
						<Button variant='info' onClick={handleEditPublisher} disabled={isFetching}>
							Chỉnh sửa
						</Button>
					) : (
						<Button variant='success' onClick={handleAddPublisher} disabled={isFetching}>
							Tạo mới
						</Button>
					)}
					<Button variant='light' onClick={() => setShowModal(false)}>
						Quay lại
					</Button>
				</Modal.Footer>
			</Modal>

			<MyModal
				show={showModalConfirmDelete}
				onHide={() => setShowModalConfirmDelete(false)}
				onDelete={confirmDelete}
				title={'Xác nhận xóa NXB'}
				type={'nhà xuất bản'}
			/>
		</div>
	);
}
