import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Request from 'helpers/Request';
import { editPublishersAPI, getPublishersAPI } from 'constants/apiURL';

export const getPublishers = createAsyncThunk('publishers/getPublisher', async (params, { rejectWithValue }) => {
	try {
		const res = await Request.makeGet(getPublishersAPI, params);
		return res.data;
	} catch (err) {
		const error = err.response.message;
		return rejectWithValue(error);
	}
});

export const addPublisher = createAsyncThunk('publishers/addPublisher', async (params, { rejectWithValue }) => {
	try {
		const res = await Request.makePost(getPublishersAPI, params);
		return res.data;
	} catch (err) {
		const error = err.response.message;
		return rejectWithValue(error);
	}
});

export const editPublisher = createAsyncThunk('publishers/editPublisher', async (params, { rejectWithValue }) => {
	const { id, body } = params;
	try {
		const res = await Request.makePatch(editPublishersAPI(id), body);
		return res.data;
	} catch (err) {
		const error = err.response.message;
		return rejectWithValue(error);
	}
});

export const deletePublisher = createAsyncThunk('publishers/deletePublisher', async (id, { rejectWithValue }) => {
	try {
		const res = await Request.makeDelete(editPublishersAPI(id));
		return res.data;
	} catch (err) {
		const error = err.response.message;
		return rejectWithValue(error);
	}
});

const publishersSlice = createSlice({
	name: 'publishers',
	initialState: {},
	reducers: {},
});

// export const { } = publishersSlice.actions;

const publishers = publishersSlice.reducer;
export default publishers;
