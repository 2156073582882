import { faCheck, faInfo, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Button, Card, InputGroup, Modal, Table, Form } from '@themesberg/react-bootstrap';
import BookThumbnail from 'components/BookThumbnail';
import BreadcrumbComponent from 'components/BreadcrumbComponent';
import CustomDropdownMenu from 'components/CustomDropdownMenu';
import LoadingIndicator from 'components/LoadingIndicator';
import PaginationComponent from 'components/PaginationComponent';
import { breadcrumbsData } from 'constants';
import { generateAuthorsName, generateCategories, generateTime, generateTranslatorsName } from 'helpers/Common';
import { NotificationError } from 'helpers/Error';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { confirmApproveBook, getListBookPending } from 'reducers/books';

function BookApproval() {
	const breadcrumbData = [breadcrumbsData.booksManager.bookApproval];

	const [bookPendingList, setBookPendingList] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPage, setTotalPage] = useState(1);
	const [isloading, setIsLoading] = useState(true);
	const [showModal, setShowModal] = useState(false);
	const [sortByDate, setSortByDate] = useState('DESC');
	const [sortByName, setSortByName] = useState('');
	const [inputSearchValue, setInputSearchValue] = useState('');
	const [filterStatus, setFilterStatus] = useState('');
	const [isConfirmLoading, setIsConfirmLoading] = useState(false);

	const callApiPerPage = useRef(10);
	const currentItemInfo = useRef({});
	const previousFilterText = useRef(null);

	const dispatch = useDispatch();

	const generateStatus = item => {
		let bg = '',
			text = '';
		switch (item.status) {
			case 'pending':
				bg = 'warning';
				text = 'Chờ duyệt';
				break;
			case 'approved':
				bg = 'success';
				text = 'Đã duyệt';
				break;
			case 'rejected':
				bg = 'secondary';
				text = 'Đã tồn tại';
				break;
			default:
				return;
		}
		return (
			<Badge className='p-2' bg={bg} style={{ fontSize: '0.75rem' }}>
				{text}
			</Badge>
		);
	};

	useEffect(() => {
		if ((sortByDate && !sortByName) || (sortByName && !sortByDate)) {
			getBookPendingData();
		}
	}, [currentPage, sortByDate, sortByName, filterStatus]);

	const getBookPendingData = async () => {
		setIsLoading(true);
		try {
			const params = {
				start: (currentPage - 1) * callApiPerPage.current,
				limit: callApiPerPage.current,
				sort: JSON.stringify([
					{ direction: sortByName || sortByDate, property: sortByName ? 'name' : 'createdAt' },
				]),
				filter: JSON.stringify([
					filterStatus && {
						operator: 'eq',
						value: filterStatus,
						property: 'status',
					},
					inputSearchValue !== '' && {
						operator: 'search',
						value: inputSearchValue.trim(),
						property: 'name',
					},
				]),
			};
			const res = await dispatch(getListBookPending(params)).unwrap();
			setBookPendingList(res.rows);
			setTotalPage(Math.ceil(res.count / callApiPerPage.current));
			previousFilterText.current = inputSearchValue.trim();
		} catch (error) {
			NotificationError(error);
		} finally {
			setIsLoading(false);
		}
	};

	const handleConfirmApproveBook = async id => {
		setIsConfirmLoading(true);
		try {
			await dispatch(confirmApproveBook(id)).unwrap();
			toast.success('Duyệt sách thành công');
			getBookPendingData();
		} catch (error) {
			NotificationError(error);
		} finally {
			setShowModal(false);
			setIsConfirmLoading(false);
		}
	};

	const handleShowModalItemInfo = item => {
		currentItemInfo.current = item;
		setShowModal(true);
	};

	const onChangeSortName = value => {
		setSortByName(value);
		setSortByDate('');
	};

	const onChangeSortDate = value => {
		setSortByDate(value);
		setSortByName('');
	};

	const onChangeInputSearchValue = e => {
		setInputSearchValue(e.target.value);
	};

	const handleKeyDown = e => {
		if (e.key === 'Enter' && inputSearchValue.trim().length && inputSearchValue !== previousFilterText.current) {
			setCurrentPage(1);
			getBookPendingData();
		}
	};

	const onChangeFilterStatus = value => {
		setCurrentPage(1);
		setFilterStatus(value);
	};

	return (
		<>
			{isConfirmLoading && (
				<div className='loading-fixed'>
					<LoadingIndicator />
				</div>
			)}
			<div className='d-flex justify-content-between align-items-end'>
				<BreadcrumbComponent subItems={breadcrumbData} pageTitle='Duyệt sách' />
				<div className='d-flex justify-content-between align-items-center' style={{ marginBottom: '20px' }}>
					<InputGroup className='input-group-merge search-bar'>
						<InputGroup.Text>
							<FontAwesomeIcon icon={faSearch} />
						</InputGroup.Text>
						<Form.Control
							type='text'
							placeholder='Tìm kiếm sách'
							value={inputSearchValue}
							onChange={onChangeInputSearchValue}
							onKeyDown={handleKeyDown}
						/>
					</InputGroup>
				</div>
			</div>
			<Card border='light' className='shadow-sm mb-4'>
				<Card.Body className='pb-0'>
					{isloading ? (
						<div className='d-flex justify-content-center align-items-center' style={{ height: '580px' }}>
							<LoadingIndicator />
						</div>
					) : (
						<>
							<Table responsive className='table-centered table-nowrap rounded mb-0'>
								<thead className='thead-light'>
									<tr>
										<th className='border-0' style={{ width: '4%' }}>
											id
										</th>
										<th className='border-0' style={{ width: '5%' }}>
											bìa sách
										</th>
										<th className='border-0'>
											<CustomDropdownMenu
												title='tên sách'
												dataArray={[
													{ value: 'ASC', title: 'A -> Z' },
													{ value: 'DESC', title: 'Z -> A' },
												]}
												onFilterChange={onChangeSortName}
												filterValue={sortByName}
											/>
										</th>
										<th className='border-0' style={{ width: '20%' }}>
											tác giả
										</th>
										<th className='border-0'>
											<CustomDropdownMenu
												title='ngày đăng'
												dataArray={[
													{ value: 'DESC', title: 'Mới nhất' },
													{ value: 'ASC', title: 'Cũ nhất' },
												]}
												onFilterChange={onChangeSortDate}
												filterValue={sortByDate}
											/>
										</th>
										<th className='border-0' style={{ width: '10%' }}>
											<CustomDropdownMenu
												title='trạng thái'
												dataArray={[
													{ value: '', title: 'Tất cả' },
													{ value: 'pending', title: 'Chờ duyệt' },
													{ value: 'approved', title: 'Đã duyệt' },
													{ value: 'rejected', title: 'Đã tồn tại' },
												]}
												onFilterChange={onChangeFilterStatus}
												filterValue={filterStatus}
											/>
										</th>
										<th className='border-0' style={{ width: '5%' }}>
											hoạt động
										</th>
									</tr>
								</thead>
								{bookPendingList.length > 0 && (
									<tbody>
										{bookPendingList.map(item => (
											<tr key={item.id}>
												<td>{item.id}</td>
												<td>
													<BookThumbnail src={item.frontBookCover} />
												</td>
												<td title={item.name}>{item.name}</td>
												<td title={generateAuthorsName(item.authors)}>
													{generateAuthorsName(item.authors)}
												</td>
												<td>{generateTime(item.createdAt)}</td>
												<td>{generateStatus(item)}</td>
												<td>
													<div className='d-flex gap-1'>
														<Button
															style={{ width: '31px' }}
															variant='light'
															title='Chi tiết'
															size='sm'
															onClick={() => handleShowModalItemInfo(item)}
														>
															<FontAwesomeIcon className='text-info' icon={faInfo} />
														</Button>
														{item.status === 'pending' && (
															<Button
																variant='light'
																size='sm'
																title='Đồng ý duyệt'
																onClick={() => handleConfirmApproveBook(item.id)}
															>
																<FontAwesomeIcon
																	className='text-success'
																	icon={faCheck}
																	role='button'
																/>
															</Button>
														)}
													</div>
												</td>
											</tr>
										))}
									</tbody>
								)}
							</Table>
							<PaginationComponent
								currentPage={currentPage}
								totalPage={totalPage}
								setCurrentPage={setCurrentPage}
							/>

							{bookPendingList.length === 0 && (
								<p className='position-absolute start-50 translate-middle margin-negative'>
									Không có kết quả
								</p>
							)}
						</>
					)}
				</Card.Body>
			</Card>
			{!_.isEmpty(currentItemInfo.current) && (
				<Modal className='modal-approve-book-info' show={showModal} onHide={() => setShowModal(false)} centered>
					<Modal.Header>
						<h5 style={{ margin: 'auto' }}>Chi tiết</h5>
					</Modal.Header>
					<Modal.Body>
						<div className='d-flex'>
							<div className='modal-approve-book-info__book-thumbnail'>
								<BookThumbnail src={currentItemInfo.current.frontBookCover} />
							</div>
							<div style={{ fontSize: '15px' }}>
								<div className='mb-2'>
									Tên sách: <strong>{currentItemInfo.current.name}</strong>
								</div>
								<div className='mb-2'>
									Tiêu đề phụ:{' '}
									<strong>
										{currentItemInfo.current.subName ? currentItemInfo.current.subName : 'Không có'}
									</strong>
								</div>
								<div className='mb-2'>
									Tên sách gốc:{' '}
									<strong>
										{currentItemInfo.current.originalName
											? currentItemInfo.current.originalName
											: 'Không có'}
									</strong>
								</div>
								<div className='mb-2'>
									Tác giả: <strong>{generateAuthorsName(currentItemInfo.current.authors)}</strong>
								</div>
								<div className='mb-2'>
									Dịch giả:{' '}
									<strong>{generateTranslatorsName(currentItemInfo.current.translators)}</strong>
								</div>
								<div className='mb-2'>
									Chủ đề: <strong>{generateCategories(currentItemInfo.current.categories)}</strong>
								</div>
								<div className='mb-2'>
									Nhà xuất bản: <strong>{currentItemInfo.current.publisher?.name}</strong>
								</div>
								<div className='mb-2'>
									ISBN: <strong>{currentItemInfo.current.isbn}</strong>
								</div>
								<div className='mb-2'>
									Ngày phát hành:{' '}
									<strong>
										{currentItemInfo.current.publishDate
											? moment(currentItemInfo.current.publishDate).format('DD-MM-YYYY')
											: 'Chưa xác định'}
									</strong>
								</div>
								<div className='mb-2'>
									Số trang: <strong>{currentItemInfo.current.page}</strong>
								</div>
								<div className='mb-2'>
									Ngôn ngữ:{' '}
									<strong>
										{currentItemInfo.current.language === 'vn' ? 'Việt Nam' : 'Tiếng Anh'}
									</strong>
								</div>
							</div>
						</div>
					</Modal.Body>
					<Modal.Footer>
						{currentItemInfo.current.status === 'pending' && (
							<Button
								variant='success'
								onClick={() => handleConfirmApproveBook(currentItemInfo.current.id)}
							>
								Duyệt sách
							</Button>
						)}
						<Button variant='light' onClick={() => setShowModal(false)}>
							Đóng
						</Button>
					</Modal.Footer>
				</Modal>
			)}
		</>
	);
}

export default BookApproval;
