import { faLongArrowAltRight, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Form, InputGroup, Table } from '@themesberg/react-bootstrap';
import BreadcrumbComponent from 'components/BreadcrumbComponent';
import CustomDropdownMenu from 'components/CustomDropdownMenu';
import LoadingIndicator from 'components/LoadingIndicator';
import PaginationComponent from 'components/PaginationComponent';
import { BASE_URL } from 'constants';
import { breadcrumbsData } from 'constants';
import { generateTime } from 'helpers/Common';
import { NotificationError } from 'helpers/Error';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getListHasgTag } from 'reducers/hashtag';

const Hashtag = () => {
	const [listHashTag, setListHashTag] = useState([]);
	const [isloading, setIsLoading] = useState(false);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPage, setTotalPage] = useState(1);
	const [filterText, setFilterText] = useState('');
	const [previousFilterText, setPreviousFilterText] = useState('');
	const [sort, setSort] = useState('DESC');

	const totalHashTag = useRef(0);
	const callApiPerPage = useRef(10);
	const dispatch = useDispatch();

	const breadcrumbData = [breadcrumbsData.hashtagManger.hashtag];

	useEffect(() => {
		getDataHashtag();
	}, [currentPage, sort]);

	useEffect(() => {
		if (filterText === '' && filterText !== previousFilterText) {
			setTimeout(getDataHashtag, 700);
		}
	}, [filterText]);

	const getDataHashtag = async () => {
		setIsLoading(true);
		try {
			const params = {
				start: (currentPage - 1) * callApiPerPage.current,
				limit: callApiPerPage.current,
				sort: JSON.stringify([{ direction: sort, property: 'createdAt' }]),
				filter: JSON.stringify([{ 'operator': 'search', 'value': filterText.trim(), 'property': 'name' }]),
			};
			const res = await dispatch(getListHasgTag(params)).unwrap();
			setListHashTag(res.rows);
			setTotalPage(Math.ceil(res.count / callApiPerPage.current));
			totalHashTag.current = res.count;
		} catch (err) {
			NotificationError(err);
		} finally {
			setIsLoading(false);
			setPreviousFilterText(filterText.trim());
		}
	};

	const handleNavigate = item => {
		const removeSharp = item.name.replace('#', '');
		window.open(`${BASE_URL}hashtag/${removeSharp}`);
	};

	const onKeyPressSearchValue = e => {
		if (e.key === 'Enter' && filterText.trim() !== previousFilterText) {
			if (currentPage !== 1) {
				setCurrentPage(1);
			} else {
				getDataHashtag();
			}
		}
	};

	return (
		<div className='hashtag'>
			<div className='d-flex justify-content-between align-items-end'>
				<BreadcrumbComponent
					subItems={breadcrumbData}
					pageTitle={`Danh sách Hashtag (${totalHashTag.current})`}
				/>
				<div style={{ padding: '24px 0' }}>
					<div>
						<InputGroup className='input-group-merge search-bar'>
							<InputGroup.Text>
								<FontAwesomeIcon icon={faSearch} />
							</InputGroup.Text>
							<Form.Control
								placeholder='Tìm kiếm hashtag'
								value={filterText}
								onChange={e => setFilterText(e.target.value)}
								onKeyPress={e => onKeyPressSearchValue(e)}
							/>
						</InputGroup>
					</div>
				</div>
			</div>
			<Card border='light' className='shadow-sm mb-4'>
				<Card.Body>
					{isloading ? (
						<div className='d-flex justify-content-center align-items-center' style={{ height: '580px' }}>
							<LoadingIndicator />
						</div>
					) : (
						<>
							<Table responsive className='table-centered table-nowrap rounded mb-0'>
								<thead className='thead-light'>
									<tr>
										<th className='border-0'>ID</th>
										<th className='border-0'>Hashtag</th>
										<th className='border-0'>
											<CustomDropdownMenu
												title='Ngày tạo'
												dataArray={[
													{ value: 'DESC', title: 'Mới nhất' },
													{ value: 'ASC', title: 'Cũ nhất' },
												]}
												onFilterChange={setSort}
												filterValue={sort}
											/>
										</th>
										<th className='border-0'>Hoạt động</th>
									</tr>
								</thead>
								{listHashTag.length > 0 && (
									<tbody>
										{listHashTag.map((item, index) => {
											return (
												<tr key={index}>
													<td>{item.id}</td>
													<td>{item.name.slice(1, item.name.length)}</td>
													<td>{generateTime(item.createdAt)}</td>
													<td>
														<div className='d-flex gap-1'>
															<Button
																variant='light'
																size='sm'
																title='Xem bài viết'
																onClick={() => handleNavigate(item)}
															>
																<FontAwesomeIcon
																	icon={faLongArrowAltRight}
																	style={{ transform: 'rotate(-45deg)' }}
																/>
															</Button>
														</div>
													</td>
												</tr>
											);
										})}
									</tbody>
								)}
							</Table>
							<PaginationComponent
								currentPage={currentPage}
								totalPage={totalPage}
								setCurrentPage={setCurrentPage}
							/>
							{listHashTag.length === 0 && (
								<p className='position-absolute start-50 translate-middle margin-negative'>
									Không có kết quả nào
								</p>
							)}
						</>
					)}
				</Card.Body>
			</Card>
		</div>
	);
};
export default Hashtag;
