import { faEdit, faPlusCircle, faSearch, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Form, InputGroup, Modal, Table } from '@themesberg/react-bootstrap';
import BreadcrumbComponent from 'components/BreadcrumbComponent';
import CustomDropdownMenu from 'components/CustomDropdownMenu';
import MyModal from 'components/Modal';
import PaginationComponent from 'components/PaginationComponent';
import RichTextEditor from 'components/RichTextEditor';
import { breadcrumbsData, maxCategoriesNumberAdded } from 'constants';
import { NotificationError } from 'helpers/Error';
import _ from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { createCategory, deleteCategory, editCategory, getCategoryList } from 'reducers/category';
import LoadingIndicator from '../../components/LoadingIndicator';

export default function Category() {
	const [itemCategory, setItemCategory] = useState({});

	const [showModalDelete, setShowModalDelete] = useState(false);
	const [showModalDetail, setShowModalDetail] = useState(false);
	const [showModalAddCategory, setShowModalAddCategory] = useState(false);

	const [sortById, setSortById] = useState('DESC');
	const [sortByName, setSortByName] = useState('');
	const [filterIsTop, setFilterIsTop] = useState('all');
	const [filterHasBook, setFilterHasBook] = useState('all');
	const [filterText, setFilterText] = useState('');
	const [previousFilterText, setPreviousFilterText] = useState('');
	const [categoryList, setCategoryList] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPage, setTotalPage] = useState(1);

	const [isloading, setIsLoading] = useState(false);
	const [suggestionData, setSuggestionData] = useState([]);
	const [categoryLoading, setCategoryLoading] = useState(false);
	const [categories, setCategories] = useState([]);

	const [valueInput, setValueInput] = useState('');
	const [valueInputAddCategory, setValueInputAddCategory] = useState();
	const [valueDescription, setValueDescription] = useState('');
	const [checkedCategory, setCheckedCategory] = useState(false);

	const callApiPerPage = useRef(10);
	const initialCategoriesSuggestionList = useRef([]);
	const currentItem = useRef({});
	const totalCategory = useRef(0);

	const breadcrumbData = [breadcrumbsData.categoryManager.category];

	const dispatch = useDispatch();

	useEffect(() => {
		getCategoryListData();
	}, [currentPage, filterHasBook, filterIsTop, sortById, sortByName]);

	useEffect(() => {
		if (filterText === '' && filterText !== previousFilterText) {
			setTimeout(getCategoryListData, 700);
		}
	}, [filterText]);

	useEffect(() => {
		setValueInput(itemCategory.name);
	}, [itemCategory]);

	const getCategoryListData = async () => {
		setIsLoading(true);
		try {
			const params = {
				start: (currentPage - 1) * callApiPerPage.current,
				limit: callApiPerPage.current,
				sort: JSON.stringify([{ direction: sortById || sortByName, property: sortById ? 'id' : 'name' }]),
				filter: JSON.stringify([
					{
						operator: 'search',
						value: filterText.trim(),
						property: 'name',
					},
					filterIsTop !== 'all' && {
						operator: 'eq',
						value: filterIsTop,
						property: 'isTopCategory',
					},
					filterHasBook !== 'all' && {
						operator: filterHasBook,
						value: 0,
						property: 'numberBooks',
					},
				]),
			};
			const categoryListData = await dispatch(getCategoryList({ option: false, params })).unwrap();
			setCategoryList(categoryListData.rows);
			setTotalPage(Math.ceil(categoryListData.count / callApiPerPage.current));
			totalCategory.current = categoryListData.count;
			if (currentPage === 1) {
				if (categoryListData.rows.length) {
					const dataTemp = categoryListData.rows.map(item => {
						return {
							value: item.id,
							label: item.name,
						};
					});
					initialCategoriesSuggestionList.current = dataTemp;
					setSuggestionData(dataTemp);
				}
			}
		} catch (error) {
			NotificationError(error);
		} finally {
			setIsLoading(false);
			setPreviousFilterText(filterText.trim());
		}
	};

	const getSuggestionData = async input => {
		try {
			if (input) {
				const params = {
					option: false,
					filter: JSON.stringify([{ 'operator': 'search', 'value': input.trim(), 'property': 'name' }]),
				};
				const categoryListData = await dispatch(getCategoryList({ option: false, params })).unwrap();
				const dataTemp = categoryListData.rows.map(item => {
					return {
						value: item.id,
						label: item.name,
					};
				});
				setSuggestionData(dataTemp);
			}
		} catch (error) {
			NotificationError(error);
		} finally {
			setCategoryLoading(false);
		}
	};

	const debounceSearch = useCallback(_.debounce(getSuggestionData, 700), []);

	const handleSearch = inputValue => {
		debounceSearch(inputValue);
		setCategoryLoading(true);
		if (inputValue) {
			setSuggestionData([]);
		} else {
			setCategoryLoading(false);
			setSuggestionData(initialCategoriesSuggestionList.current);
		}
	};

	const onChangeCategories = categoriesArray => {
		if (categoriesArray.length <= maxCategoriesNumberAdded) {
			setCategories(categoriesArray);
		} else {
			toast.warning(`Chỉ được chọn tối đa ${maxCategoriesNumberAdded} chủ đề`);
		}
	};

	const handleChange = e => {
		setFilterText(e.target.value);
	};

	const handleShowModal = item => {
		currentItem.current = item;
		setShowModalDelete(true);
	};

	const handleDelete = async () => {
		try {
			await dispatch(deleteCategory(currentItem.current.id)).unwrap();
			setShowModalDelete(false);
			toast.success('Xóa thành công');
			getCategoryListData();
		} catch (error) {
			NotificationError(error);
		}
	};

	const handleShowModalItemInfo = item => {
		currentItem.current = item;
		setItemCategory(item);
		setCheckedCategory(item.isTopCategory ? true : false);
		if (item.recommendCategories.length > 0) {
			const arr = [];
			item.recommendCategories.forEach(cate => arr.push({ value: cate.id, label: cate.name }));
			setCategories(arr);
		} else {
			setCategories([]);
		}

		setShowModalDetail(true);
	};

	const handleShowModalAddCategory = () => {
		setCategories([]);
		setShowModalAddCategory(true);
	};

	const onKeyPressInputSearch = e => {
		if (e.key === 'Enter' && filterText.trim() !== previousFilterText) {
			setCurrentPage(1);
			if (currentPage !== 1) {
				setCurrentPage(1);
			} else {
				getCategoryListData();
			}
		}
	};

	const handleAddCategory = async () => {
		if (valueInputAddCategory.trim()) {
			try {
				const params = {
					name: valueInputAddCategory,
					description: valueDescription,
				};
				const res = await dispatch(createCategory({ option: true, params })).unwrap();

				if (categories.length > 0) {
					await dispatch(
						editCategory({
							id: res.id,
							params: {
								name: res.name,
								isTopCategory: false,
								recommendCategories: categories.map(item => item.value),
							},
						})
					).unwrap();
				}

				setShowModalAddCategory(false);
				toast.success('Thêm chủ đề thành công');
				getCategoryListData();
			} catch (err) {
				NotificationError(err);
			}
		}
	};

	const handleEditCategory = async () => {
		try {
			const params = {
				name: valueInput,
				isTopCategory: checkedCategory,
				recommendCategories: categories.map(item => item.value),
			};
			await dispatch(editCategory({ id: currentItem.current.id, params: params })).unwrap();
			setShowModalDetail(false);
			toast.success('sửa chủ đề thành công');
			getCategoryListData();
		} catch (err) {
			NotificationError(err);
		}
	};

	const onChangeSortId = value => {
		setSortById(value);
		setSortByName('');
	};

	const onChangeSortName = value => {
		setSortByName(value);
		setSortById('');
	};

	const onChangeFilterHasBook = value => {
		setCurrentPage(1);
		setFilterHasBook(value);
	};

	const onChangeFilterIsTop = value => {
		setCurrentPage(1);
		setFilterIsTop(value);
	};

	return (
		<>
			<div className='category'>
				<div className='d-flex justify-content-between align-items-end'>
					<BreadcrumbComponent
						subItems={breadcrumbData}
						pageTitle={`Danh sách Chủ đề (${totalCategory.current})`}
					/>
					<div style={{ padding: '24px 0' }} className='d-flex justify-content-between align-items-end gap-3'>
						<div>
							<InputGroup className='input-group-merge search-bar'>
								<InputGroup.Text>
									<FontAwesomeIcon icon={faSearch} />
								</InputGroup.Text>
								<Form.Control
									placeholder='Tìm kiếm (tên chủ đề)'
									value={filterText}
									onChange={handleChange}
									onKeyPress={e => onKeyPressInputSearch(e)}
								/>
							</InputGroup>
						</div>
						<Button
							variant='secondary'
							onClick={() => {
								handleShowModalAddCategory();
							}}
						>
							<FontAwesomeIcon icon={faPlusCircle} className='me-2' />
							Tạo chủ đề
						</Button>
					</div>
				</div>
				<Card border='light' className='shadow-sm mb-4'>
					<Card.Body>
						{isloading ? (
							<div
								className='d-flex justify-content-center align-items-center'
								style={{ height: '580px' }}
							>
								<LoadingIndicator />
							</div>
						) : (
							<>
								<Table responsive className='table-centered table-nowrap rounded mb-0'>
									<thead className='thead-light'>
										<tr>
											<th className='border-0'>
												<CustomDropdownMenu
													title='id'
													dataArray={[
														{ value: 'DESC', title: 'Lớn đến bé (mới nhất)' },
														{ value: 'ASC', title: 'Bé đến lớn (cũ nhất)' },
													]}
													onFilterChange={onChangeSortId}
													filterValue={sortById}
												/>
											</th>
											<th className='border-0'>
												<CustomDropdownMenu
													title='chủ đề'
													dataArray={[
														{ value: 'ASC', title: 'A -> Z' },
														{ value: 'DESC', title: 'Z -> A' },
													]}
													onFilterChange={onChangeSortName}
													filterValue={sortByName}
												/>
											</th>
											<th className='border-0'>số lượng quote</th>
											<th className='border-0'>
												<CustomDropdownMenu
													title='số lượng sách'
													dataArray={[
														{ value: 'all', title: 'Tất cả' },
														{ value: 'ne', title: 'Có sách' },
														{ value: 'eq', title: 'Không có sách' },
													]}
													onFilterChange={onChangeFilterHasBook}
													filterValue={filterHasBook}
												/>
											</th>
											<th className='border-0'>
												<CustomDropdownMenu
													title='chủ đề yêu thích'
													dataArray={[
														{ value: 'all', title: 'Tất cả' },
														{ value: true, title: 'Có' },
														{ value: false, title: 'Không' },
													]}
													onFilterChange={onChangeFilterIsTop}
													filterValue={filterIsTop}
												/>
											</th>
											<th className='border-0'>Hoạt động</th>
										</tr>
									</thead>
									{categoryList.length > 0 && (
										<tbody>
											{categoryList.map((item, index) => {
												return (
													<tr key={index}>
														<td>{item.id}</td>
														<td>{item.name}</td>
														<td>{item.countQuote}</td>
														<td>{item.numberBooks}</td>
														<td>
															{item.isTopCategory ? (
																<span
																	className='p-2 badge bg-success'
																	style={{ fontSize: '0.75rem', minWidth: '50px' }}
																>
																	Có
																</span>
															) : (
																<span
																	className='p-2 badge bg-gray'
																	style={{ fontSize: '0.75rem', minWidth: '50px' }}
																>
																	Không
																</span>
															)}
														</td>
														<td>
															<div className='d-flex gap-1'>
																<Button
																	variant='light'
																	size='sm'
																	title='Chỉnh sửa'
																	onClick={() => handleShowModalItemInfo(item)}
																>
																	<FontAwesomeIcon
																		className='text-success'
																		icon={faEdit}
																	/>
																</Button>
																<Button
																	variant='light'
																	size='sm'
																	title='Xóa'
																	onClick={() => handleShowModal(item)}
																>
																	<FontAwesomeIcon
																		className='text-danger'
																		icon={faTrashAlt}
																	/>
																</Button>
															</div>
														</td>
													</tr>
												);
											})}
										</tbody>
									)}
								</Table>
								<PaginationComponent
									currentPage={currentPage}
									totalPage={totalPage}
									setCurrentPage={setCurrentPage}
								/>
								{categoryList.length === 0 && (
									<p className='position-absolute start-50 translate-middle margin-negative'>
										Không có kết quả nào
									</p>
								)}
							</>
						)}
					</Card.Body>
				</Card>
			</div>

			{/* modal edit category */}
			<Modal
				className='modal-approve-book-info category'
				show={showModalDetail}
				onHide={() => setShowModalDetail()}
				centered
			>
				<Modal.Header>
					<h5 style={{ margin: 'auto' }}>Chỉnh sửa</h5>
				</Modal.Header>
				<Modal.Body>
					<div>
						<Form.Group>
							<Form.Label>
								Tên chủ đề: <span className='text-danger'>*</span>
							</Form.Label>
							<Form.Control
								type='text'
								id='name'
								name='lname'
								value={valueInput}
								placeholder='chủ đề'
								onChange={e => setValueInput(e.target.value)}
							/>
						</Form.Group>

						<div className='my-3 d-flex'>
							<Form.Label>Được yêu thích: {itemCategory.isTopCategory}</Form.Label>
							<Form style={{ display: 'flex' }}>
								<Form.Check
									label={checkedCategory ? 'Có' : 'Không'}
									type='switch'
									style={{ margin: '0px 20px' }}
									checked={checkedCategory}
									readOnly
									onClick={() => setCheckedCategory(prev => !prev)}
								/>
							</Form>
						</div>
						<div className='mb-3'>
							<Form.Group>
								<Form.Label>Chủ đề liên quan:</Form.Label>
								<Select
									placeholder='Tìm kiếm và thêm chủ đề'
									options={suggestionData}
									isMulti={true}
									value={categories}
									onChange={data => onChangeCategories(data)}
									onFocus={() => {}}
									onInputChange={value => handleSearch(value)}
									isLoading={categoryLoading}
									loadingMessage={() => <LoadingIndicator />}
									noOptionsMessage={() => <p className='m-1'>Không có kết quả tìm kiếm nào</p>}
									components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
								/>
							</Form.Group>
						</div>
						<div className='mb-3'>
							<Form.Label>Mô tả:</Form.Label>
							<div className='rich-text-description'>
								<RichTextEditor
									setTextContent={setValueDescription}
									placeholder='Mô tả chủ đề'
									initialContent={itemCategory.description || ''}
								/>
							</div>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant='blue'
						style={{ minWidth: '80px' }}
						onClick={handleEditCategory}
						disabled={!valueInput}
					>
						Lưu
					</Button>
					<Button variant='light' style={{ minWidth: '80px' }} onClick={() => setShowModalDetail(false)}>
						Đóng
					</Button>
				</Modal.Footer>
			</Modal>

			{/* modal add category */}
			<Modal
				className='modal-approve-book-info'
				show={showModalAddCategory}
				onHide={() => setShowModalAddCategory()}
				centered
			>
				<Modal.Header>
					<h5 style={{ margin: 'auto' }}>Tạo Chủ đề</h5>
				</Modal.Header>
				<Modal.Body>
					<Form.Group>
						<Form.Label>
							Tên chủ đề: <span className='text-danger'>*</span>
						</Form.Label>
						<Form.Control
							value={valueInputAddCategory}
							placeholder='Tên chủ đề...'
							onChange={e => setValueInputAddCategory(e.target.value)}
						/>
					</Form.Group>

					<Form.Group className='my-3'>
						<Form.Label>Chủ đề liên quan:</Form.Label>
						<Select
							placeholder='Tìm kiếm và thêm chủ đề'
							options={suggestionData}
							isMulti={true}
							value={categories}
							onChange={data => onChangeCategories(data)}
							onFocus={() => {}}
							onInputChange={value => handleSearch(value)}
							isLoading={categoryLoading}
							loadingMessage={() => <LoadingIndicator />}
							noOptionsMessage={() => <p className='m-1'>Không có kết quả tìm kiếm nào</p>}
							components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
						/>
					</Form.Group>

					<Form.Group className='my-3'>
						<Form.Label>Mô tả:</Form.Label>
						<div className='rich-text-description'>
							<RichTextEditor setTextContent={setValueDescription} placeholder='Mô tả chủ đề' />
						</div>
					</Form.Group>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant='blue'
						style={{ minWidth: '80px' }}
						onClick={handleAddCategory}
						disabled={!valueInputAddCategory}
					>
						Lưu
					</Button>
					<Button variant='light' style={{ minWidth: '80px' }} onClick={() => setShowModalAddCategory(false)}>
						Đóng
					</Button>
				</Modal.Footer>
			</Modal>

			{/* modelDeleteCategory */}
			<MyModal
				show={showModalDelete}
				onHide={() => setShowModalDelete(false)}
				title='Xác nhận xóa chủ đề'
				type={`chủ đề ${currentItem.current.name}`}
				onDelete={handleDelete}
			/>
		</>
	);
}
