import { faInfo, faLongArrowAltRight, faSearch, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Button, Card, Form, InputGroup, Table } from '@themesberg/react-bootstrap';
import defaultAvatar from 'assets/img/avatar-default.jpeg';
import BreadcrumbComponent from 'components/BreadcrumbComponent';
import CustomDropdownMenu from 'components/CustomDropdownMenu';
import LoadingIndicator from 'components/LoadingIndicator';
import MyModal from 'components/Modal';
import PaginationComponent from 'components/PaginationComponent';
import { BASE_URL, breadcrumbsData } from 'constants';
import { NotificationError } from 'helpers/Error';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { deleteGroupFeed, getGroupFeed } from 'reducers/group-feed';
import { generateTime } from 'helpers/Common';

function GroupFeed() {
	const breadcrumbData = [breadcrumbsData.feedManager.groupFeed];
	const creadModalData = { title: 'Xác nhận xóa feed', type: 'feed' };

	const [isLoading, setIsLoading] = useState(false);
	const [totalCount, setTotalCount] = useState(0);
	const [groupFeed, setGroupFeed] = useState([]);
	const [sort, setSort] = useState('DESC');
	const [filterStatus, setFilterStatus] = useState(false);
	const [filterText, setFilterText] = useState([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPage, setTotalPage] = useState(1);
	const [inputSearchValue, setInputSearchValue] = useState('');
	const [showModal, setShowModal] = useState(false);
	const [idFeed, setIdFeed] = useState(null);

	const callApiPerPage = useRef(10);
	const oldInputSearchValue = useRef('');
	const getDataFirstTime = useRef(true);

	const dispatch = useDispatch();

	const history = useHistory();

	useEffect(() => {
		getGroupFeedData();
	}, [currentPage, sort, filterStatus]);

	useEffect(() => {
		if (!oldInputSearchValue.current && !getDataFirstTime.current) {
			setCurrentPage(1);
			getGroupFeedData();
		}
	}, [oldInputSearchValue.current]);

	const getGroupFeedData = async () => {
		const params = {
			start: (currentPage - 1) * callApiPerPage.current,
			limit: callApiPerPage.current,
			sort: JSON.stringify([{ property: 'createdAt', direction: sort }]),
			filter: JSON.stringify([
				filterText && { 'operator': 'search', 'value': filterText, 'property': 'message' },
				filterStatus !== 'all-status' && { 'operator': 'eq', 'value': filterStatus, 'property': 'isDeleted' },
			]),
		};
		setIsLoading(true);
		try {
			const data = await dispatch(getGroupFeed(params)).unwrap();
			setTotalCount(data.count);
			setGroupFeed(data.rows);
			setTotalPage(Math.ceil(data.count / callApiPerPage.current));
		} catch (err) {
			NotificationError(err);
		} finally {
			getDataFirstTime.current = false;
			setIsLoading(false);
		}
	};

	const updateInputSearch = e => {
		setInputSearchValue(e.target.value);
		setFilterText(e.target.value.trim());
		if (!e.target.value.trim().length) {
			oldInputSearchValue.current = '';
		}
	};

	const handleSearch = e => {
		if (e.key === 'Enter' && inputSearchValue.trim().length && inputSearchValue !== oldInputSearchValue.current) {
			oldInputSearchValue.current = inputSearchValue;
			getGroupFeedData();
		}
	};

	const handleDelete = async () => {
		try {
			setShowModal(false);
			await dispatch(deleteGroupFeed(idFeed)).unwrap();
			toast.success('Xóa thành công!');
			getGroupFeedData();
		} catch (err) {
			NotificationError(err);
		}
	};

	const onChangeFilterStatus = value => {
		setCurrentPage(1);
		setFilterStatus(value);
	};

	const renderContent = data => {
		return <span dangerouslySetInnerHTML={{ __html: data }} className='render-content'></span>;
	};

	return (
		<>
			<MyModal
				show={showModal}
				onHide={() => setShowModal(false)}
				title={creadModalData.title}
				type={creadModalData.type}
				onDelete={handleDelete}
			/>
			<div className='d-flex justify-content-between align-items-end'>
				<BreadcrumbComponent subItems={breadcrumbData} pageTitle={`Danh sách Group Feed (${totalCount})`} />
				<div style={{ padding: '24px 0' }}>
					<div>
						<InputGroup className='input-group-merge search-bar'>
							<InputGroup.Text>
								<FontAwesomeIcon icon={faSearch} />
							</InputGroup.Text>
							<Form.Control
								type='text'
								placeholder='Tìm kiếm nội dung'
								value={inputSearchValue}
								onChange={updateInputSearch}
								onKeyDown={handleSearch}
							/>
						</InputGroup>
					</div>
				</div>
			</div>
			<Card border='light' className='shadow-sm mb-4'>
				<Card.Body className='pb-0'>
					{isLoading ? (
						<div className='d-flex justify-content-center align-items-center' style={{ height: '550px' }}>
							<LoadingIndicator />
						</div>
					) : (
						<>
							<Table responsive className='table-centered table-nowrap rounded mb-0 user-select-none'>
								<thead className='thead-light'>
									<tr>
										<th className='border-0'>
											<CustomDropdownMenu
												title='id'
												dataArray={[
													{ value: 'DESC', title: 'Lớn đến bé (mới nhất)' },
													{ value: 'ASC', title: 'Bé đến lớn (cũ nhất)' },
												]}
												onFilterChange={setSort}
												filterValue={sort}
											/>
										</th>
										<th className='border-0'>nguời tạo</th>
										<th className='border-0'>tên nhóm</th>
										<th className='border-0'>
											<CustomDropdownMenu
												title='trạng thái'
												dataArray={[
													{ value: 'all-status', title: 'Tất cả trạng thái' },
													{ value: true, title: 'Đã xóa' },
													{ value: false, title: 'Chưa xóa' },
												]}
												onFilterChange={onChangeFilterStatus}
												filterValue={filterStatus}
											/>
										</th>
										<th className='border-0'>ngày tạo</th>
										<th className='border-0'>hoạt động</th>
									</tr>
								</thead>
								{groupFeed.length > 0 && (
									<tbody>
										{groupFeed.map((item, index) => (
											<tr key={index}>
												<td>{item.id}</td>
												<td>
													<img
														src={item?.user?.avatarImage || defaultAvatar}
														alt={item?.user?.firstName}
														className='user-image'
														onError={e => e.target.setAttribute('src', defaultAvatar)}
													/>
													<span className='mx-2'>
														{item?.user?.firstName + ' ' + item?.user?.lastName}
													</span>
												</td>
												<td>{item?.group?.name}</td>

												<td>
													<Badge
														className='p-2'
														bg={item.isDeleted ? 'warning' : 'success'}
														style={{ fontSize: '0.75rem' }}
													>
														{item.isDeleted ? 'Đã xóa' : 'Chưa xóa'}
													</Badge>
												</td>
												<td>{generateTime(item.time)}</td>
												<td>
													<div className='d-flex gap-1'>
														<Button
															disabled={item.isDeleted}
															variant='light'
															size='sm'
															title='Xem trang của bài viết'
															onClick={() =>
																window.open(
																	`${BASE_URL}detail-feed/group-post/${item.id}`
																)
															}
														>
															<FontAwesomeIcon
																icon={faLongArrowAltRight}
																style={{ transform: 'rotate(-45deg)' }}
															/>
														</Button>
														<Button
															disabled={item.isDeleted}
															style={{ width: '31px' }}
															variant='light'
															size='sm'
															title='Chi tiết'
															onClick={() => history.push(`/groudFeed/${item.id}`)}
														>
															<FontAwesomeIcon className='text-info' icon={faInfo} />
														</Button>
														<Button
															disabled={item.isDeleted}
															variant='light'
															size='sm'
															title='Xóa'
															onClick={() => {
																setShowModal(true);
																setIdFeed(item.id);
															}}
														>
															<FontAwesomeIcon
																className='text-danger icon-delete'
																icon={faTrashAlt}
															/>
														</Button>
													</div>
												</td>
											</tr>
										))}
									</tbody>
								)}
							</Table>
							<PaginationComponent
								currentPage={currentPage}
								totalPage={totalPage}
								setCurrentPage={setCurrentPage}
							/>

							{groupFeed.length === 0 && (
								<p className='position-absolute start-50 translate-middle margin-negative'>
									Không có kết quả nào
								</p>
							)}
						</>
					)}
				</Card.Body>
			</Card>
		</>
	);
}

export default GroupFeed;
