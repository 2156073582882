import BreadcrumbComponent from 'components/BreadcrumbComponent';
import { NotificationError } from 'helpers/Error';
import { useEffect, useRef, useState } from 'react';
import { deleteGroup, getGroupList, getupdateBackground } from 'reducers/group';
// import { getFilterSearch } from 'reducers/search';
import { faInfo, faLongArrowAltRight, faSearch, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Button, Card, Col, Form, InputGroup, Modal, Row, Table } from '@themesberg/react-bootstrap';
import defaultAvatar from 'assets/img/avatar-default.jpeg';
import CustomDropdownMenu from 'components/CustomDropdownMenu';
import LoadingIndicator from 'components/LoadingIndicator';
import PaginationComponent from 'components/PaginationComponent';
import { BASE_URL, breadcrumbsData } from 'constants';
import { generateTime } from 'helpers/Common';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import defaultCoverGroup from '../../assets/img/Rectangle 17435.png';

export default function Group() {
	const breadcrumbData = [breadcrumbsData.groupsManager.groups];
	const dispatch = useDispatch();

	const callApiPerPage = useRef(10);

	const [isloading, setIsLoading] = useState(true);

	const [groups, setGroups] = useState([]);
	const [totalGroups, setTotalGroups] = useState(0);

	const [currentPage, setCurrentPage] = useState(1);
	const [totalPage, setTotalPage] = useState(1);

	const [sort, setSort] = useState('DESC');
	const [filterGroupType, setFilterGroupType] = useState('');
	const [filterActive, setFilterActive] = useState('');

	const [inputSearchValue, setInputSearchValue] = useState('');
	const [previousInputSearchValue, setPreviousInputSearchValue] = useState('');

	const [isChecked, setIsChecked] = useState(false);

	const [showModalInfoGroup, setShowModalInfoGroup] = useState(false);
	const handleCloseModalInfoGroup = () => setShowModalInfoGroup(false);
	const handleShowModalInfoGroup = () => setShowModalInfoGroup(true);
	const [choosenInfoGroup, setChoosenInfoGroup] = useState({});

	const [showModalDeleteGroup, setShowModalDeleteGroup] = useState(false);
	const handleCloseModalDeleteGroup = () => setShowModalDeleteGroup(false);
	const handleShowModalDeleteGroup = () => setShowModalDeleteGroup(true);
	const [choosenDeleteGroup, setChoosenDeleteGroup] = useState({});

	useEffect(() => {
		getGroupListData();
	}, [currentPage, sort, filterGroupType, filterActive]);

	useEffect(() => {
		if (inputSearchValue === '' && inputSearchValue !== previousInputSearchValue) {
			setTimeout(getGroupListData, 700);
		}
	}, [inputSearchValue]);

	const onClickIconDeleteGroup = paramItem => {
		setChoosenDeleteGroup(paramItem);
		handleShowModalDeleteGroup();
	};

	const handleDeleteGroup = async () => {
		try {
			await dispatch(deleteGroup(choosenDeleteGroup.id)).unwrap();
			toast.success('Xóa nhóm thành công', { toastId: 'success-delete-group' });
		} catch (error) {
			toast.error('Xóa nhóm thất bại', { toastId: 'failed-delete-group' });
			if (typeof error.responseBody.message === 'string') {
				toast.warning(error.responseBody.message, {
					toastId: 'error-delete-group',
				});
			} else {
				toast.warning(error.responseBody.message[0], {
					toastId: 'error-delete-group',
				});
			}
		} finally {
			handleCloseModalDeleteGroup();
			getGroupListData();
		}
	};

	const getGroupListData = async () => {
		setIsLoading(true);
		const params = {
			start: (currentPage - 1) * callApiPerPage.current,
			limit: callApiPerPage.current,
			sort: JSON.stringify([{ property: 'createdAt', direction: sort }]),
			filter: JSON.stringify([
				{
					operator: 'search',
					value: inputSearchValue,
					property: 'name',
				},
				filterGroupType !== '' && {
					operator: 'eq',
					value: filterGroupType,
					property: 'groupType',
				},
				filterActive !== '' && {
					operator: 'eq',
					value: filterActive,
					property: 'active',
				},
			]),
		};
		try {
			const res = await dispatch(getGroupList(params)).unwrap();
			setGroups(res.rows);
			setTotalGroups(res.count);
			setTotalPage(Math.ceil(res.count / callApiPerPage.current));
		} catch (error) {
			NotificationError(error);
		} finally {
			setIsLoading(false);
		}
		setPreviousInputSearchValue(inputSearchValue.trim());
	};

	const onClickIconLinkGroup = paramItem => {
		window.open(`${BASE_URL}group/${paramItem.id}`);
	};

	const onClickIconInfoGroup = item => {
		setChoosenInfoGroup(item);
		handleShowModalInfoGroup();
		setIsChecked(item.active);
	};

	const handleUpdateGroup = async () => {
		try {
			const params = {
				id: choosenInfoGroup.id,
				param: {
					active: isChecked,
				},
			};
			await dispatch(getupdateBackground(params)).unwrap();
			handleCloseModalInfoGroup();
			getGroupListData();
		} catch (err) {
			NotificationError(err);
		}
	};

	const onChangeFilterGroupType = value => {
		setCurrentPage(1);
		setFilterGroupType(value);
	};

	const onChangeFilterActive = value => {
		setCurrentPage(1);
		setFilterActive(value);
	};

	const onChangeInputSearchValue = e => {
		setCurrentPage(1);
		setInputSearchValue(e.target.value);
	};

	const onKeyPressSearchValue = e => {
		if (e.key === 'Enter' && inputSearchValue.trim() !== previousInputSearchValue) {
			getGroupListData();
		}
	};

	const getGroupBooks = () => {
		return (
			<span>
				{choosenInfoGroup.groupBooks?.length === 0 && <span>Chưa có</span>}
				{choosenInfoGroup.groupBooks?.length > 0 && (
					<>
						{choosenInfoGroup.groupBooks.map((item, index) => (
							<div key={item.id}>
								{index + 1}: {item.book.name}
							</div>
						))}
					</>
				)}
			</span>
		);
	};

	const getGroupAuthors = () => {
		return (
			<span>
				{choosenInfoGroup.groupAuthors?.length === 0 && <span>Chưa có</span>}
				{choosenInfoGroup.groupAuthors?.length > 0 && (
					<>
						{choosenInfoGroup.groupAuthors.map((item, index) => (
							<div key={item.id}>
								{index + 1}: {item.author.firstName + ' ' + item.author.lastName}
							</div>
						))}
					</>
				)}
			</span>
		);
	};

	const getGroupCategories = () => {
		return (
			<span>
				{choosenInfoGroup.groupCategories?.length === 0 && <span>Chưa có</span>}
				{choosenInfoGroup.groupCategories?.length > 0 && (
					<>
						{choosenInfoGroup.groupCategories.map((item, index) => (
							<div key={item.id}>
								{index + 1}: {item.category.name}
							</div>
						))}
					</>
				)}
			</span>
		);
	};

	return (
		<div className='group'>
			<div className='d-flex justify-content-between align-items-end'>
				<BreadcrumbComponent subItems={breadcrumbData} pageTitle={`Danh sách Nhóm (${totalGroups})`} />
				<div style={{ padding: '24px 0' }}>
					<InputGroup className='input-group-merge search-bar'>
						<InputGroup.Text>
							<FontAwesomeIcon icon={faSearch} />
						</InputGroup.Text>
						<Form.Control
							placeholder='Tìm kiếm (tên nhóm)'
							value={inputSearchValue}
							onChange={e => onChangeInputSearchValue(e)}
							onKeyPress={e => onKeyPressSearchValue(e)}
						/>
					</InputGroup>
				</div>
			</div>
			<Card border='light' className='shadow-sm mb-4'>
				<Card.Body>
					{isloading ? (
						<div className='d-flex justify-content-center align-items-center' style={{ height: '580px' }}>
							<LoadingIndicator />
						</div>
					) : (
						<>
							<Table responsive className='table-centered table-nowrap rounded mb-0'>
								<thead className='thead-light'>
									<tr>
										<th className='border-0'>
											<CustomDropdownMenu
												title='id'
												dataArray={[
													{ value: 'DESC', title: 'Lớn đến bé (mới nhất)' },
													{ value: 'ASC', title: 'Bé đến lớn (cũ nhất)' },
												]}
												onFilterChange={setSort}
												filterValue={sort}
											/>
										</th>
										<th className='border-0'>ảnh bìa</th>
										<th className='border-0'>tên nhóm</th>
										<th className='border-0'>người tạo</th>
										<th className='border-0'>
											<CustomDropdownMenu
												title='kiểu nội dung'
												dataArray={[
													{ value: '', title: 'Tất cả' },
													{ value: 'category', title: 'Chia sẻ' },
													{ value: 'author', title: 'Tác giả' },
													{ value: 'book', title: 'Sách' },
												]}
												onFilterChange={onChangeFilterGroupType}
												filterValue={filterGroupType}
											/>
										</th>
										<th className='border-0'>
											<CustomDropdownMenu
												title='trạng thái'
												dataArray={[
													{ value: '', title: 'Tất cả' },
													{ value: true, title: 'Không bị khóa' },
													{ value: false, title: 'Bị khóa' },
												]}
												onFilterChange={onChangeFilterActive}
												filterValue={filterActive}
											/>
										</th>
										<th className='border-0'>hoạt động</th>
									</tr>
								</thead>
								{groups.length > 0 && (
									<tbody>
										{groups.map(item => (
											<tr key={item.id}>
												<td>{item.id}</td>
												<td>
													<img
														src={item.avatar || defaultCoverGroup}
														style={{
															height: '40px',
															width: '60px',
															objectFit: 'cover',
															borderRadius: '10%',
														}}
														onError={({ currentTarget }) => {
															currentTarget.onerror = null; // prevents looping
															currentTarget.src = defaultCoverGroup;
														}}
														alt='img'
													></img>
												</td>
												<td>{item.name}</td>
												<td>{item.createdBy.firstName + ' ' + item.createdBy.lastName}</td>
												<td>
													{item.groupType === 'book'
														? 'Sách'
														: item.groupType === 'author'
														? 'Tác giả'
														: 'Chia sẻ'}
												</td>
												<td>
													<Badge
														className='p-2'
														bg={item.isDeleted ? 'warning' : 'success'}
														style={{ fontSize: '0.75rem' }}
													>
														{item.isDeleted ? 'Khóa' : 'Không khóa'}
													</Badge>
												</td>
												<td>
													<div className='d-flex gap-1'>
														<Button
															variant='light'
															size='sm'
															title='Xem trang group'
															onClick={() => onClickIconLinkGroup(item)}
														>
															<FontAwesomeIcon
																icon={faLongArrowAltRight}
																style={{ transform: 'rotate(-45deg)' }}
															/>
														</Button>
														<Button
															style={{ width: '31px' }}
															variant='light'
															size='sm'
															title='Chi tiết'
															onClick={() => onClickIconInfoGroup(item)}
														>
															<FontAwesomeIcon className='text-info' icon={faInfo} />
														</Button>
														<Button
															variant='light'
															size='sm'
															title='Xóa'
															onClick={() => onClickIconDeleteGroup(item)}
														>
															<FontAwesomeIcon
																className='text-danger'
																icon={faTrashAlt}
															/>
														</Button>
													</div>
												</td>
											</tr>
										))}
									</tbody>
								)}
							</Table>
							<PaginationComponent
								currentPage={currentPage}
								totalPage={totalPage}
								setCurrentPage={setCurrentPage}
							/>
							{groups.length === 0 && (
								<p className='position-absolute start-50 translate-middle margin-negative'>
									Không có kết quả nào
								</p>
							)}
						</>
					)}
				</Card.Body>
			</Card>

			{/* Modal chi tiết nhóm */}
			<Modal
				show={showModalInfoGroup}
				onHide={handleCloseModalInfoGroup}
				centered
				className='group__modal-info-group'
				size='lg'
			>
				<Modal.Header>
					<h5 style={{ margin: 'auto' }}>Thông tin chi tiết nhóm</h5>
				</Modal.Header>
				<Modal.Body>
					<Row>
						<Col xs={4}>
							<img
								src={choosenInfoGroup.avatar || defaultCoverGroup}
								onError={({ currentTarget }) => {
									currentTarget.onerror = null; // prevents looping
									currentTarget.src = defaultCoverGroup;
								}}
								style={{
									height: '140px',
									width: '100%',
									objectFit: 'cover',
									borderRadius: '10px',
									marginBottom: '1rem',
								}}
							/>
							<div className='d-flex flex-column align-items-center'>
								<h6>
									Người tạo:{' '}
									{choosenInfoGroup.createdBy?.firstName + ' ' + choosenInfoGroup.createdBy?.lastName}
								</h6>
								<div className='d-flex flex-row align-items-center gap-3'>
									<img
										src={choosenInfoGroup.createdBy?.avatarImage || defaultAvatar}
										onError={({ currentTarget }) => {
											currentTarget.onerror = null; // prevents looping
											currentTarget.src = defaultAvatar;
										}}
										style={{
											height: '40px',
											width: '40px',
											objectFit: 'cover',
											borderRadius: '10%',
										}}
									/>
									<span
										className='group__modal__link'
										onClick={() => window.open(`/edit-user/${choosenInfoGroup.createdBy?.id}`)}
									>
										Xem chi tiết
									</span>
								</div>

								<div className='group__toggle-edit'>
									<Form.Check
										className='mt-2'
										type='switch'
										label='Khóa / mở khóa nhóm'
										checked={isChecked}
										onChange={() => setIsChecked(!isChecked)}
									/>
								</div>
							</div>
						</Col>

						<Col xs={8} className='overflow-auto group__modal-info-group__column-right'>
							<Row>
								<Col xs={4} className='text-right'>
									Tên nhóm
								</Col>
								<Col xs={8}>
									<b>{choosenInfoGroup.name}</b>
								</Col>
							</Row>
							<Row>
								<Col xs={4} className='text-right'>
									Kiểu nội dung
								</Col>
								<Col xs={8}>
									{choosenInfoGroup.groupType === 'book'
										? 'Sách'
										: choosenInfoGroup.groupType === 'author'
										? 'Tác giả'
										: 'Chia sẻ'}
								</Col>
							</Row>
							<Row>
								<Col xs={4} className='text-right'>
									Số lượng thành viên
								</Col>
								<Col xs={8}>{choosenInfoGroup.countMember}</Col>
							</Row>
							<Row>
								<Col xs={4} className='text-right'>
									Tổng số bài viết
								</Col>
								<Col xs={8}>{choosenInfoGroup.countPost}</Col>
							</Row>
							<Row>
								<Col xs={4} className='text-right'>
									Số bài viết mỗi ngày
								</Col>
								<Col xs={8}>{choosenInfoGroup.countPostPerDay}</Col>
							</Row>
							<Row>
								<Col xs={4} className='text-right'>
									Tên sách ({choosenInfoGroup.groupBooks?.length})
								</Col>
								<Col xs={8}>{getGroupBooks()}</Col>
							</Row>
							<Row>
								<Col xs={4} className='text-right'>
									Tên tác giả ({choosenInfoGroup.groupAuthors?.length})
								</Col>
								<Col xs={8}>{getGroupAuthors()}</Col>
							</Row>
							<Row>
								<Col xs={4} className='text-right'>
									Tên chủ đề ({choosenInfoGroup.groupCategories?.length})
								</Col>
								<Col xs={8}>{getGroupCategories()}</Col>
							</Row>
							<Row>
								<Col xs={4} className='text-right'>
									Ngày tạo
								</Col>
								<Col xs={8}>{generateTime(choosenInfoGroup.createdAt)}</Col>
							</Row>
						</Col>
					</Row>
				</Modal.Body>
				<Modal.Footer>
					<Button variant='info' onClick={handleUpdateGroup}>
						Lưu
					</Button>
					<Button variant='light' onClick={handleCloseModalInfoGroup}>
						Đóng lại
					</Button>
				</Modal.Footer>
			</Modal>

			{/* Modal xóa nhóm */}
			<Modal
				show={showModalDeleteGroup}
				onHide={handleCloseModalDeleteGroup}
				centered
				className='group__modal-delete-group'
			>
				<Modal.Header>
					<Modal.Title className='h6'>Xóa nhóm</Modal.Title>
					<Button variant='close' aria-label='Close' onClick={handleCloseModalDeleteGroup} />
				</Modal.Header>
				<Modal.Body>
					<h5 className='text-center'>Bạn có chắc chắn muốn xóa nhóm này?</h5>
					<div className='d-flex gap-3 justify-content-center'>
						<img
							className='rounded'
							src={choosenDeleteGroup.avatar || defaultAvatar}
							alt='img'
							style={{ width: '166px', height: '100px' }}
						></img>

						<div className='d-flex flex-column justify-content-center'>
							<span className='h6'>Tên nhóm: {choosenDeleteGroup.name}</span>
							<span className='h6'>
								Người tạo:{' '}
								{choosenDeleteGroup.createdBy?.firstName + ' ' + choosenDeleteGroup.createdBy?.lastName}
							</span>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button variant='danger' onClick={handleDeleteGroup}>
						Xóa
					</Button>
					<Button variant='light' onClick={handleCloseModalDeleteGroup}>
						Đóng
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
}

Group.propTypes = {
	item: PropTypes.object,
	index: PropTypes.number,
};
