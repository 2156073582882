import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card } from '@themesberg/react-bootstrap';
import bookImageDefault from 'assets/img/default-book.png';
import classNames from 'classnames';
import BreadcrumbComponent from 'components/BreadcrumbComponent';
import LoadingIndicator from 'components/LoadingIndicator';
import MyModal from 'components/Modal';
import { calculateDurationTime } from 'helpers/Common';
import { NotificationError } from 'helpers/Error';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import Lightbox from 'react-image-lightbox';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { deleteGroupFeed, detailGroupFeed } from 'reducers/group-feed';
import defaultCover from 'assets/img/Rectangle 17435.png';
import { breadcrumbsData } from 'constants';

function DetailFeed() {
	const breadcrumbData = [breadcrumbsData.feedManager.groupFeed, breadcrumbsData.feedManager.detailNewFeed];

	const creadModalData = { title: 'Xác nhận xóa feed', type: 'feed' };

	const [groupFeedDetail, setGroupFeedDetail] = useState(null);
	const [percent, setPercent] = useState(null);
	const [showModal, setShowModal] = useState(false);
	const [readMore, setReadMore] = useState(false);
	const [isloading, setIsLoading] = useState(false);

	const [photoIndex, setPhotoIndex] = useState(0);
	const [isOpen, setIsOpen] = useState(false);

	const { id } = useParams();

	const history = useHistory();

	const dispatch = useDispatch();

	useEffect(() => {
		detailGroupFeedData();
	}, []);

	const detailGroupFeedData = async () => {
		setIsLoading(true);
		try {
			const data = await dispatch(detailGroupFeed(id)).unwrap();
			setGroupFeedDetail(data);
		} catch (err) {
			NotificationError(err);
		} finally {
			setIsLoading(false);
		}
	};
	useEffect(() => {
		if (!_.isEmpty(groupFeedDetail?.book)) {
			const percentData = Math.round((groupFeedDetail?.metaData?.progress / groupFeedDetail?.book?.page) * 100);
			setPercent(percentData);
		}
	}, [groupFeedDetail, percent]);

	const withFriends = paramInfo => {
		if (paramInfo.length === 1) {
			return (
				<span>
					<span style={{ fontWeight: '300' }}> cùng với </span>
					{paramInfo[0].users.fullName || paramInfo[0].users.firstName + ' ' + paramInfo[0].users.lastName}
					<span style={{ fontWeight: '500' }}>.</span>
				</span>
			);
		} else if (paramInfo.length === 2) {
			return (
				<span>
					<span style={{ fontWeight: '300' }}> cùng với </span>
					{paramInfo[0].users.fullName || paramInfo[0].users.firstName + ' ' + paramInfo[0].users.lastName}
					<span style={{ fontWeight: '300' }}> và </span>
					{paramInfo[1].users.fullName || paramInfo[1].users.firstName + ' ' + paramInfo[1].users.lastName}
					<span style={{ fontWeight: '500' }}>.</span>
				</span>
			);
		} else {
			return (
				<span>
					<span style={{ fontWeight: '300' }}> cùng với </span>
					{paramInfo[0].users.fullName || paramInfo[0].users.firstName + ' ' + paramInfo[0].users.lastName}
					<span style={{ fontWeight: '300' }}> và </span>
					{paramInfo.length - 1}
					{' người khác.'}
				</span>
			);
		}
	};

	const handleDelete = async () => {
		try {
			setShowModal(false);
			await dispatch(deleteGroupFeed(id)).unwrap();
			toast.success('Xóa thành công!');
			history.goBack();
		} catch (err) {
			NotificationError(err);
		}
	};

	const renderContent = data => {
		return <div className={readMore ? '' : 'content-feed'} dangerouslySetInnerHTML={{ __html: data }}></div>;
	};

	const genarateAuthor = authors => {
		if (authors && authors.length) {
			const authorName = authors.map(author => author.authorName);
			return authorName;
		} else {
			return 'chưa xác định';
		}
	};

	const renderImgage = item => {
		return (
			<>
				{item?.length > 0 ? (
					<>
						{item.map((images, index) => {
							if (index < 3) {
								return (
									<div
										style={{ cursor: 'pointer' }}
										key={index}
										className='main-confirm-my-book__confirm__image-item'
										onClick={() => {
											setIsOpen(true);
											setPhotoIndex(index);
										}}
									>
										<img src={images} />
									</div>
								);
							}
						})}
						{item?.length >= 3 && (
							<div className='main-confirm-my-book__confirm__image-item'>
								<div className='main-confirm-my-book__confirm__image-item'>
									<img src={item} />
								</div>

								<div
									style={{ cursor: 'pointer' }}
									onClick={() => {
										setIsOpen(true);
										setPhotoIndex(3);
									}}
									className={classNames('main-confirm-my-book__confirm__image-over', {
										'show': item?.length > 3,
									})}
								>
									+{item?.length - 3}
								</div>
							</div>
						)}
						{isOpen && (
							<Lightbox
								mainSrc={item[photoIndex]}
								nextSrc={item[(photoIndex + 1) % item.length]}
								prevSrc={item[(photoIndex + item.length - 1) % item.length]}
								onCloseRequest={() => setIsOpen(false)}
								onMovePrevRequest={() => setPhotoIndex((photoIndex + item.length - 1) % item.length)}
								onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % item.length)}
							/>
						)}
					</>
				) : (
					'Không có'
				)}
			</>
		);
	};

	return (
		<>
			<MyModal
				show={showModal}
				title={creadModalData.title}
				type={creadModalData.type}
				onHide={() => setShowModal(false)}
				onDelete={handleDelete}
			/>
			<div className='title w-100 d-flex align-items-center justify-content-between'>
				<BreadcrumbComponent subItems={breadcrumbData} />
				<Button
					className='shadow-none btn-danger'
					onClick={() => {
						setShowModal(true);
					}}
				>
					Xóa Feed
					<FontAwesomeIcon icon={faTrashAlt} className='mx-1' />
				</Button>
			</div>

			{isloading ? (
				<div className='d-flex justify-content-center align-items-center' style={{ height: '580px' }}>
					<LoadingIndicator />
				</div>
			) : (
				<>
					<Card border='light' className='shadow-sm mb-4 p-3'>
						<div className='items-feed mb-6'>
							<div className='title'>
								<h5 className='mt-5'>Nhóm :</h5>
							</div>
							<div className='group-info'>
								<div className='group-info--img'>
									<img src={groupFeedDetail?.groupInfo?.avatar || defaultCover} />
								</div>
								<div className='group-info--name'>
									<h6>{groupFeedDetail?.groupInfo?.name}</h6>
								</div>
							</div>
						</div>
						<h5>Bài viết</h5>
						<div>
							<div className='title'>Nội dung bài viết :</div>
							<div className='box-feed-content'>
								{renderContent(groupFeedDetail?.message)}
								{groupFeedDetail?.message?.length > 500 ? (
									<h6
										style={{ cursor: 'pointer', color: 'blue' }}
										className='mt-4'
										onClick={() => setReadMore(!readMore)}
									>
										{readMore ? 'Rút gọn : ...' : 'Xem thêm ...'}
									</h6>
								) : (
									<></>
								)}
							</div>
						</div>
						<div className='items-feed'>
							<div className='title'>Người tạo : </div>
							<div className='box-content'>
								<div>
									{groupFeedDetail?.createdBy?.fullName
										? groupFeedDetail?.createdBy?.fullName
										: groupFeedDetail?.createdBy?.firstName +
										  '' +
										  groupFeedDetail?.createdBy?.lastName}
								</div>
							</div>
						</div>
						<div className='items-feed mb-3'>
							<div className='title'>Ngày tạo : </div>
							<div className='box-content'>
								<div>{calculateDurationTime(groupFeedDetail?.createdAt)}</div>
							</div>
						</div>

						<div className='d-flex gap-5'>
							<div className='d-flex gap-2  align-items-center'>
								<div className='titl'>Like :</div>
								<div>{groupFeedDetail?.usersLikePost?.length}</div>
							</div>
							<div className='d-flex gap-2  align-items-center'>
								<div className='titl'>Comment : </div>
								<div>{groupFeedDetail?.usersComments?.length}</div>
							</div>
							<div className='d-flex gap-2  align-items-center'>
								<div className='m'>Share : </div>
								<div>{groupFeedDetail?.share}</div>
							</div>
						</div>
						<h5 className='mt-5 mb-0'>Các tùy chọn thêm vào bài viết : </h5>
						<div className='items-feed'>
							<div className='title'>Sách : </div>
							<div className='box-content'>
								<div className='d-flex align-items-center'>
									{!_.isEmpty(groupFeedDetail?.book) ? (
										<>
											<img
												src={groupFeedDetail.book || bookImageDefault}
												style={{
													width: '40px',
													height: '50px',
													marginRight: '10px',
												}}
											/>
											<div>
												{groupFeedDetail?.book?.name.length > 0 && groupFeedDetail?.book?.name}
											</div>
										</>
									) : (
										<div>không có </div>
									)}
								</div>
							</div>
						</div>

						<div className='items-feed'>
							<div className='title'>Tác giả : </div>
							<div className='d-flex flex-wrap gap-2'>
								{!_.isEmpty(groupFeedDetail?.mentionsAuthors) ? (
									<>
										{groupFeedDetail?.mentionsAuthors.map((item, index) => (
											<span key={index} className='item-mentions'>
												{item?.authors?.fullName
													? item?.authors?.fullName
													: item?.authors?.firstName + ' ' + item?.authors?.lastName}
											</span>
										))}
									</>
								) : (
									<span className='item-mentions'>Không xác định</span>
								)}
							</div>
						</div>

						<div className='items-feed'>
							<div className='title'>Chủ đề : </div>
							<div className='d-flex flex-wrap gap-2'>
								{groupFeedDetail?.mentionsCategories.length > 0 ? (
									groupFeedDetail?.mentionsCategories.map((item, index) => (
										<span key={index} className='item-mentions'>
											{item.category.name}
										</span>
									))
								) : (
									<span className='item-mentions'>Không có</span>
								)}
							</div>
						</div>

						<div className='items-feed'>
							<div className='title'>Gắn thẻ : </div>
							<div className='d-flex flex-wrap gap-2'>
								{groupFeedDetail?.mentionsUsers.length > 0 ? (
									groupFeedDetail?.mentionsUsers?.map((item, index) => (
										<span key={index} className='item-mentions'>
											{item?.users?.firstName + '' + item?.users?.lastName}
										</span>
									))
								) : (
									<span className='item-mentions'>Không có</span>
								)}
							</div>
						</div>
						<div className='items-feed'>
							<div className='title'>#HashTag : </div>
							<div className='box-content'>
								<div>Chưa có hashtag</div>
							</div>
						</div>

						<div className='items-feed'>
							<div className='title'>Ảnh : </div>
							<div className='box-content'>
								<div className='d-flex gap-2 flex-wrap'>{renderImgage(groupFeedDetail?.image)}</div>
							</div>
						</div>
					</Card>
				</>
			)}
		</>
	);
}

export default DetailFeed;
