import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { deleteQuote, getQuoteDetail } from 'reducers/quotes-reducer';

import BreadcrumbComponent from 'components/BreadcrumbComponent';
import { NotificationError } from 'helpers/Error';
import { Button, Card, Form } from '@themesberg/react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import MyModal from 'components/Modal';
import { toast } from 'react-toastify';
import { generateTime } from 'helpers/Common';

function QuotesDetails() {
	const breadcrumbData = [{ title: 'Quản lí quotes', path: '/quotes' }, { title: 'Chi tiết quotes' }];
	const creadModalData = { title: 'Xác nhận xóa quotes', type: 'quotes' };

	const [quotesDetail, setQuotesDetail] = useState(null);
	const [showModal, setShowModal] = useState(false);

	const { id } = useParams();
	const history = useHistory();

	const dispatch = useDispatch();

	useEffect(() => {
		getQuotesDetailData();
	}, []);

	const getQuotesDetailData = async () => {
		try {
			const data = await dispatch(getQuoteDetail(id)).unwrap();
			setQuotesDetail(data);
		} catch (err) {
			NotificationError(err);
		}
	};

	const getCategories = categories => {
		if (categories && categories.length) {
			return categories.map(item => item.category.name);
		} else {
			return 'Chưa xác định';
		}
	};

	const getHashtag = tags => {
		if (tags && tags.length) {
			return tags.map(item => item.tag.name);
		} else {
			return 'Bài quote không gắn hashtag';
		}
	};

	const handleDelete = async () => {
		try {
			await dispatch(deleteQuote(id)).unwrap();
			setShowModal(false);
			toast.success('Xóa thành công!');
			history.push('/quotes');
		} catch (err) {
			NotificationError(err);
		}
	};

	return (
		<>
			<MyModal
				show={showModal}
				onHide={() => setShowModal(false)}
				onDelete={handleDelete}
				title={creadModalData.title}
				type={creadModalData.type}
			/>
			<div className='title w-100 d-flex align-items-center justify-content-between'>
				<BreadcrumbComponent subItems={breadcrumbData} />
				<Button className='shadow-none btn-danger' onClick={() => setShowModal(true)}>
					<FontAwesomeIcon icon={faTrashAlt} className='me-2' />
					Xóa Quotes
				</Button>
			</div>
			<Card border='light' className='shadow-sm mb-4 p-3'>
				<div className='quotes-content d-flex align-items-center'>
					<Form.Label className='mx-2 col-1 mb-0'>Quote</Form.Label>
					<Form.Control
						className='shadow-none'
						as='textarea'
						rows={3}
						value={quotesDetail?.quote}
						readOnly
						style={{ resize: 'none' }}
					/>
				</div>
				<div className='quotes-content d-flex align-items-center mt-4'>
					<Form.Label className='mx-2 col-1 mb-0'>Sách</Form.Label>
					<Form.Control
						className='shadow-none'
						rows={1}
						as='textarea'
						value={quotesDetail?.book.name}
						readOnly
						style={{ resize: 'none' }}
					/>
				</div>
				<div className='quotes-content d-flex align-items-center mt-4'>
					<Form.Label className='mx-2 col-1 mb-0'>Chủ đề</Form.Label>
					<Form.Control
						className='shadow-none'
						rows={1}
						as='textarea'
						value={getCategories(quotesDetail?.categories)}
						readOnly
						style={{ resize: 'none' }}
					/>
				</div>
				<div className='quotes-content d-flex align-items-center mt-4'>
					<Form.Label className='mx-2 col-1 mb-0'>Người tạo</Form.Label>
					<Form.Control
						className='shadow-none'
						as='textarea'
						rows={1}
						value={
							quotesDetail?.user.fullName
								? quotesDetail?.user.fullName
								: quotesDetail?.user.firstName + '' + quotesDetail?.user.lastName
						}
						readOnly
						style={{ resize: 'none' }}
					/>
				</div>
				<div className='quotes-content d-flex mt-4'>
					<div className='quotes-item d-flex col-4 align-items-center'>
						<Form.Label className='mx-2 col-3 mb-0'>Like</Form.Label>
						<Form.Control
							className='shadow-none'
							as='textarea'
							rows={1}
							value={quotesDetail?.like}
							readOnly
							style={{ resize: 'none' }}
						/>
					</div>
					<div className='quotes-item d-flex col-4 align-items-center'>
						<Form.Label className='col-3 mb-0 text-center'>Comment</Form.Label>
						<Form.Control
							className='shadow-none'
							as='textarea'
							rows={1}
							value={quotesDetail?.comments}
							readOnly
							style={{ resize: 'none' }}
						/>
					</div>
					<div className='quotes-item d-flex col-4 align-items-center'>
						<Form.Label className='col-3 mb-0  text-center'>Share</Form.Label>
						<Form.Control
							className='shadow-none'
							as='textarea'
							rows={1}
							value={quotesDetail?.share}
							readOnly
							style={{ resize: 'none' }}
						/>
					</div>
				</div>
				<div className='quotes-content d-flex align-items-center mt-4'>
					<Form.Label className='mx-2 col-1 mb-0'>Hashtag</Form.Label>
					<Form.Control
						className='shadow-none'
						as='textarea'
						rows={2}
						value={getHashtag(quotesDetail?.tags)}
						readOnly
						style={{ resize: 'none' }}
					/>
				</div>
				<div className='quotes-content d-flex align-items-center mt-4'>
					<Form.Label className='mx-2 col-1 mb-0'>Ngày tạo</Form.Label>
					<Form.Control
						className='shadow-none'
						rows={1}
						as='textarea'
						value={generateTime(quotesDetail?.createdAt)}
						readOnly
						style={{ resize: 'none' }}
					/>
				</div>
			</Card>
		</>
	);
}

export default QuotesDetails;
