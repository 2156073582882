import { configureStore } from '@reduxjs/toolkit';
import auth from './auth';
import books from './books';
import search from './search';
import category from './category';
import user from './user';
import common from './common';
import activity from './activity';
import groupFeed from './group-feed';
import group from './group';
import library from './library';

const store = configureStore({
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware({
			serializableCheck: false,
		}),
	reducer: { auth, books, search, category, user, common, activity, groupFeed, group, library },
});

export default store;
