import { deleteMiniPostAPI } from 'constants/apiURL';
import Request from 'helpers/Request';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const deleteMiniPost = createAsyncThunk('post/deleteMiniPost', async (id, { rejectWithValue }) => {
	try {
		const response = await Request.makeDelete(deleteMiniPostAPI(id));
		return response.data;
	} catch (err) {
		const error = JSON.parse(err.response);
		return rejectWithValue(error);
	}
});
