import { useParams } from 'react-router-dom';
import BreadcrumbComponent from 'components/BreadcrumbComponent';
import { Card, Form } from '@themesberg/react-bootstrap';
import { Button, Col, Row, InputGroup, Modal } from '@themesberg/react-bootstrap';
import { getUserDetail, editUserInfo } from 'reducers/user';
import { useEffect, useState, useRef, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import LoadingIndicator from 'components/LoadingIndicator';
import _ from 'lodash';
import 'react-datetime/css/react-datetime.css';
import Datetime from 'react-datetime';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faLock, faLockOpen } from '@fortawesome/free-solid-svg-icons';
import { NotificationError } from 'helpers/Error';
import { uploadImage } from 'reducers/common';
import moment from 'moment-timezone';
import Select from 'react-select';
import { getCategoryList } from 'reducers/category';
import defaultAvatar from 'assets/img/avatar-default.jpeg';
import AvatarCoverSocial from './Avatar-Cover-Social';
import { breadcrumbsData } from 'constants';
import { forgotPasswordAdmin, updateUserInfo } from 'reducers/auth';
import RichTextEditor from 'components/RichTextEditor';

export default function EditUser() {
	const breadcrumbData = [breadcrumbsData.usersManager.users, breadcrumbsData.usersManager.editUser];

	const { userId } = useParams();
	const dispatch = useDispatch();
	const initalCategoriesSuggestionList = useRef([]);

	const [isDisabled, setIsDisabled] = useState(true);

	const [isloadingTable, setIsLoadingTable] = useState(true);
	const [isLoadingSelect, setIsLoadingSelect] = useState(false);

	const [userDetail, setUserDetail] = useState({});

	const [avatarSrc, setAvatarSrc] = useState('');
	const [avatarUpload, setAvatarUpload] = useState('');

	const [coverSrc, setCoverSrc] = useState('');
	const [coverUpload, setCoverUpload] = useState('');

	const [suggestionData, setSuggestionData] = useState([]);
	const [selectedCategories, setSelectedCategories] = useState([]);
	const [birthday, setBirthday] = useState(null);
	const [socials, setSocials] = useState([]);
	const [favoriteCategory, setFavoriteCategory] = useState([]);

	const initialInputValue = {
		firstName: '',
		lastName: '',
		email: '',
		role: '',
		highSchool: '',
		university: '',
		gender: 'male',
		descriptions: '',
		interest: '',
		works: '',
		address: '',
		active: true,
	};
	const [
		{ firstName, lastName, email, role, highSchool, university, gender, interest, works, address, active },
		setInputValue,
	] = useState(initialInputValue);

	const [initialSocials, setInitialSocials] = useState([]);
	const [descriptions, setDescriptions] = useState('');

	const [showModalBanUser, setShowModalBanUser] = useState(false);
	const handleCloseModalBanUser = () => setShowModalBanUser(false);
	const handleShowModalBanUser = () => setShowModalBanUser(true);

	useEffect(() => {
		handleGetUserDetail(userId);
		getInitialCategories();
	}, [userId]);

	const uploadImageFile = async acceptedFiles => {
		const imageUploadedData = await dispatch(uploadImage(acceptedFiles)).unwrap();
		return imageUploadedData?.streamPath.small;
	};

	// on change when typing input
	const onChange = e => {
		const { name, value } = e.target;
		setInputValue(prevState => ({ ...prevState, [name]: value }));
	};

	const handleGetUserDetail = async paramUserId => {
		setIsLoadingTable(true);
		try {
			const res = await dispatch(getUserDetail(paramUserId)).unwrap();
			setUserDetail(res);
			handleFillResDataIntoForm(res);
		} catch (error) {
			toast.error('Lấy thông tin người dùng thất bại', {
				toastId: 'failed-get-userDetail',
			});
			if (typeof error.responseBody.message === 'string') {
				toast.warning(error.responseBody.message, {
					toastId: 'error-get-userDetail',
				});
			} else {
				toast.warning(error.responseBody.message[0], {
					toastId: 'error-get-userDetail',
				});
			}
		} finally {
			setIsLoadingTable(false);
		}
	};

	const handleFillResDataIntoForm = paramRes => {
		setInputValue({
			firstName: paramRes.firstName,
			lastName: paramRes.lastName,
			email: paramRes.email,
			role: paramRes.role === 'tecinus' ? 'admin' : paramRes.role,
			highSchool: paramRes.highSchool,
			university: paramRes.university,
			gender: paramRes.gender,
			interest: paramRes.interest,
			works: paramRes.works,
			address: paramRes.address,
			active: paramRes.active,
		});
		setAvatarSrc(paramRes.avatarImage);
		setCoverSrc(paramRes.backgroundImage);
		setSocials(paramRes.socials || []);
		setInitialSocials(paramRes.socials);
		setBirthday(paramRes.birthday);
		setDescriptions(paramRes.descriptions);

		if (paramRes.favoriteCategory.length > 0) {
			let dataTemp = [];
			dataTemp = paramRes.favoriteCategory.map(item => {
				return {
					value: item.category.id,
					label: item.category.name,
				};
			});
			setSelectedCategories(dataTemp);
		}
	};

	const handleOnDropAvatar = paramAcceptedFiles => {
		setAvatarUpload(paramAcceptedFiles);
		setAvatarSrc(URL.createObjectURL(paramAcceptedFiles[0]));
	};

	const handleOnDropCover = paramAcceptedFiles => {
		setCoverUpload(paramAcceptedFiles);
		setCoverSrc(URL.createObjectURL(paramAcceptedFiles[0]));
	};

	const handleEditUser = async e => {
		e.preventDefault();
		// Gọi api đăng ảnh đại diện và ảnh bìa
		let avatarSrc = '';
		let coverSrc = '';
		if (avatarUpload.length > 0) {
			avatarSrc = await uploadImageFile(avatarUpload);
		}
		if (coverUpload.length > 0) {
			coverSrc = await uploadImageFile(coverUpload);
		}

		const body = {
			firstName: firstName,
			lastName: lastName,
			role: role,
			highSchool: highSchool,
			university: university,
			gender: gender,
			descriptions: descriptions,
			socials: socials,
			interest: interest,
			works: works,
			birthday: birthday,
			address: address,
			avatarImage: avatarSrc,
			backgroundImage: coverSrc,
			favoriteCategory: favoriteCategory,
		};
		try {
			const changeUserImage = await dispatch(editUserInfo({ userId: userId, params: body })).unwrap();
			dispatch(updateUserInfo(changeUserImage));
			toast.success('Chỉnh sửa thành công', { toastId: 'success-edit-user' });
		} catch (err) {
			NotificationError(err);
		}
	};

	const getInitialCategories = async () => {
		try {
			const query = {
				option: false,
				params: {},
			};
			const res = await dispatch(getCategoryList(query)).unwrap();
			if (res.rows.length) {
				const dataTemp = res.rows.map(item => {
					return {
						value: item.id,
						label: item.name,
					};
				});
				initalCategoriesSuggestionList.current = dataTemp;
			}
		} catch (err) {
			NotificationError(err);
		}
	};

	const fetchSuggestion = async inputValue => {
		try {
			if (inputValue) {
				let dataTemp = [];
				const query = {
					option: false,
					params: {
						filter: JSON.stringify([{ 'operator': 'search', 'value': inputValue, 'property': 'name' }]),
					},
				};
				const res = await dispatch(getCategoryList(query)).unwrap();
				dataTemp = res.rows.map(item => {
					return {
						value: item.id,
						label: item.name,
					};
				});
				setSuggestionData(dataTemp);
			}
		} catch (err) {
			NotificationError(err);
		} finally {
			setIsLoadingSelect(false);
		}
	};

	const debounceSearch = useCallback(_.debounce(fetchSuggestion, 700), []);

	const handleSearchCategories = inputValue => {
		if (inputValue) {
			setIsLoadingSelect(true);
			debounceSearch(inputValue);
		} else {
			setIsLoadingSelect(false);
			setSuggestionData(initalCategoriesSuggestionList.current);
		}
	};

	const onChangeCategSelect = paramData => {
		setSelectedCategories(paramData);
		const newArray = [];
		paramData.forEach(item => {
			newArray.push(item.value);
		});
		setFavoriteCategory(newArray);
	};

	const onClickOpenBanModal = () => {
		handleShowModalBanUser();
	};

	const handleToggleBanUser = async () => {
		debounceToggleBanUser();
	};

	const fetchToggleBanUser = async () => {
		const body = {
			active: userDetail.active === true ? false : true,
		};
		try {
			await dispatch(editUserInfo({ userId: userId, params: body })).unwrap();
			toast.success('Chỉnh sửa thành công', { toastId: 'success-edit-user' });
			handleCloseModalBanUser();
			handleGetUserDetail(userId);
		} catch (err) {
			NotificationError(err);
		}
	};

	const debounceToggleBanUser = useCallback(_.debounce(fetchToggleBanUser, 700), [userDetail]);

	const handleForgotPassword = () => {
		debounceForgotPassword();
	};

	const fetchForgotPassword = async () => {
		if (userDetail) {
			try {
				await dispatch(forgotPasswordAdmin(userDetail)).unwrap();
				toast.success('Đã gửi yêu cầu thành công', { toastId: 'success-forgot-password' });
			} catch (err) {
				NotificationError(err);
			}
		}
	};

	const debounceForgotPassword = useCallback(_.debounce(fetchForgotPassword, 700), [userDetail]);

	return (
		<div className='edit-user'>
			<BreadcrumbComponent subItems={breadcrumbData} />
			{isloadingTable ? (
				<div className='d-flex justify-content-center align-items-center' style={{ height: '580px' }}>
					<LoadingIndicator />
				</div>
			) : (
				<>
					{!_.isEmpty(userDetail) ? (
						<Form onSubmit={handleEditUser}>
							<div className='edit-user__toggle-edit py-2 d-flex align-items-center gap-4'>
								<Form.Check
									type='switch'
									label='Bật/tắt chỉnh sửa'
									checked={!isDisabled}
									onChange={() => setIsDisabled(!isDisabled)}
								/>

								<div
									className={`d-flex flex-wrap gap-4 edit-user__toggle-edit__buttons--${
										isDisabled && 'isDisabled'
									}`}
								>
									<Button variant='info' type='submit'>
										Lưu
									</Button>

									<Form.Group>
										{active ? (
											<Button variant='danger' onClick={onClickOpenBanModal}>
												<FontAwesomeIcon icon={faLock} className='me-2' />
												Khóa tài khoản
											</Button>
										) : (
											<Button variant='success' onClick={onClickOpenBanModal}>
												<FontAwesomeIcon icon={faLockOpen} className='me-2' />
												Mở khóa tài khoản
											</Button>
										)}
									</Form.Group>

									<Button variant='warning' onClick={handleForgotPassword}>
										Gửi yêu cầu cấp lại mật khẩu
									</Button>
								</div>
								<Modal show={showModalBanUser} onHide={handleCloseModalBanUser} centered>
									<Modal.Header closeButton>
										<Modal.Title>Bạn có muốn {!active && 'mở'} khóa người dùng này?</Modal.Title>
									</Modal.Header>
									<Modal.Body>
										<Row>
											<Col xs={5}>
												<img
													src={avatarSrc}
													alt='img'
													onError={e => e.target.setAttribute('src', `${defaultAvatar}`)}
													style={{
														width: '10rem',
														height: '10rem',
														objectFit: 'cover',
														borderRadius: '10px',
													}}
												/>
											</Col>
											<Col xs={7}>
												<p>
													Họ tên: {firstName} {lastName}
												</p>
												<p>Email: {email}</p>
												<Button
													variant={active ? 'danger' : 'success'}
													className='me-3'
													onClick={handleToggleBanUser}
												>
													{active ? 'Khóa' : 'Mở khóa'}
												</Button>
												<Button variant='light' onClick={() => handleCloseModalBanUser()}>
													Hủy bỏ
												</Button>
											</Col>
										</Row>
									</Modal.Body>
								</Modal>
							</div>
							<Row>
								<Col xs={12} xl={7}>
									<Card border='light' className='shadow-sm mb-4'>
										<Card.Body>
											<Row className='mb-3'>
												<Form.Group as={Col} md='6'>
													<Form.Label>First name</Form.Label>
													<span className='text-danger'> *</span>
													<Form.Control
														required
														value={firstName}
														name='firstName'
														onChange={onChange}
														disabled={isDisabled}
													/>
													<Form.Control.Feedback type='invalid'>
														Vui lòng điền đầy đủ thông tin.
													</Form.Control.Feedback>
												</Form.Group>
												<Form.Group as={Col} md='6'>
													<Form.Label>Last name</Form.Label>
													<span className='text-danger'> *</span>
													<Form.Control
														required
														value={lastName}
														name='lastName'
														onChange={onChange}
														disabled={isDisabled}
													/>
													<Form.Control.Feedback type='invalid'>
														Vui lòng điền đầy đủ thông tin.
													</Form.Control.Feedback>
												</Form.Group>
											</Row>
											<Row className='mb-3'>
												<Form.Group as={Col} md='6'>
													<Form.Label>Email</Form.Label>
													<span className='text-danger'> *</span>
													<Form.Control
														required
														type='email'
														pattern='[^@\s]+@[^@\s]+\.[^@\s]+'
														value={email}
														name='email'
														readOnly
													/>
													<Form.Control.Feedback type='invalid'>
														Vui lòng điền đầy đủ và đúng định dạng email.
													</Form.Control.Feedback>
												</Form.Group>
												<Form.Group as={Col} md='3'>
													<Form.Label>Vai trò</Form.Label>
													<span className='text-danger'> *</span>
													<Form.Select
														required
														value={role}
														name='role'
														onChange={onChange}
														disabled={isDisabled}
													>
														<option value=''>Chọn vai trò</option>
														<option value='reader'>Reader</option>
														<option value='admin'>Admin</option>
														<option value='author'>Author</option>
													</Form.Select>
													<Form.Control.Feedback type='invalid'>
														Vui lòng điền đầy đủ thông tin.
													</Form.Control.Feedback>
												</Form.Group>
												<Form.Group as={Col} md='3'>
													<Form.Label>Giới tính</Form.Label>
													<Form.Select
														value={gender}
														name='gender'
														onChange={onChange}
														disabled={isDisabled}
													>
														<option value='male'>Nam</option>
														<option value='female'>Nữ</option>
													</Form.Select>
												</Form.Group>
											</Row>
											<Row className='mb-3'>
												<Form.Group as={Col} md='6'>
													<Form.Label>Trường trung học</Form.Label>
													<Form.Control
														value={highSchool}
														name='highSchool'
														onChange={onChange}
														disabled={isDisabled}
													/>
												</Form.Group>
												<Form.Group as={Col} md='6'>
													<Form.Label>Đại học/Cao đẳng</Form.Label>
													<Form.Control
														value={university}
														name='university'
														onChange={onChange}
														disabled={isDisabled}
													/>
												</Form.Group>
											</Row>
											<Row className='mb-3'>
												<Form.Group as={Col} md={6}>
													<Form.Label>Ngày sinh</Form.Label>
													<Datetime
														timeFormat={false}
														locale='vi'
														renderMonth={(props, month) => (
															<td {...props}>Th{month + 1}</td>
														)}
														onChange={date => setBirthday(date._d)}
														renderInput={(props, openCalendar) => (
															<InputGroup>
																<InputGroup.Text>
																	<FontAwesomeIcon icon={faCalendarAlt} />
																</InputGroup.Text>
																<Form.Control
																	value={
																		birthday
																			? moment(birthday).format('DD/MM/YYYY')
																			: ''
																	}
																	placeholder='mm/dd/yyyy'
																	onFocus={openCalendar}
																	onChange={() => {}}
																	disabled={isDisabled}
																/>
															</InputGroup>
														)}
													/>
												</Form.Group>
												<Form.Group as={Col} md={6}>
													<Form.Label>Công việc</Form.Label>
													<Form.Control
														value={works}
														name='works'
														onChange={onChange}
														disabled={isDisabled}
													></Form.Control>
												</Form.Group>
											</Row>
											<Row className='mb-3'>
												<Form.Group>
													<Form.Label>Chủ đề yêu thích</Form.Label>
													<Select
														placeholder='Chọn chủ đề'
														options={suggestionData}
														value={selectedCategories}
														isMulti
														onChange={data => onChangeCategSelect(data)}
														onFocus={() =>
															setSuggestionData(initalCategoriesSuggestionList.current)
														}
														onInputChange={value => handleSearchCategories(value)}
														isLoading={isLoadingSelect}
														loadingMessage={() => <LoadingIndicator />}
														noOptionsMessage={() => (
															<p className='m-1'>Không có kết quả tìm kiếm nào</p>
														)}
														isDisabled={isDisabled}
													/>
												</Form.Group>
											</Row>
											<Row className='mb-3'>
												<Form.Group>
													<Form.Label>Sở thích</Form.Label>
													<Form.Control
														value={interest}
														name='interest'
														onChange={onChange}
														disabled={isDisabled}
													></Form.Control>
												</Form.Group>
											</Row>
											<Row className='mb-3'>
												<Form.Group>
													<Form.Label>Địa chỉ</Form.Label>
													<Form.Control
														value={address}
														name='address'
														onChange={onChange}
														disabled={isDisabled}
													></Form.Control>
												</Form.Group>
											</Row>
											<Row className='mb-3'>
												<Form.Group>
													<Form.Label>Giới thiệu</Form.Label>
													<div
														className={`rich-text-description ${isDisabled && 'disabled'}`}
													>
														<RichTextEditor
															setTextContent={setDescriptions}
															placeholder='Nhập nội dung'
															initialContent={descriptions || ''}
															isDisabled={isDisabled}
														/>
													</div>
												</Form.Group>
											</Row>
										</Card.Body>
									</Card>
								</Col>

								<Col xs={12} xl={5}>
									<AvatarCoverSocial
										handleOnDropCover={handleOnDropCover}
										isDisabled={isDisabled}
										coverSrc={coverSrc}
										handleOnDropAvatar={handleOnDropAvatar}
										avatarSrc={avatarSrc}
										socials={socials}
										initialSocials={initialSocials}
										setSocials={setSocials}
									/>
								</Col>
							</Row>
						</Form>
					) : (
						<p className='text-center'>Không tìm thấy dữ liệu</p>
					)}
				</>
			)}
		</div>
	);
}
