import { faInfo, faLongArrowAltRight, faSearch, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Form, InputGroup, Table } from '@themesberg/react-bootstrap';
import defaultAvatar from 'assets/icons/defaultLogoAvatar.svg';
import BreadcrumbComponent from 'components/BreadcrumbComponent';
import LoadingIndicator from 'components/LoadingIndicator';
import MyModal from 'components/Modal';
import PaginationComponent from 'components/PaginationComponent';
import { BASE_URL, breadcrumbsData } from 'constants';
import { generateTime } from 'helpers/Common';
import { NotificationError } from 'helpers/Error';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getActivityList } from 'reducers/activity';
import { getPostsByUser } from 'reducers/group-feed';
import { deleteMiniPost } from 'reducers/post-reducer';
import { getFilterSearch } from 'reducers/search';

function NewFeed() {
	const creadModalData = { title: 'Xác nhận xóa feed', type: 'feed' };
	const breadcrumbData = [breadcrumbsData.feedManager.newFeed];

	const [inputSearchValue, setInputSearchValue] = useState('');
	const [currentPage, setCurrentPage] = useState(1);
	const [newFeed, setNewFeed] = useState([]);
	const [isLoadingTable, setIsLoadingTable] = useState(false);
	const [isLoadingSearch, setIsLoadingSearch] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [idFeed, setIdFeed] = useState(null);
	const [totalPage, setTotalPage] = useState(1);
	const [user, setUser] = useState([]);
	const [showInput, setShowInput] = useState(false);

	const callApiPerPage = useRef(10);
	const oldInputSearchValue = useRef('');
	const hamburgerRef = useRef(null);

	const dispatch = useDispatch();

	const history = useHistory();

	useEffect(() => {
		getFeeds();
	}, [currentPage]);

	useEffect(() => {
		function handleClickOutside(event) {
			if (hamburgerRef.current && !hamburgerRef.current.contains(event.target)) {
				setShowInput(false);
			}
		}
		// Bind the event listener
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [hamburgerRef]);

	const getFeeds = async () => {
		setIsLoadingTable(true);
		const params = {
			start: (currentPage - 1) * callApiPerPage.current,
			limit: callApiPerPage.current,
			fromAdminPanel: true,
		};
		try {
			const data = await dispatch(getActivityList(params)).unwrap();
			setNewFeed(data);
			setTotalPage(Math.ceil(100 / callApiPerPage.current));
		} catch (err) {
			NotificationError(err);
		} finally {
			setIsLoadingTable(false);
		}
	};

	const searchUser = async () => {
		setIsLoadingSearch(true);
		try {
			const params = {
				q: inputSearchValue,
				type: 'users',
				start: 0,
			};
			const res = await dispatch(getFilterSearch(params)).unwrap();
			setUser(res.rows);
		} catch (error) {
			NotificationError(error);
		} finally {
			setIsLoadingSearch(false);
		}
	};

	const newData = newFeed.filter(item => item.verb !== 'groupPost');

	const updateInputSearch = e => {
		setInputSearchValue(e.target.value);
		if (!e.target.value) {
			setShowInput(false);
			getFeeds();
		}
	};

	const handleSearch = e => {
		if (e.key === 'Enter' && inputSearchValue.trim().length) {
			oldInputSearchValue.current = inputSearchValue;
			searchUser();
			setShowInput(true);
		}
	};

	const handleDelete = async () => {
		setShowModal(false);
		try {
			await dispatch(deleteMiniPost(idFeed)).unwrap();
			toast.success('Xoá bài viết thành công');
			getFeeds();
		} catch (err) {
			toast.error('Lỗi không xóa được bài viết', { toastId: 'custom-id-SettingMore-error' });
		}
	};

	const handleSearchByUser = async userId => {
		setIsLoadingTable(true);
		try {
			const data = {
				userId: userId,
				params: {
					q: inputSearchValue,
					type: 'users',
					start: (currentPage - 1) * callApiPerPage.current,
					limit: callApiPerPage.current,
					mention: false,
				},
			};
			const res = await dispatch(getPostsByUser(data)).unwrap();
			setNewFeed(res);
		} catch (error) {
			NotificationError(error);
		} finally {
			setIsLoadingTable(false);
		}
	};

	const onClickDirectToWisfeed = item => {
		if (item.minipostId) {
			window.open(`${BASE_URL}detail-feed/mini-post/${item.minipostId}`);
		}
	};

	return (
		<div className='newfeed'>
			<MyModal
				show={showModal}
				onHide={() => setShowModal(false)}
				title={creadModalData.title}
				type={creadModalData.type}
				onDelete={handleDelete}
			/>

			<div className='d-flex justify-content-between align-items-end'>
				<BreadcrumbComponent subItems={breadcrumbData} pageTitle='Danh sách New Feed' />
				<div style={{ padding: '24px 0' }} className='d-flex gap-3'>
					<div className='position-relative' ref={hamburgerRef}>
						<InputGroup
							className='input-group-merge search-bar'
							onClick={() => {
								user.length > 0 && setShowInput(true);
							}}
						>
							<InputGroup.Text>
								<FontAwesomeIcon icon={faSearch} />
							</InputGroup.Text>
							<Form.Control
								type='text'
								placeholder='Tìm kiếm người đăng'
								value={inputSearchValue}
								onChange={updateInputSearch}
								onKeyDown={handleSearch}
							/>
						</InputGroup>
						{showInput && (
							<div className='modalSearch'>
								{isLoadingSearch ? (
									<LoadingIndicator />
								) : (
									<>
										{user.length > 0 ? (
											<div>
												{user.map((item, index) => {
													return (
														<div
															key={index}
															className='item-search'
															onClick={() => handleSearchByUser(item.id)}
														>
															<img
																className='img-search'
																src={
																	item.avatarImage ? item.avatarImage : defaultAvatar
																}
																onError={e =>
																	e.target.setAttribute('src', defaultAvatar)
																}
															/>
															<p className='name'>
																{item.firstName + ' ' + item.lastName}
															</p>
														</div>
													);
												})}
											</div>
										) : (
											<p style={{ textAlign: 'center', paddingTop: '10px' }}>
												Không có kết quả nào
											</p>
										)}
									</>
								)}
							</div>
						)}
					</div>
					{/* <Button variant='secondary' onClick={() => history.push('/create-post')}>
						<FontAwesomeIcon icon={faPlusCircle} className='me-2' />
						Tạo bài viết
					</Button> */}
				</div>
			</div>
			<Card border='light' className='shadow-sm mb-4'>
				<Card.Body>
					<div className='d-flex justify-content-between align-items-center'>
						<div className='table-search'></div>
					</div>
					{isLoadingTable ? (
						<div className='d-flex justify-content-center align-items-center' style={{ height: '550px' }}>
							<LoadingIndicator />
						</div>
					) : (
						<>
							<Table responsive className='table-centered table-nowrap rounded mb-0 user-select-none'>
								<thead className='thead-light'>
									<tr>
										<th className='border-0 newfeed__id'>id</th>
										<th className='border-0 newfeed__createdBy'>người tạo</th>
										<th className='border-0'>nội dung</th>
										<th className='border-0 newfeed__createdAt'>ngày tạo</th>
										<th className='border-0'>hoạt động</th>
									</tr>
								</thead>
								{newData.length > 0 && (
									<tbody>
										{newData.map((item, index) => {
											return (
												<tr key={index}>
													<td style={{ maxWidth: '110px' }}>{item?.minipostId || item.id}</td>
													<td>{item.createdBy.firstName + ' ' + item.createdBy.lastName}</td>
													<td>
														<span
															className='newfeed__content'
															dangerouslySetInnerHTML={{ __html: item.message }}
														></span>
													</td>
													<td>{generateTime(item.time)}</td>
													<td>
														<div className='d-flex gap-1'>
															<Button
																variant='light'
																size='sm'
																title='Xem trang của bài viết'
																onClick={() => onClickDirectToWisfeed(item)}
															>
																<FontAwesomeIcon
																	icon={faLongArrowAltRight}
																	style={{ transform: 'rotate(-45deg)' }}
																/>
															</Button>
															<Button
																style={{ width: '31px' }}
																variant='light'
																size='sm'
																title='Chi tiết'
																onClick={() =>
																	history.push(`/newFeed/${item.minipostId}`)
																}
															>
																<FontAwesomeIcon className='text-info ' icon={faInfo} />
															</Button>
															<Button
																variant='light'
																size='sm'
																title='Xóa'
																onClick={() => {
																	setIdFeed(item.minipostId);
																	setShowModal(true);
																}}
															>
																<FontAwesomeIcon
																	className='text-danger icon-delete'
																	icon={faTrashAlt}
																/>
															</Button>
														</div>
													</td>
												</tr>
											);
										})}
									</tbody>
								)}
							</Table>
							<PaginationComponent
								currentPage={currentPage}
								totalPage={totalPage}
								setCurrentPage={setCurrentPage}
							/>
							{newData.length === 0 && (
								<p className='position-absolute start-50 translate-middle margin-negative'>
									Không có kết quả nào
								</p>
							)}
						</>
					)}
				</Card.Body>
			</Card>
		</div>
	);
}

export default NewFeed;
