import { faEdit, faLongArrowAltRight, faPlusCircle, faSearch, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Button, Card, Form, InputGroup, Modal, Table } from '@themesberg/react-bootstrap';
import BookThumbnail from 'components/BookThumbnail';
import BreadcrumbComponent from 'components/BreadcrumbComponent';
import { ButtonExport, ButtonImport } from 'components/ButtonExcel';
import CustomDropdownMenu from 'components/CustomDropdownMenu';
import LoadingIndicator from 'components/LoadingIndicator';
import MyModal from 'components/Modal';
import PaginationComponent from 'components/PaginationComponent';
import { BASE_URL, breadcrumbsData } from 'constants';
import { generateAuthorsName, generateCategories, generateTranslatorsName } from 'helpers/Common';
import { NotificationError } from 'helpers/Error';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { deleteBook, getBookList } from 'reducers/books';
import { Routes } from 'routes';

function Books() {
	const breadcrumbData = [breadcrumbsData.booksManager.books];

	const [books, setBooks] = useState([]);
	const [inputSearchValue, setInputSearchValue] = useState('');
	const [sort, setSort] = useState('DESC');
	const [filterStatus, setFilterStatus] = useState('all-status');
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPage, setTotalPage] = useState(1);
	const [isloading, setIsLoading] = useState(true);

	const [showModal, setShowModal] = useState(false);
	const [showModalConfirmDelete, setShowModalConfirmDelete] = useState(false);

	const callApiPerPage = useRef(10);
	const oldInputSearchValue = useRef('');
	const totalBook = useRef(0);
	const currentItemInfo = useRef({});

	const dispatch = useDispatch();
	const history = useHistory();

	useEffect(() => {
		getBookListData();
	}, [currentPage, sort, filterStatus]);

	useEffect(() => {
		if (inputSearchValue === '' && inputSearchValue !== oldInputSearchValue.current) {
			setTimeout(getBookListData, 700);
		}
	}, [inputSearchValue]);

	const getBookListData = async () => {
		setIsLoading(true);
		try {
			const params = {
				start: (currentPage - 1) * callApiPerPage.current,
				limit: callApiPerPage.current,
				sort: JSON.stringify([{ direction: sort, property: 'id' }]),
				filter: JSON.stringify([
					{
						operator: 'search',
						value: inputSearchValue.trim(),
						property: 'name',
					},
					filterStatus !== 'all-status' && {
						operator: 'eq',
						value: filterStatus,
						property: 'active',
					},
				]),
			};
			oldInputSearchValue.current = inputSearchValue.trim();
			const res = await dispatch(getBookList(params)).unwrap();
			setBooks(res.rows);
			setTotalPage(Math.ceil(res.count / callApiPerPage.current));
			totalBook.current = res.count;
		} catch (error) {
			NotificationError(error);
		} finally {
			setIsLoading(false);
		}
	};

	const updateInputSearchValue = e => {
		setInputSearchValue(e.target.value);
	};

	const handleKeyDown = e => {
		if (e.key === 'Enter' && inputSearchValue.trim() !== oldInputSearchValue.current) {
			setCurrentPage(1);
			if (currentPage !== 1) {
				setCurrentPage(1);
			} else {
				getBookListData();
			}
		}
	};

	const handleShowModalItemInfo = item => {
		currentItemInfo.current = item;
		setShowModal(true);
	};

	const handleShowModalConfirmDelete = () => {
		setShowModal(false);
		setShowModalConfirmDelete(true);
	};

	const onHideModalConfirmDelete = () => {
		setShowModalConfirmDelete(false);
		setShowModal(false);
	};

	const confirmDelete = async () => {
		onHideModalConfirmDelete();
		try {
			await dispatch(deleteBook(currentItemInfo.current.id)).unwrap();
			toast.success('Đã xóa sách thành công');
			getBookListData(currentPage);
		} catch (err) {
			NotificationError(err);
		}
	};

	const onChangeFilterStatus = value => {
		setCurrentPage(1);
		setFilterStatus(value);
	};

	return (
		<div className='books-page'>
			<div className='d-flex justify-content-between align-items-end'>
				<BreadcrumbComponent subItems={breadcrumbData} pageTitle={`Danh sách Sách (${totalBook.current})`} />
				<div className='d-flex gap-3 align-items-center'>
					<div>
						<InputGroup className='input-group-merge search-bar'>
							<InputGroup.Text>
								<FontAwesomeIcon icon={faSearch} />
							</InputGroup.Text>
							<Form.Control
								type='text'
								placeholder='Tìm kiếm sách'
								value={inputSearchValue}
								onChange={updateInputSearchValue}
								onKeyDown={handleKeyDown}
							/>
						</InputGroup>
					</div>

					<ButtonImport handleSuccessImport={() => getBookListData(currentPage)} />
					<ButtonExport />

					<Button variant='secondary' onClick={() => history.push(Routes.CreateBook.path)}>
						<FontAwesomeIcon icon={faPlusCircle} style={{ marginRight: '12px' }} />
						Tạo sách
					</Button>
				</div>
			</div>

			<Card border='light' className='shadow-sm mb-4'>
				<Card.Body className='pb-0'>
					{isloading ? (
						<div className='d-flex justify-content-center align-items-center' style={{ height: '580px' }}>
							<LoadingIndicator />
						</div>
					) : (
						<>
							<Table responsive className='table-centered table-nowrap rounded mb-0'>
								<thead className='thead-light'>
									<tr>
										<th className='border-0' style={{ width: '7%' }}>
											<CustomDropdownMenu
												title='id'
												dataArray={[
													{ value: 'DESC', title: 'Lớn đến bé (mới nhất)' },
													{ value: 'ASC', title: 'Bé đến lớn (cũ nhất)' },
												]}
												onFilterChange={setSort}
												filterValue={sort}
											/>
										</th>
										<th className='border-0'>tên sách</th>
										<th className='border-0' style={{ width: '20%' }}>
											tác giả
										</th>
										<th className='border-0' style={{ width: '15%' }}>
											chủ đề
										</th>
										<th className='border-0' style={{ width: '15%' }}>
											<CustomDropdownMenu
												title='trạng thái'
												dataArray={[
													{ value: 'all-status', title: 'Tất cả trạng thái' },
													{ value: true, title: 'Kích hoạt' },
													{ value: false, title: 'Vô hiệu' },
												]}
												onFilterChange={onChangeFilterStatus}
												filterValue={filterStatus}
											/>
										</th>
										<th className='border-0' style={{ width: '10%' }}>
											hoạt động
										</th>
									</tr>
								</thead>
								{books.length > 0 && (
									<tbody>
										{books.map(item => (
											<tr key={item.id}>
												<td>{item.id}</td>
												<td title={item.name}>{item.name}</td>
												<td
													title={
														item.authors.length
															? item.authors[0].authorName
															: 'Chưa xác định'
													}
												>
													{item.authors.length ? item.authors[0].authorName : 'Chưa xác định'}
												</td>
												<td title={generateCategories(item.categories)}>
													{generateCategories(item.categories)}
												</td>
												<td>
													<Badge
														className='p-2'
														bg={item.active ? 'success' : 'warning'}
														style={{ fontSize: '0.75rem' }}
													>
														{item.active ? 'Kích hoạt' : 'Vô hiệu'}
													</Badge>
												</td>
												<td>
													<div className='d-flex gap-1'>
														<Button
															variant='light'
															size='sm'
															title='Xem trang chi tiết sách'
															onClick={() => {
																window.open(`${BASE_URL}book/detail/${item.id}`);
															}}
														>
															<FontAwesomeIcon
																className='text-Secondary'
																icon={faLongArrowAltRight}
																style={{ transform: 'rotate(-45deg)' }}
															/>
														</Button>
														<Button
															variant='light'
															size='sm'
															title='Chỉnh sửa'
															onClick={() => {
																history.push(`/edit-book/${item.id}`);
															}}
														>
															<FontAwesomeIcon className='text-success' icon={faEdit} />
														</Button>
														<Button
															variant='light'
															size='sm'
															title='Xóa'
															onClick={() => handleShowModalItemInfo(item)}
														>
															<FontAwesomeIcon
																className='text-danger'
																icon={faTrashAlt}
															/>
														</Button>
													</div>
												</td>
											</tr>
										))}
									</tbody>
								)}
							</Table>
							<PaginationComponent
								currentPage={currentPage}
								totalPage={totalPage}
								setCurrentPage={setCurrentPage}
							/>

							{books.length === 0 && (
								<p className='position-absolute start-50 translate-middle margin-negative'>
									Không có kết quả nào
								</p>
							)}
						</>
					)}
				</Card.Body>
			</Card>
			{!_.isEmpty(currentItemInfo.current) && (
				<>
					<Modal
						className='modal-approve-book-info'
						show={showModal}
						onHide={() => setShowModal(false)}
						centered
					>
						<Modal.Header>
							<h5 style={{ margin: 'auto' }}>Chi tiết</h5>
						</Modal.Header>
						<Modal.Body>
							<div className='d-flex'>
								<div className='modal-approve-book-info__book-thumbnail'>
									<BookThumbnail src={currentItemInfo.current.images[0]} />
								</div>
								<div style={{ fontSize: '15px' }}>
									<div className='mb-2'>
										Tên sách: <strong>{currentItemInfo.current.name}</strong>
									</div>
									<div className='mb-2'>
										Tiêu đề phụ:{' '}
										<strong>
											{currentItemInfo.current.subName
												? currentItemInfo.current.subName
												: 'Không có'}
										</strong>
									</div>
									<div className='mb-2'>
										Tên sách gốc:{' '}
										<strong>
											{currentItemInfo.current.originalName
												? currentItemInfo.current.originalName
												: 'Không có'}
										</strong>
									</div>
									<div className='mb-2'>
										Tác giả: <strong>{generateAuthorsName(currentItemInfo.current.authors)}</strong>
									</div>
									<div className='mb-2'>
										Dịch giả:{' '}
										<strong>{generateTranslatorsName(currentItemInfo.current.translators)}</strong>
									</div>
									<div className='mb-2'>
										Chủ đề:{' '}
										<strong>{generateCategories(currentItemInfo.current.categories)}</strong>
									</div>
									<div className='mb-2'>
										Ngày phát hành:{' '}
										<strong>
											{currentItemInfo.current.publishDate
												? moment(currentItemInfo.current.publishDate).format('DD-MM-YYYY')
												: 'Chưa xác định'}
										</strong>
									</div>
									<div className='mb-2'>
										Số trang: <strong>{currentItemInfo.current.page}</strong>
									</div>
									<div className='mb-2'>
										Ngôn ngữ:{' '}
										<strong>
											{currentItemInfo.current.language === 'vn' ? 'Việt Nam' : 'Tiếng Anh'}
										</strong>
									</div>
								</div>
							</div>
						</Modal.Body>
						<Modal.Footer>
							<Button variant='danger' onClick={handleShowModalConfirmDelete} className='me-3'>
								Xóa sách
							</Button>
							<Button variant='light' className='text-gray' onClick={() => setShowModal(false)}>
								Đóng
							</Button>
						</Modal.Footer>
					</Modal>

					<MyModal
						show={showModalConfirmDelete}
						onHide={onHideModalConfirmDelete}
						onDelete={confirmDelete}
						title={'Xác nhận xóa sách'}
						type={'cuốn sách'}
					/>
				</>
			)}
		</div>
	);
}

export default Books;
