export const Routes = {
	// pages
	DashboardOverview: { path: '/' },
	Users: { path: '/users' },
	EditUser: { path: '/edit-user/:userId' },
	Login: { path: '/login' },
	ForgotPassword: { path: '/forgot-password' },
	ResetPassword: { path: '/examples/reset-password' },
	NotFound: { path: '/examples/404' },
	ServerError: { path: '/examples/500' },
	Groups: { path: '/groups' },
	Books: { path: '/books-manager/books' },
	BookApproval: { path: '/books-manager/book-approval' },
	ConfirmMyBook: { path: '/books-manager/confirm-my-book' },
	Quotes: { path: '/quotes' },
	QuotesDetail: { path: '/quotes/detail/:id' },
	CreateBook: { path: '/create-book' },
	Category: { path: '/category' },
	Hashtag: { path: '/hashtag' },
	DetailGroupFeed: { path: '/groudFeed/:id' },
	DetailNewFeed: { path: '/newFeed/:id' },
	EditBook: { path: '/edit-book/:bookId' },
	// CreatePost: { path: '/create-post' },
	NewFeed: { path: '/feed-manager/new-feed' },
	DetailFeed: { path: '/detail-feed/:id' },
	GroupFeed: { path: '/feed-manager/group-feed' },
	PopularFeed: { path: '/feed-manager/popular-feed' },
	Publisher: { path: '/publisher' },
};
