import { faEdit, faPlusCircle, faSearch, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Button, Card, Form, InputGroup, Modal, Table } from '@themesberg/react-bootstrap';
import BreadcrumbComponent from 'components/BreadcrumbComponent';
import CustomDropdownMenu from 'components/CustomDropdownMenu';
import LoadingIndicator from 'components/LoadingIndicator';
import PaginationComponent from 'components/PaginationComponent';
import { breadcrumbsData } from 'constants';
import { NotificationError } from 'helpers/Error';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { createPopularFeed, editPopularFeed, getPopularFeed } from 'reducers/activity';

function PopularFeed() {
	const breadcrumbData = [breadcrumbsData.feedManager.popularFeed];

	const [popularFeed, setPopularFeed] = useState([]);
	const [inputSearchValue, setInputSearchValue] = useState('');
	const [filterStatus, setFilterStatus] = useState('all');
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPage, setTotalPage] = useState(1);
	const [isLoadingTable, setIsLoadingTable] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [choosenFeedId, setChoosenFeedId] = useState(null);
	const [title, setTitle] = useState('');
	const [link, setLink] = useState('');
	const [active, setActive] = useState(true);
	const [isFetching, setIsFetching] = useState(false);

	const dispatch = useDispatch();

	useEffect(() => {
		handleGetPopularFeed();
	}, [filterStatus, currentPage]);

	const handleGetPopularFeed = async () => {
		const params = {
			sort: JSON.stringify([{ direction: 'DESC', property: 'createdAt' }]),
			filter: JSON.stringify([
				filterStatus !== 'all' && {
					operator: 'eq',
					value: filterStatus,
					property: 'active',
				},
			]),
		};
		try {
			setIsLoadingTable(true);
			const response = await dispatch(getPopularFeed(params)).unwrap();
			setPopularFeed(response);
		} catch (error) {
			NotificationError(error);
		} finally {
			setIsLoadingTable(false);
		}
	};

	const openModalCreate = () => {
		setChoosenFeedId(null);
		setTitle('');
		setLink('');
		setActive(true);
		setShowModal(true);
	};

	const openModalEdit = item => {
		setChoosenFeedId(item.id);
		setTitle(item.title);
		setLink(item.link);
		setActive(item.active);
		setShowModal(true);
	};

	const onChangeInputSearch = e => {
		setInputSearchValue(e.target.value);
	};

	const handleSubmit = async () => {
		setIsFetching(true);
		let params = {};
		if (choosenFeedId) {
			params = {
				id: choosenFeedId,
				body: {
					title: title,
					link: link,
					active: active,
				},
			};
		} else {
			params = {
				title: title,
				link: link,
				active: active,
			};
		}
		try {
			if (choosenFeedId) {
				await dispatch(editPopularFeed(params)).unwrap();
				toast.success('Chỉnh sửa thành công');
			} else {
				await dispatch(createPopularFeed(params)).unwrap();
				toast.success('Tạo thành công');
			}
		} catch (error) {
			NotificationError(error);
		} finally {
			setIsFetching(false);
			setShowModal(false);
		}
	};

	const onChangeFilterStatus = value => {
		setFilterStatus(value);
		setCurrentPage(1);
	};

	return (
		<div className='popular-feed'>
			<div className='d-flex justify-content-between align-items-end'>
				<BreadcrumbComponent subItems={breadcrumbData} pageTitle='Bài viết nổi bật' />
				<div style={{ padding: '24px 0' }} className='d-flex gap-3'>
					<div className='position-relative'>
						<InputGroup className='input-group-merge search-bar'>
							<InputGroup.Text>
								<FontAwesomeIcon icon={faSearch} />
							</InputGroup.Text>
							<Form.Control
								type='text'
								placeholder='Tìm kiếm bài viết'
								value={inputSearchValue}
								onChange={onChangeInputSearch}
								// onKeyDown={handleSearch}
							/>
						</InputGroup>
					</div>
					<Button variant='secondary' onClick={openModalCreate}>
						<FontAwesomeIcon icon={faPlusCircle} className='me-2' />
						Tạo bài viết nổi bật
					</Button>
				</div>
			</div>
			<Card border='light' className='shadow-sm mb-4'>
				<Card.Body>
					<div className='d-flex justify-content-between align-items-center'>
						<div className='table-search'></div>
					</div>
					{isLoadingTable ? (
						<div className='d-flex justify-content-center align-items-center' style={{ height: '550px' }}>
							<LoadingIndicator />
						</div>
					) : (
						<>
							<Table responsive className='table-centered table-nowrap rounded mb-0 user-select-none'>
								<thead className='thead-light'>
									<tr>
										<th className='border-0 newfeed__id'>id</th>
										<th className='border-0 newfeed__createdBy'>tiêu đề</th>
										<th className='border-0'>đường dẫn</th>
										<th className='border-0'>
											<CustomDropdownMenu
												title='trạng thái'
												dataArray={[
													{ value: 'all', title: 'Tất cả' },
													{ value: true, title: 'Kích hoạt' },
													{ value: false, title: 'Vô hiệu' },
												]}
												onFilterChange={onChangeFilterStatus}
												filterValue={filterStatus}
											/>
										</th>
										<th className='border-0'>hoạt động</th>
									</tr>
								</thead>
								{popularFeed.length > 0 && (
									<tbody>
										{popularFeed.map((item, index) => {
											return (
												<tr key={index}>
													<td style={{ maxWidth: '110px' }}>{item.id}</td>
													<td>{item.title}</td>
													<td>{item.link}</td>
													<td>
														<Badge
															className='p-2'
															bg={item.active ? 'success' : 'warning'}
															style={{ fontSize: '0.75rem' }}
														>
															{item.active ? 'Kích hoạt' : 'Vô hiệu'}
														</Badge>
													</td>
													<td>
														<div className='d-flex gap-1'>
															<Button
																variant='light'
																size='sm'
																title='Chỉnh sửa'
																onClick={() => openModalEdit(item)}
															>
																<FontAwesomeIcon
																	className='text-success'
																	icon={faEdit}
																/>
															</Button>
															<Button
																variant='light'
																size='sm'
																title='Xóa'
																// onClick={() => handleShowModalItemInfo(item)}
															>
																<FontAwesomeIcon
																	className='text-danger'
																	icon={faTrashAlt}
																/>
															</Button>
														</div>
													</td>
												</tr>
											);
										})}
									</tbody>
								)}
							</Table>
							<PaginationComponent
								currentPage={currentPage}
								totalPage={totalPage}
								setCurrentPage={setCurrentPage}
							/>
							{popularFeed.length === 0 && (
								<p className='position-absolute start-50 translate-middle margin-negative'>
									Không có kết quả nào
								</p>
							)}
						</>
					)}
				</Card.Body>
			</Card>

			{/* Modal chỉnh sửa bài viết */}
			<Modal className='modal-edit-popular-feed' show={showModal} onHide={() => setShowModal(false)} centered>
				<Modal.Header>
					<h5 style={{ margin: 'auto' }}>{choosenFeedId ? 'Chi tiết' : 'Tạo bài viết nổi bật'}</h5>
				</Modal.Header>

				<Modal.Body>
					<Form.Group>
						<Form.Label>Tiêu đề</Form.Label>
						<Form.Control placeholder='Tiêu đề' value={title} onChange={e => setTitle(e.target.value)} />
					</Form.Group>

					<Form.Group className='mt-3'>
						<Form.Label>Đường dẫn</Form.Label>
						<Form.Control placeholder='Đường dẫn' value={link} onChange={e => setLink(e.target.value)} />
					</Form.Group>

					<div className='mt-3 my-3 d-flex'>
						<Form.Label>Kích hoạt:</Form.Label>
						<Form style={{ display: 'flex' }}>
							<Form.Check
								label={active ? 'Có kích hoạt' : 'Vô hiệu'}
								type='switch'
								style={{ margin: '0px 20px' }}
								checked={active}
								readOnly
								onClick={() => setActive(prev => !prev)}
							/>
						</Form>
					</div>
				</Modal.Body>

				<Modal.Footer>
					<Button
						variant={choosenFeedId ? 'info' : 'success'}
						onClick={handleSubmit}
						className='me-2'
						disabled={!title || !link || isFetching}
					>
						{choosenFeedId ? 'Lưu' : 'Tạo'}
					</Button>
					<Button variant='light' className='text-gray' onClick={() => setShowModal(false)}>
						Đóng
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
}

export default PopularFeed;
