import { faInfo, faLongArrowAltRight, faSearch, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Form, InputGroup, Table } from '@themesberg/react-bootstrap';
import BreadcrumbComponent from 'components/BreadcrumbComponent';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import defaultAvatar from 'assets/img/avatar-default.jpeg';
import CustomDropdownMenu from 'components/CustomDropdownMenu';
import LoadingIndicator from 'components/LoadingIndicator';
import MyModal from 'components/Modal';
import PaginationComponent from 'components/PaginationComponent';
import { BASE_URL, breadcrumbsData } from 'constants';
import { NotificationError } from 'helpers/Error';
import { deleteQuote, getQuoteList } from 'reducers/quotes-reducer';
import { getFilterSearch } from 'reducers/search';

function Quotes() {
	const breadcrumbData = [breadcrumbsData.quotesManager.quotes];
	const creadModalData = { title: 'Xác nhận xóa quotes', type: 'quotes' };

	const [quotes, setQuotes] = useState([]);
	const [sort, setSort] = useState('DESC');
	const [filter, setFilter] = useState([]);
	const [inputSearchValue, setInputSearchValue] = useState('');
	const [showModal, setShowModal] = useState(false);
	const [idQuotes, setIdQuotes] = useState(null);
	const [currentPage, setCurrentPage] = useState(1);
	const [totalPage, setTotalPage] = useState(1);
	const [totalQuote, setTotalQuote] = useState(0);
	const [isloading, setIsLoading] = useState(false);

	const oldInputSearchValue = useRef('');
	const callApiPerPage = useRef(10);
	const getDataFirstTime = useRef(true);

	const dispatch = useDispatch();
	const history = useHistory();

	useEffect(() => {
		if (inputSearchValue) {
			getSearchQuotes();
		} else {
			getQuotesListData(currentPage);
		}
	}, [currentPage, sort]);

	const getQuotesListData = async currentPageData => {
		setIsLoading(true);
		const params = {
			start: (currentPageData - 1) * callApiPerPage.current,
			limit: callApiPerPage.current,
			sort: JSON.stringify([{ direction: sort, property: 'createdAt' }]),
			filter: JSON.stringify(filter),
		};

		try {
			const data = await dispatch(getQuoteList(params)).unwrap();
			setQuotes(data.rows);
			setTotalPage(Math.ceil(data.count / callApiPerPage.current));
			setTotalQuote(data.count);
		} catch (err) {
			NotificationError(err);
		} finally {
			getDataFirstTime.current = false;
			setIsLoading(false);
		}
	};

	const getAuthor = author => {
		if (author && author.length) {
			const authorName = author.map(item => item.authorName);
			return authorName.join(' - ');
		} else {
			return 'Chưa xác định';
		}
	};

	const handleNavigate = id => {
		history.push(`/quotes/detail/${id}`);
	};

	useEffect(() => {
		if (!oldInputSearchValue.current && !getDataFirstTime.current) {
			getQuotesListData(1);
		}
	}, [oldInputSearchValue.current]);

	const updateInputSearch = e => {
		setInputSearchValue(e.target.value);
		if (!e.target.value.trim().length) {
			oldInputSearchValue.current = '';
		}
	};

	const getSearchQuotes = async () => {
		setIsLoading(true);
		const params = {
			q: inputSearchValue.trim(),
			type: 'quotes',
			start: (currentPage - 1) * callApiPerPage.current,
			limit: callApiPerPage.current,
		};
		try {
			const data = await dispatch(getFilterSearch(params)).unwrap();
			setQuotes(data.rows);
			setTotalPage(Math.ceil(data.count / callApiPerPage.current));
		} catch (err) {
			NotificationError(err);
		} finally {
			setIsLoading(false);
		}
	};

	const handleSearch = e => {
		if (e.key === 'Enter' && inputSearchValue.trim().length && inputSearchValue !== oldInputSearchValue.current) {
			oldInputSearchValue.current = inputSearchValue;
			getSearchQuotes();
		}
	};

	const handleDelete = async () => {
		try {
			await dispatch(deleteQuote(idQuotes)).unwrap();
			toast.success('Xoá thành công!');
			setShowModal(false);
			getQuotesListData(currentPage);
		} catch (err) {
			NotificationError(err);
		}
	};

	return (
		<>
			<MyModal
				show={showModal}
				onHide={() => setShowModal(false)}
				onDelete={handleDelete}
				title={creadModalData.title}
				type={creadModalData.type}
			/>
			<div className='d-flex justify-content-between align-items-end'>
				<BreadcrumbComponent subItems={breadcrumbData} pageTitle={`Danh sách quote (${totalQuote})`} />
				<div style={{ padding: '24px 0' }}>
					<InputGroup className='input-group-merge search-bar'>
						<InputGroup.Text>
							<FontAwesomeIcon icon={faSearch} />
						</InputGroup.Text>
						<Form.Control
							type='text'
							placeholder='Tìm kiếm'
							value={inputSearchValue}
							onChange={updateInputSearch}
							onKeyDown={handleSearch}
						/>
					</InputGroup>
				</div>
			</div>
			<Card border='light' className='shadow-sm mb-4'>
				<Card.Body>
					{isloading ? (
						<div className='d-flex justify-content-center align-items-center' style={{ height: '580px' }}>
							<LoadingIndicator />
						</div>
					) : (
						<>
							<Table responsive className='table-centered table-nowrap rounded mb-0 user-select-none'>
								<thead className='thead-light'>
									<tr>
										<th className='border-0'>
											<CustomDropdownMenu
												title='id'
												dataArray={[
													{ value: 'DESC', title: 'Lớn đến bé (mới nhất)' },
													{ value: 'ASC', title: 'Bé đến lớn (cũ nhất)' },
												]}
												onFilterChange={setSort}
												filterValue={sort}
											/>
										</th>
										<th className='border-0'>Nội dung</th>
										<th className='border-0'>Sách</th>
										<th className='border-0'>Tác giả</th>
										<th className='border-0'>Người tạo</th>
										<th className='border-0'>hoạt động</th>
									</tr>
								</thead>
								{!!quotes.length && (
									<tbody>
										{quotes.map((item, index) => (
											<tr key={index}>
												<td>{item.id}</td>
												<td>{item.quote}</td>
												<td>{item.book.name}</td>
												<td>{getAuthor(item.book.authors)}</td>
												<td>
													<img
														src={item.user.avatarImage || defaultAvatar}
														alt={item.user.fullName}
														className='user-image'
														onError={e => e.target.setAttribute('src', defaultAvatar)}
													/>
													<span className='mx-2'>
														{item.user.fullName
															? item.user.fullName
															: item.user.firstName + ' ' + item.user.lastName}
													</span>
												</td>
												<td>
													<div className='d-flex gap-1'>
														<Button
															variant='light'
															size='sm'
															title='Xem trang quote'
															onClick={() =>
																window.open(
																	`${BASE_URL}quotes/detail/${item.id}/${item.user.id}`
																)
															}
														>
															<FontAwesomeIcon
																className='text-cecondary'
																icon={faLongArrowAltRight}
																style={{ transform: 'rotate(-45deg)' }}
															/>
														</Button>
														<Button
															style={{ width: '31px' }}
															variant='light'
															size='sm'
															title='Chi tiết'
															onClick={() => handleNavigate(item.id)}
														>
															<FontAwesomeIcon className='text-info' icon={faInfo} />
														</Button>
														<Button
															variant='light'
															size='sm'
															title='Xóa'
															onClick={() => {
																setShowModal(true);
																setIdQuotes(item.id);
															}}
														>
															<FontAwesomeIcon
																className='text-danger'
																icon={faTrashAlt}
															/>
														</Button>
													</div>
												</td>
											</tr>
										))}
									</tbody>
								)}
							</Table>
							<PaginationComponent
								currentPage={currentPage}
								totalPage={totalPage}
								setCurrentPage={setCurrentPage}
							/>
							{quotes.length === 0 && (
								<p className='position-absolute start-50 translate-middle margin-negative'>
									Không có kết quả nào
								</p>
							)}
						</>
					)}
				</Card.Body>
			</Card>
		</>
	);
}

export default Quotes;
