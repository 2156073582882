import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card } from '@themesberg/react-bootstrap';
import { Button } from '@themesberg/react-bootstrap/lib/esm';
import bookImageDefault from 'assets/img/default-book.png';
import classNames from 'classnames';
import BreadcrumbComponent from 'components/BreadcrumbComponent';
import LoadingIndicator from 'components/LoadingIndicator';
import MyModal from 'components/Modal';
import { BASE_URL, breadcrumbsData } from 'constants';
import { generateTime } from 'helpers/Common';
import { NotificationError } from 'helpers/Error';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getDetailFeed } from 'reducers/activity';
import { deleteMiniPost } from 'reducers/post-reducer';

function DetailNewFeed() {
	const breadcrumbData = [breadcrumbsData.feedManager.newFeed, breadcrumbsData.feedManager.detailNewFeed];

	const [isloading, setIsLoading] = useState(false);
	const [newFeedDetail, setNewFeedDetail] = useState([]);
	const [readMore, setReadMore] = useState(false);
	const [photoIndex, setPhotoIndex] = useState(0);
	const [isOpen, setIsOpen] = useState(false);
	const [showModal, setShowModal] = useState(false);

	const { id } = useParams();
	const dispatch = useDispatch();
	const history = useHistory();

	useEffect(() => {
		getDetailFeedData();
	}, []);

	const getDetailFeedData = async () => {
		setIsLoading(true);
		try {
			const data = await dispatch(getDetailFeed(id)).unwrap();
			setNewFeedDetail(data);
		} catch (err) {
			NotificationError(err);
		} finally {
			setIsLoading(false);
		}
	};

	// const genarateAuthor = authors => {
	// 	if (authors && authors.length) {
	// 		const authorName = authors.map(author => author.authorName);
	// 		return authorName;
	// 	} else {
	// 		return 'chưa xác định';
	// 	}
	// };

	const renderContent = data => {
		return <div className={readMore ? '' : 'content-feed'} dangerouslySetInnerHTML={{ __html: data }}></div>;
	};

	const renderImgBook = item => {
		if (!item?.frontBookCover && !item?.images?.length) {
			return bookImageDefault;
		} else {
			let imgSrc = '';
			if (item?.frontBookCover) {
				imgSrc = item.frontBookCover;
			} else if (item?.images?.length) {
				imgSrc = item?.images[0];
			}
			return imgSrc.slice(0, 4).includes('http') ? imgSrc : `${BASE_URL}${imgSrc}`;
		}
	};

	const renderImages = item => {
		return (
			<>
				{item?.length > 0 ? (
					<>
						{item.map((images, index) => {
							if (index < 3) {
								return (
									<div
										style={{ cursor: 'pointer' }}
										key={index}
										className='main-confirm-my-book__confirm__image-item'
										onClick={() => {
											setIsOpen(true);
											setPhotoIndex(index);
										}}
									>
										<img src={`${BASE_URL}${images}`} />
									</div>
								);
							}
						})}
						{item?.length >= 3 && (
							<div className='main-confirm-my-book__confirm__image-item'>
								<div className='main-confirm-my-book__confirm__image-item'>
									<img src={`${BASE_URL}${item[3]}`} />
								</div>

								<div
									style={{ cursor: 'pointer' }}
									onClick={() => {
										setIsOpen(true);
										setPhotoIndex(3);
									}}
									className={classNames('main-confirm-my-book__confirm__image-over', {
										'show': item?.length > 3,
									})}
								>
									+{item?.length - 3}
								</div>
							</div>
						)}
						{isOpen && (
							<Lightbox
								mainSrc={`${BASE_URL}${item[photoIndex]}`}
								nextSrc={item[(photoIndex + 1) % item.length]}
								prevSrc={item[(photoIndex + item.length - 1) % item.length]}
								onCloseRequest={() => setIsOpen(false)}
								onMovePrevRequest={() => setPhotoIndex((photoIndex + item.length - 1) % item.length)}
								onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % item.length)}
							/>
						)}
					</>
				) : (
					'Không có'
				)}
			</>
		);
	};

	const handleDelete = async () => {
		try {
			await dispatch(deleteMiniPost(id)).unwrap();
			toast.success('Xoá bài viết thành công');
			history.goBack();
		} catch (err) {
			toast.error('Lỗi không xóa được bài viết', { toastId: 'custom-id-SettingMore-error' });
		}
	};

	return (
		<>
			<div className='title w-100 d-flex align-items-center justify-content-between'>
				<BreadcrumbComponent subItems={breadcrumbData} pageTitle='Chi tiết feed' />
				<Button className='shadow-none btn-danger' onClick={() => setShowModal(true)}>
					Xóa Feed
					<FontAwesomeIcon icon={faTrashAlt} className='mx-1' />
				</Button>
			</div>
			{isloading ? (
				<div className='d-flex justify-content-center align-items-center' style={{ height: '580px' }}>
					<LoadingIndicator />
				</div>
			) : (
				<>
					{newFeedDetail &&
						newFeedDetail.map(item => {
							return (
								<Card border='light' className='shadow-sm mb-4 p-3' key={item.id}>
									<h5>Bài viết :</h5>
									<div>
										<div className='title'>Nội dung bài viết :</div>
										<div className='box-feed-content'>
											{renderContent(item.message)}
											{item?.message?.length > 500 ? (
												<h6
													style={{ cursor: 'pointer', color: 'blue' }}
													className='mt-4'
													onClick={() => setReadMore(!readMore)}
												>
													{readMore ? 'Rút gọn : ...' : 'Xem thêm ...'}
												</h6>
											) : (
												<></>
											)}
										</div>
									</div>
									<div className='items-feed'>
										<div className='title'>Người tạo : </div>
										<div className='box-content'>
											<div>
												{item?.createdBy?.fullName
													? item?.createdBy?.fullName
													: item?.createdBy?.firstName + '' + item?.createdBy?.lastName}
											</div>
										</div>
									</div>
									<div className='items-feed mb-3'>
										<div className='title'>Ngày tạo : </div>
										<div className='box-content'>
											<div>{generateTime(item?.time)}</div>
										</div>
									</div>

									<div className='d-flex gap-5'>
										<div className='d-flex gap-2  align-items-center'>
											<div className='titl'>Like : </div>
											<div>{item?.like}</div>
										</div>
										<div className='d-flex gap-2  align-items-center'>
											<div className='titl'>Comment : </div>
											<div>{item?.comment}</div>
										</div>
										<div className='d-flex gap-2  align-items-center'>
											<div className='m'>Share : </div>
											<div>{item?.share}</div>
										</div>
									</div>
									<h5 className='mt-5 mb-0'>Các tùy chọn thêm vào bài viết : </h5>
									<div className='items-feed'>
										<div className='title'>Sách : </div>
										<div className='box-content'>
											<div className='d-flex align-items-center'>
												{!_.isEmpty(item?.book) ? (
													<>
														<img
															src={renderImgBook(item.book)}
															style={{
																width: '40px',
																height: '50px',
																marginRight: '10px',
															}}
														/>
														<div>{item?.book?.name.length > 0 && item?.book?.name}</div>
													</>
												) : (
													<div>không có </div>
												)}
											</div>
										</div>
									</div>

									<div className='items-feed'>
										<div className='title'>Tác giả : </div>
										<div className='d-flex flex-wrap gap-2'>
											{!_.isEmpty(item?.mentionsAuthors) ? (
												<>
													{item?.mentionsAuthors.map((item, index) => (
														<span key={index} className='item-mentions'>
															{item?.authors?.fullName
																? item?.authors?.fullName
																: item?.authors?.firstName +
																  ' ' +
																  item?.authors?.lastName}
														</span>
													))}
												</>
											) : (
												<span className='item-mentions'>Không xác định</span>
											)}
										</div>
									</div>

									<div className='items-feed'>
										<div className='title'>Chủ đề : </div>

										<div className='d-flex flex-wrap gap-2'>
											{item?.mentionsCategories.length > 0 ? (
												item?.mentionsCategories.map((item, index) => (
													<span key={index} className='item-mentions'>
														{item.category?.name}
													</span>
												))
											) : (
												<span className='item-mentions'>Không có</span>
											)}
										</div>
									</div>

									<div className='items-feed'>
										<div className='title'>Gắn thẻ : </div>

										<div className='d-flex flex-wrap gap-2'>
											{item?.mentionsUsers.length > 0 ? (
												item?.mentionsUsers?.map((item, index) => (
													<span key={index} className='item-mentions'>
														{item?.users?.firstName + '' + item?.users?.lastName}
													</span>
												))
											) : (
												<span className='item-mentions'>Không có</span>
											)}
										</div>
									</div>
									<div className='items-feed'>
										<div className='title'>#HashTag : </div>
										<div className='box-content'>
											<div>Chưa có hashtag</div>
										</div>
									</div>

									<div className='items-feed'>
										<div className='title'>Ảnh : </div>
										<div className='box-content'>
											<div className='d-flex gap-2 flex-wrap'>{renderImages(item.image)}</div>
										</div>
									</div>
								</Card>
							);
						})}
				</>
			)}

			<MyModal
				show={showModal}
				onHide={() => setShowModal(false)}
				title='Xác nhận xóa bài viết'
				type='bài viết'
				onDelete={handleDelete}
			/>
		</>
	);
}

export default DetailNewFeed;
