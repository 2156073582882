import { Card, Form } from '@themesberg/react-bootstrap';
import Dropzone from 'react-dropzone';
import defaultCover from 'assets/img/Rectangle 17435.png';
import defaultAvatar from 'assets/img/avatar-default.jpeg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faPlusCircle,
	faMinusCircle,
	faEdit,
	faUndo,
	faCheckSquare,
	faCamera,
} from '@fortawesome/free-solid-svg-icons';
import { Image } from 'svg-components';
import _ from 'lodash';
import { Button, Row, InputGroup, Modal } from '@themesberg/react-bootstrap';
import { useState } from 'react';
import PropTypes from 'prop-types';

export default function AvatarCoverSocial({
	handleOnDropCover,
	handleOnDropAvatar,
	isDisabled,
	coverSrc,
	avatarSrc,
	socials,
	initialSocials,
	setSocials,
}) {
	const [isEdittingSocials, setIsEdittingSocials] = useState(false);
	const [isAddingNewSocial, setIsAddingNewSocial] = useState(false);

	const [openModal, setOpenModal] = useState(false);
	const [showImg, setShowImg] = useState('');

	const updateText = (e, index) => {
		const cloneArr = [...socials];
		cloneArr[index] = e.target.value;
		setSocials(cloneArr);
	};

	const onKeyDownEditSocial = e => {
		if (e.keyCode == 13) {
			e.preventDefault();
			removeEmptySocial();
			setIsEdittingSocials(false);
			setIsAddingNewSocial(false);
		}
	};

	const handleAddInputSocial = () => {
		setIsEdittingSocials(true);
		const cloneArr = [...socials];
		cloneArr.push('');
		setSocials(cloneArr);
	};

	const handleRemoveInputSocial = index => {
		const cloneArr = [...socials];
		cloneArr.splice(index, 1);
		setSocials(cloneArr);
	};

	const onClickConfirmSocials = () => {
		setIsEdittingSocials(false);
		removeEmptySocial();
		if (isAddingNewSocial) {
			setIsAddingNewSocial(false);
		}
	};

	const onClickEditSocials = () => {
		setIsEdittingSocials(true);
		removeEmptySocial();
		if (isAddingNewSocial) {
			setIsAddingNewSocial(false);
		}
	};

	const removeEmptySocial = () => {
		const cloneArr = [...socials];
		setSocials(cloneArr.filter(item => item.trim() !== ''));
	};

	const handleSeeBigPhoto = paramImage => {
		setOpenModal(true);
		setShowImg(paramImage);
	};

	return (
		<Card border='light' className='shadow-sm mb-4'>
			<Dropzone
				onDrop={acceptedFiles => {
					handleOnDropCover(acceptedFiles);
				}}
				disabled={isDisabled}
			>
				{({ getRootProps, getInputProps }) => (
					<div {...getRootProps()} className='rounded-top edit-user__upload-image cover'>
						<input {...getInputProps()} />
						{coverSrc ? (
							<>
								<img
									src={coverSrc || defaultCover}
									onError={e => e.target.setAttribute('src', `${defaultCover}`)}
									alt='img'
									className='rounded-top edit-user__user-cover'
									onClick={e => {
										e.stopPropagation();
										handleSeeBigPhoto(coverSrc);
									}}
								/>
								<div className={`btn-change-cover ${!isDisabled && 'show'}`}>
									<FontAwesomeIcon icon={faCamera} />
								</div>
							</>
						) : (
							<>
								<Image />
								<br />
								<p>Thêm ảnh bìa từ thiết bị.</p>
							</>
						)}
					</div>
				)}
			</Dropzone>
			<Card.Body>
				<Dropzone
					onDrop={acceptedFiles => {
						handleOnDropAvatar(acceptedFiles);
					}}
					disabled={isDisabled}
				>
					{({ getRootProps, getInputProps }) => (
						<div
							{...getRootProps()}
							className='edit-user__upload-image user-avatar large-avatar rounded-circle mx-auto mt-n6 mb-4 text-dark position-relative'
						>
							<input {...getInputProps()} />
							{avatarSrc ? (
								<div>
									<Card.Img
										src={avatarSrc || defaultAvatar}
										alt='img'
										onError={e => e.target.setAttribute('src', `${defaultAvatar}`)}
										className='user-avatar large-avatar rounded-circle '
										onClick={e => {
											e.stopPropagation();
											handleSeeBigPhoto(avatarSrc);
										}}
									/>
									<div className={`btn-change-avatar ${!isDisabled && 'show'}`}>
										<FontAwesomeIcon icon={faCamera} />
									</div>
								</div>
							) : (
								<>
									<Image />
									<br />
									<p>Thêm ảnh đại diện</p>
									<p>từ thiết bị</p>
								</>
							)}
						</div>
					)}
				</Dropzone>
				<Row className='mb-3'>
					<Form.Group>
						<Form.Label>URL Mạng xã hội</Form.Label>
						<br />
						{socials.length > 0 ? (
							socials.map((item, index) => (
								<InputGroup key={index} className='mb-3 inp-socials-group'>
									<Form.Control
										className={`inp-socials-group__input ${
											!isEdittingSocials && 'inp-socials-readonly'
										} `}
										value={item}
										onChange={e => updateText(e, index)}
										onKeyDown={e => onKeyDownEditSocial(e)}
										readOnly={!isEdittingSocials}
										onClick={() => {
											!isEdittingSocials && window.open(item);
										}}
									/>
									<Button
										variant='outline-primary'
										className='inp-socials-group__button'
										disabled={isDisabled}
									>
										<FontAwesomeIcon
											icon={faMinusCircle}
											className='text-danger'
											role='button'
											onClick={() => handleRemoveInputSocial(index)}
										/>
									</Button>
								</InputGroup>
							))
						) : (
							<div className='mb-3'>Chưa có dữ liệu</div>
						)}

						<div className={`social-links ${!isDisabled && 'show'}`}>
							<FontAwesomeIcon
								icon={faPlusCircle}
								title='Thêm đường dẫn'
								className='text-secondary h4 me-3'
								role='button'
								onClick={handleAddInputSocial}
							/>

							{isEdittingSocials && (
								<FontAwesomeIcon
									icon={faCheckSquare}
									title='Xác nhận'
									className='text-success h4 me-3'
									role='button'
									onClick={onClickConfirmSocials}
								/>
							)}

							{socials.length > 0 && !isEdittingSocials && (
								<FontAwesomeIcon
									icon={faEdit}
									title='Chỉnh sửa'
									className='text-info h4 me-3'
									role='button'
									onClick={onClickEditSocials}
								/>
							)}

							{!_.isEqual(socials, initialSocials) && !isEdittingSocials && (
								<FontAwesomeIcon
									icon={faUndo}
									title='Khôi phục'
									className='text-success h4'
									role='button'
									onClick={() => {
										setIsAddingNewSocial(false);
										setSocials(initialSocials);
									}}
								/>
							)}
						</div>
					</Form.Group>
				</Row>
			</Card.Body>
			<Modal show={openModal} onHide={() => setOpenModal(false)} centered>
				<img
					src={showImg || defaultAvatar}
					alt='img'
					onError={e =>
						e.target.setAttribute('src', `${showImg === avatarSrc ? defaultAvatar : defaultCover}`)
					}
				/>
			</Modal>
		</Card>
	);
}

AvatarCoverSocial.propTypes = {
	handleOnDropCover: PropTypes.func,
	handleOnDropAvatar: PropTypes.func,
	isDisabled: PropTypes.bool,
	coverSrc: PropTypes.string,
	avatarSrc: PropTypes.string,
	socials: PropTypes.array,
	initialSocials: PropTypes.array,
	setSocials: PropTypes.func,
};
