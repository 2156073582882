import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { activityAPI, detailFeedAPI, detailHighLightPostAPI, highlightPostAPI } from 'constants/apiURL';
import Request from 'helpers/Request';

export const getActivityList = createAsyncThunk('activity/getActivityList', async (params, { rejectWithValue }) => {
	try {
		const response = await Request.makeGet(activityAPI, params);
		return response.data;
	} catch (err) {
		const error = JSON.stringify(err.response);
		throw rejectWithValue(error);
	}
});

export const createActivity = createAsyncThunk('activity/createActivity', async (params, { rejectWithValue }) => {
	try {
		const response = await Request.makePost(activityAPI, params);
		return response.data;
	} catch (err) {
		const error = JSON.stringify(err.response);
		throw rejectWithValue(error);
	}
});

export const getDetailFeed = createAsyncThunk('activity/getDetailfeed', async (id, { rejectWithValue }) => {
	try {
		const response = await Request.makeGet(detailFeedAPI(id));
		return response.data;
	} catch (err) {
		const error = JSON.stringify(err.response);
		throw rejectWithValue(error);
	}
});

export const getPopularFeed = createAsyncThunk('activity/getPopularFeed', async (params, { rejectWithValue }) => {
	try {
		const response = await Request.makeGet(highlightPostAPI, params);
		return response.data;
	} catch (err) {
		const error = JSON.stringify(err.response);
		throw rejectWithValue(error);
	}
});

export const createPopularFeed = createAsyncThunk('activity/createPopularFeed', async (params, { rejectWithValue }) => {
	try {
		const response = await Request.makePost(highlightPostAPI, params);
		return response.data;
	} catch (err) {
		const error = JSON.stringify(err.response);
		throw rejectWithValue(error);
	}
});

export const editPopularFeed = createAsyncThunk('activity/createPopularFeed', async (params, { rejectWithValue }) => {
	const { id, body } = params;
	try {
		const response = await Request.makePatch(detailHighLightPostAPI(id), body);
		return response.data;
	} catch (err) {
		const error = JSON.stringify(err.response);
		throw rejectWithValue(error);
	}
});

const activitySlice = createSlice({
	name: 'activity',
	initialState: {
		isFetching: false,
		activityData: [],
		error: {},
		refreshNewfeed: true,
		currentTab: 'newfeed',
	},
	reducers: {
		handleRefreshNewfeed: state => {
			state.refreshNewfeed = !state.refreshNewfeed;
		},
		handleCurrentTab: (state, action) => {
			state.currentTab = action.payload;
		},
	},
	extraReducers: {
		[getActivityList.pending]: state => {
			state.isFetching = true;
		},
		[getActivityList.fulfilled]: (state, action) => {
			state.isFetching = false;
			state.activityData = action.payload;
			state.error = {};
		},
		[getActivityList.rejected]: (state, action) => {
			state.isFetching = false;
			state.activityData = {};
			state.error = action.payload;
		},
	},
});

export const { handleRefreshNewfeed, handleCurrentTab } = activitySlice.actions;

const activity = activitySlice.reducer;

export default activity;
