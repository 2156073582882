import {
	faBookReader,
	faEdit,
	faLongArrowAltRight,
	faPenNib,
	faPlusCircle,
	faSearch,
	faTrashAlt,
	faUserTie,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Button, Card, Col, Form, InputGroup, Modal, Row, Table } from '@themesberg/react-bootstrap';
import defaultAvatar from 'assets/img/avatar-default.jpeg';
import BreadcrumbComponent from 'components/BreadcrumbComponent';
import CustomDropdownMenu from 'components/CustomDropdownMenu';
import LoadingIndicator from 'components/LoadingIndicator';
import PaginationComponent from 'components/PaginationComponent';
import { BASE_URL, breadcrumbsData } from 'constants';
import { NotificationError } from 'helpers/Error';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { createUser, deleteUser, getUserList } from 'reducers/user';

export default function Users() {
	const breadcrumbData = [breadcrumbsData.usersManager.users];

	const [users, setUsers] = useState([]);
	const [totalUsers, setTotalUsers] = useState(0);
	const [sort, setSort] = useState('DESC');
	const [filterText, setFilterText] = useState('');
	const [previousFilterText, setPreviousFilterText] = useState('');
	const [filterRole, setFilterRole] = useState('');
	const [filterStatus, setFilterStatus] = useState('');

	const [validatedAddUserForm, setValidatedAddUserForm] = useState(false);

	const [firstName, setFirstName] = useState('');
	const [lastName, setLastName] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [role, setRole] = useState('');

	const [isloading, setIsLoading] = useState(true);

	const [currentPage, setCurrentPage] = useState(1);
	const [totalPage, setTotalPage] = useState(1);

	const [showModalCreateUser, setShowModalCreateUser] = useState(false);
	const handleCloseModalCreateUser = () => setShowModalCreateUser(false);
	const handleShowModalCreateUser = () => setShowModalCreateUser(true);

	const [showModalDeleteUser, setShowModalDeleteUser] = useState(false);
	const handleCloseModalDeleteUser = () => setShowModalDeleteUser(false);
	const handleShowModalDeleteUser = () => setShowModalDeleteUser(true);
	const [choosenDeleteUser, setChoosenDeleteUser] = useState({});

	const dispatch = useDispatch();
	const history = useHistory();

	const callApiPerPage = useRef(10);

	useEffect(() => {
		getUserListData();
	}, [currentPage, sort, filterRole, filterStatus]);

	useEffect(() => {
		if (filterText === '' && filterText !== previousFilterText) {
			setTimeout(getUserListData, 700);
		}
	}, [filterText]);

	const onClickInfoUser = paramItem => {
		window.open(`${BASE_URL}profile/${paramItem.id}`);
	};

	const onClickIconEditUser = paramItem => {
		history.push(`/edit-user/${paramItem.id}`);
	};

	const onClickIconDeleteUser = paramItem => {
		setChoosenDeleteUser(paramItem);
		handleShowModalDeleteUser();
	};

	const handleDeleteUser = async () => {
		try {
			await dispatch(deleteUser(choosenDeleteUser.id)).unwrap();
			toast.success('Xóa người dùng thành công', { toastId: 'success-delete-user' });
		} catch (error) {
			toast.error('Xóa người dùng thất bại', { toastId: 'failed-delete-user' });
			if (typeof error.responseBody.message === 'string') {
				toast.warning(error.responseBody.message, {
					toastId: 'error-delete-user',
				});
			} else {
				toast.warning(error.responseBody.message[0], {
					toastId: 'error-delete-user',
				});
			}
		} finally {
			handleCloseModalDeleteUser();
			getUserListData();
		}
	};

	const handleCreateUser = async event => {
		event.preventDefault();
		setValidatedAddUserForm(true);
		const payloadData = {
			firstName: firstName,
			lastName: lastName,
			email: email,
			password: password,
			role: role,
		};
		const form = event.currentTarget;
		if (form.checkValidity() === false) {
			event.stopPropagation();
		} else {
			try {
				await dispatch(createUser(payloadData)).unwrap();
				clearState();
				toast.success('Tạo người dùng thành công', {
					toastId: 'success-create-user',
				});
			} catch (error) {
				toast.error('Tạo người dùng thất bại', {
					toastId: 'failed-create-user',
				});
				if (typeof error.responseBody.message === 'string') {
					toast.warning(error.responseBody.message, {
						toastId: 'error-create-user',
					});
				} else {
					toast.warning(error.responseBody.message[0], {
						toastId: 'error-create-user',
					});
				}
			} finally {
				setValidatedAddUserForm(false);
			}
		}
	};

	const clearState = () => {
		setFirstName('');
		setLastName('');
		setEmail('');
		setPassword('');
		setRole('');
	};

	const GenerateOrder = params => {
		const { index } = params;
		let result = (currentPage - 1) * callApiPerPage.current + index + 1;
		if (sort === 'DESC') {
			result = totalUsers - result + 1;
		}
		return <span>{result}</span>;
	};

	const onChangeFilterRole = value => {
		setCurrentPage(1);
		setFilterRole(value);
	};

	const onChangeFilterStatus = value => {
		setCurrentPage(1);
		setFilterStatus(value);
	};

	const getUserListData = async () => {
		setIsLoading(true);
		const params = {
			start: (currentPage - 1) * callApiPerPage.current,
			limit: callApiPerPage.current,
			sort: JSON.stringify([{ property: 'createdAt', direction: sort }]),
			filter: JSON.stringify([
				{
					operator: 'search',
					value: filterText.trim(),
					property: 'fullName,email,firstName,lastName',
				},
				filterStatus !== '' && {
					operator: 'eq',
					value: filterStatus,
					property: 'active',
				},
				filterRole !== '' && {
					operator: 'eq',
					value: filterRole,
					property: 'role',
				},
			]),
		};
		try {
			const res = await dispatch(getUserList(params)).unwrap();
			setUsers(res.rows);
			setTotalUsers(res.count);
			setTotalPage(Math.ceil(res.count / callApiPerPage.current));
		} catch (error) {
			NotificationError(error);
		} finally {
			setIsLoading(false);
			setPreviousFilterText(filterText.trim());
		}
	};

	const onKeyPressInputSearch = e => {
		if (e.key === 'Enter' && filterText.trim() !== previousFilterText) {
			if (currentPage !== 1) {
				setCurrentPage(1);
			} else {
				getUserListData();
			}
		}
	};

	return (
		<div className='users-page'>
			<div className='d-flex justify-content-between align-items-end'>
				<BreadcrumbComponent subItems={breadcrumbData} pageTitle={`Danh sách Người dùng (${totalUsers})`} />
				<div style={{ padding: '24px 0' }} className='d-flex gap-3'>
					<div>
						<InputGroup className='input-group-merge search-bar'>
							<InputGroup.Text>
								<FontAwesomeIcon icon={faSearch} />
							</InputGroup.Text>
							<Form.Control
								placeholder='Tìm kiếm (tên, email)'
								value={filterText}
								onChange={e => setFilterText(e.target.value)}
								onKeyPress={e => onKeyPressInputSearch(e)}
							/>
						</InputGroup>
					</div>
					<Button onClick={handleShowModalCreateUser} variant='secondary'>
						<FontAwesomeIcon icon={faPlusCircle} className='me-2' />
						Tạo người dùng
					</Button>
				</div>
			</div>
			<Card border='light' className='shadow-sm mb-4'>
				<Card.Body className='pb-0'>
					{isloading ? (
						<div
							className='d-flex justify-content-center align-items-center mb-3'
							style={{ height: '580px' }}
						>
							<LoadingIndicator />
						</div>
					) : (
						<>
							<Table responsive className='table-centered table-nowrap rounded mb-0'>
								<thead className='thead-light'>
									<tr>
										<th className='border-0'>
											<CustomDropdownMenu
												title='stt'
												dataArray={[
													{ value: 'DESC', title: 'Mới nhất' },
													{ value: 'ASC', title: 'Cũ nhất' },
												]}
												onFilterChange={setSort}
												filterValue={sort}
											/>
										</th>
										<th className='border-0'>ảnh đại diện</th>
										<th className='border-0'>họ tên</th>
										<th className='border-0'>email</th>
										<th className='border-0'>
											<CustomDropdownMenu
												title='vai trò'
												dataArray={[
													{ value: '', title: 'Tất cả' },
													{ value: 'admin', title: 'Admin' },
													{ value: 'author', title: 'Author' },
													{ value: 'reader', title: 'Reader' },
												]}
												onFilterChange={onChangeFilterRole}
												filterValue={filterRole}
											/>
										</th>
										<th className='border-0'>
											<CustomDropdownMenu
												title='trạng thái'
												dataArray={[
													{ value: '', title: 'Tất cả' },
													{ value: true, title: 'Không bị khóa' },
													{ value: false, title: 'Bị khóa' },
												]}
												onFilterChange={onChangeFilterStatus}
												filterValue={filterStatus}
											/>
										</th>
										<th className='border-0'>hoạt động</th>
									</tr>
								</thead>
								{users.length > 0 && (
									<tbody>
										{users.map((item, index) => (
											<tr key={index}>
												<td>
													<GenerateOrder index={index} />
												</td>
												<td>
													<img
														src={item.avatarImage || defaultAvatar}
														style={{
															height: '40px',
															width: '40px',
															objectFit: 'cover',
															borderRadius: '10%',
														}}
														onError={({ currentTarget }) => {
															currentTarget.onerror = null; // prevents looping
															currentTarget.src = defaultAvatar;
														}}
														alt='img'
													/>
												</td>
												<td>{item.fullName || item.firstName + ' ' + item.lastName}</td>
												<td>{item.email}</td>
												<td>
													<FontAwesomeIcon
														icon={
															item.role === 'author'
																? faPenNib
																: item.role === 'reader'
																? faBookReader
																: faUserTie
														}
														color={
															item.role === 'author'
																? 'orange'
																: item.role === 'reader'
																? 'gray'
																: 'blue'
														}
														className='me-2'
													/>
													{item.role}
												</td>
												<td>
													<Badge
														className='p-2'
														bg={item.active ? 'success' : 'warning'}
														style={{ fontSize: '0.75rem' }}
													>
														{item.active ? 'Không khóa' : 'Bị khóa'}
													</Badge>
												</td>
												<td>
													<div className='d-flex gap-1'>
														<Button
															variant='light'
															size='sm'
															title='Xem trang cá nhân'
															onClick={() => onClickInfoUser(item)}
														>
															<FontAwesomeIcon
																icon={faLongArrowAltRight}
																style={{ transform: 'rotate(-45deg)' }}
															/>
														</Button>
														<Button
															variant='light'
															size='sm'
															title='Chỉnh sửa'
															onClick={() => onClickIconEditUser(item)}
														>
															<FontAwesomeIcon className='text-success' icon={faEdit} />
														</Button>
														<Button
															variant='light'
															size='sm'
															title='Xóa'
															onClick={() => onClickIconDeleteUser(item)}
														>
															<FontAwesomeIcon
																className='text-danger'
																icon={faTrashAlt}
															/>
														</Button>
													</div>
												</td>
											</tr>
										))}
									</tbody>
								)}
							</Table>
							<PaginationComponent
								currentPage={currentPage}
								totalPage={totalPage}
								setCurrentPage={setCurrentPage}
							/>
							{users.length === 0 && (
								<p className='position-absolute start-50 translate-middle margin-negative'>
									Không có kết quả nào
								</p>
							)}
						</>
					)}
				</Card.Body>
			</Card>

			{/* Modal tạo người dùng */}
			<Modal
				show={showModalCreateUser}
				onHide={handleCloseModalCreateUser}
				centered
				className='transaction__modal-create-user'
			>
				<Modal.Header>
					<h5 style={{ margin: 'auto' }}>Tạo người dùng</h5>
				</Modal.Header>
				<Form noValidate validated={validatedAddUserForm} onSubmit={handleCreateUser}>
					<Modal.Body>
						<Row className='mb-3'>
							<Form.Group as={Col} md='6'>
								<Form.Label>First name</Form.Label>
								<span className='text-danger'> *</span>
								<Form.Control
									required
									name='firstName'
									value={firstName}
									onChange={e => setFirstName(e.target.value)}
								/>
								<Form.Control.Feedback type='invalid'>
									Vui lòng điền đầy đủ thông tin.
								</Form.Control.Feedback>
							</Form.Group>
							<Form.Group as={Col} md='6'>
								<Form.Label>Last name</Form.Label>
								<span className='text-danger'> *</span>
								<Form.Control
									required
									name='lastName'
									value={lastName}
									onChange={e => setLastName(e.target.value)}
								/>
								<Form.Control.Feedback type='invalid'>
									Vui lòng điền đầy đủ thông tin.
								</Form.Control.Feedback>
							</Form.Group>
						</Row>
						<Row>
							<Form.Group as={Col} className='mb-3'>
								<Form.Label>Email</Form.Label>
								<span className='text-danger'> *</span>
								<Form.Control
									required
									type='email'
									name='email'
									pattern='[^@\s]+@[^@\s]+\.[^@\s]+'
									value={email}
									onChange={e => setEmail(e.target.value)}
								/>
								<Form.Control.Feedback type='invalid'>
									Vui lòng điền đầy đủ và đúng định dạng email.
								</Form.Control.Feedback>
							</Form.Group>
						</Row>
						<Row>
							<Form.Group as={Col} md='6' className='mb-3'>
								<Form.Label>Mật khẩu</Form.Label>
								<span className='text-danger'> *</span>
								<Form.Control
									required
									name='password'
									pattern='[0-9a-zA-Z]{8,15}'
									value={password}
									onChange={e => setPassword(e.target.value)}
								/>
								<Form.Control.Feedback type='invalid'>
									Vui lòng điền mật khẩu từ 8-15 ký tự.
								</Form.Control.Feedback>
							</Form.Group>
							<Form.Group as={Col} md='6' className='mb-3 position-relative'>
								<Form.Label>Vai trò</Form.Label>
								<span className='text-danger'> *</span>
								<Form.Select required name='role' value={role} onChange={e => setRole(e.target.value)}>
									<option value=''>Chọn vai trò</option>
									<option value='reader'>Reader</option>
									<option value='admin'>Admin</option>
									<option value='author'>Author</option>
								</Form.Select>
								<Form.Control.Feedback type='invalid'>
									Vui lòng điền đầy đủ thông tin.
								</Form.Control.Feedback>
							</Form.Group>
						</Row>
					</Modal.Body>
					<Modal.Footer>
						<Button variant='success' type='submit'>
							Thêm người dùng
						</Button>
						<Button variant='light' onClick={handleCloseModalCreateUser}>
							Quay lại
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>

			{/* Modal xóa người dùng */}
			<Modal
				show={showModalDeleteUser}
				onHide={handleCloseModalDeleteUser}
				centered
				className='transaction__modal-delete-user'
			>
				<Modal.Header>
					<Modal.Title className='h6'>Xóa người dùng</Modal.Title>
					<Button variant='close' aria-label='Close' onClick={handleCloseModalDeleteUser} />
				</Modal.Header>
				<Modal.Body>
					<h5 className='text-center'>Bạn có chắc chắn muốn xóa người dùng này?</h5>
					<div className='d-flex gap-3 justify-content-center'>
						<img
							className='rounded'
							src={choosenDeleteUser.avatarImage || defaultAvatar}
							onError={e => e.target.setAttribute('src', `${defaultAvatar}`)}
							alt='img'
							style={{ width: '130px', height: '130px' }}
						></img>

						<div className='d-flex flex-column justify-content-center'>
							<span className='h6'>
								Họ tên: {choosenDeleteUser.firstName} {choosenDeleteUser.lastName}
							</span>
							<span className='h6'>Email: {choosenDeleteUser.email}</span>
						</div>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button variant='danger' onClick={handleDeleteUser}>
						Xóa
					</Button>
					<Button variant='light' onClick={handleCloseModalDeleteUser}>
						Đóng
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
}
